import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { buildLink } from '@amazeelabs/react-framework-bridge/gatsby'
import { useTranslation } from 'react-i18next'

import { cn } from '@/helper'
import { insertImages } from '@/utils/enhanceStrapiData'
import ArticleCarousel from '@/components/sections/ArticleCarousel'
import { ArticleTypes } from '../../constants/enums'

const badgeTexts = {
  [ArticleTypes.insight]: 'Insight',
  [ArticleTypes.news]: 'News',
  [ArticleTypes.successStory]: 'Success Story',
  [ArticleTypes.releaseNote]: 'Release Note',
}

const LatestPostsCarousel = ({ className, posts, ...props }) => {
  const { t } = useTranslation()

  const items = posts.map((item) => ({
    ...item,
    headline: item.teaserHeadline || item.title,
    copy: item.excerpt,
    ...insertImages(item, 'cover'),
    linkText: t('Global.more'),
    Link: buildLink({ href: item.route }),
    variant: ArticleTypes[item.category || 'news'] || ArticleTypes.news,
    badgeText: badgeTexts[item.category],
  }))

  return <ArticleCarousel items={items} className={cn(className)} {...props} />
}

LatestPostsCarousel.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  posts: [],
}

LatestPostsCarousel.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  posts: PropTypes.array,
}

export default LatestPostsCarousel

export const query = graphql`
  fragment SectionsLatestPostsCarousel on STRAPI__COMPONENT_SECTIONS_LATEST_POSTS_CAROUSEL {
    disableTopMargin
    space
    id
    anchor
    headline
    headlineOrder
    strapi_component
  }
`
