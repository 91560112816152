import React from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'
import { isString } from 'lodash'

import { classNames, cn } from '../../helper'
import { getNameFromMimeTyp } from '../../utils/mime'
import RichText from './RichText'
import HeadlineTag from './HeadlineTag'
import Button from './Button'

const DownloadCard = ({
	as: Tag,
	className,
	headlineOrder,
	title,
	files,
	description,
	image,
	defaultButtonLabel,
}) => {
	const descriptionRichtext = isString(description)
		? description
		: description && description.data && description.data.description

	const totalFiles = files?.length || 0
	const displayDefaultButtonText = totalFiles === 1

	return (
		<Tag
			className={classNames(
				image && 'smmax:space-y-5 sm:flex sm:space-x-5 xl:space-x-10',
				className,
			)}
		>
			{image && image.Component && (
				<div className="flex-none w-1/4 mr-2">
					<div className="relative bg-white aspect-1/1">
						<image.Component className="!absolute inset-0 w-full h-full download-image" />
					</div>
				</div>
			)}
			<div className="flex flex-col space-y-5">
				{title && (
					<HeadlineTag
						className="prose font-bold break-words"
						order={headlineOrder}
					>
						<span>{title}</span>
					</HeadlineTag>
				)}
				{descriptionRichtext && (
					<RichText className="prose break-words">
						{descriptionRichtext}
					</RichText>
				)}
				{totalFiles > 0 && (
					<div className="!mt-auto pt-5">
						<ul className="flex flex-wrap -mt-5 -mr-5">
							{files.map((file, index) => (
								<li
									key={file.url || uid(file)}
									className="flex mr-5 mt-5 min-w-0 overflow-hidden"
								>
									<Button
										variant='primary'
										isDownload={true}
										download
										href={file.localFile?.publicURL}
										target="_blank"
									>
										<span
											className={
												(cn('text-14 font-bold uppercase'),
													!displayDefaultButtonText ? 'px-2' : undefined)
											}
										>
											{displayDefaultButtonText
												? defaultButtonLabel
												: getNameFromMimeTyp(file.mime)}
										</span>
									</Button>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</Tag>
	)
}

DownloadCard.defaultProps = {
	as: 'div',
	headlineOrder: 3,
	files: [],
}

DownloadCard.propTypes = {
	as: PropTypes.string,
	className: PropTypes.string,
	headlineOrder: PropTypes.number,
	title: PropTypes.string,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	image: PropTypes.any,
	files: PropTypes.array,
	defaultButtonLabel: PropTypes.string,
}

export default DownloadCard
