export const sortByRankingAndTitle = (list) => {
  return list && list.length
    ? list.sort((a, b) => {
        // Sort by ranking DESC and title ASC
        // Title is only important if ranking is equal or null/undefined
        const rankingA = a.ranking || 0
        const rankingB = b.ranking || 0

        if (rankingA === rankingB) {
          const title = a.title || ''
          return title.localeCompare(b.title)
        }
        return b.ranking - a.ranking
      })
    : []
}
