import React from 'react'
import PropTypes from 'prop-types'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { classNames } from '../../helper'

// <Button as={Link} variant="primary" showLinkIcon>{linkText}</Button>
// const variants = {
//   fixed: `inline-flex py-[0.4375rem] border border-transparent text-14 leading-[1rem] font-bold uppercase`,
//   default: `text-white bg-rio pl-4 pr-3 justify-between `,
//   green: `text-white bg-rio px-4 justify-between `,
//   silent: `text-charcoal bg-off-white px-4`,
//   loud: `text-charcoal bg-rio px-4`,
//   dark: `text-white bg-charcoal px-4`,
//   white: `text-charcoal bg-white px-4`,
//   inverted: `text-rio bg-white px-4`,
// }

const variants = {
	fixed: `inline-flex`,
	default: `btn-default cl-default`,
	green: `btn-debug cl-green`,
	silent: `btn-debug cl-silent`,
	loud: `btn-debug cl-loud`,
	dark: `btn-debug cl-dark`,
	white: `btn-debug cl-white`,
	inverted: `btn-debug cl-inverted`,
	primaryinverted: `btn-primary-inverted`,
	primary: `btn-primary`,
	secondary: `btn-secondary`,
	tertiary: `btn-tertiary`,
}

const Button = ({
	as: Tag,
	className,
	children,
	variant,
	Icon,
	AppendIcon,
	showLinkIcon,
	isDownload,
	href,
	type,
	onClick,
}) => {
	const buttonType = Tag === 'button' && !type ? 'button' : type
	const isDefaultVariant = !(variant && variants[variant])
	const variantClasses =
		variant && variants[variant] ? variants[variant] : variants.default

	return (
		<Tag
			className={classNames(variants.fixed, variantClasses, className)}
			href={href}
			target={isDownload && '_blank'}
			download={isDownload}
			type={buttonType}
			onClick={onClick}
		>
			{isDownload && (
				<span className="b-icon-left">
					<FileDownloadIcon aria-hidden="true" />
					<FileDownloadIcon aria-hidden="true" />
				</span>
			)}
			{Icon && (
				<span className="b-icon-left">
					<Icon aria-hidden="true" />
					<Icon aria-hidden="true" />
				</span>
			)}
			{children}
			{!isDownload && (isDefaultVariant || showLinkIcon) && !AppendIcon && (
				<span className="b-icon-right">
					<ArrowForwardIcon aria-hidden="true" />
					<ArrowForwardIcon aria-hidden="true" />
				</span>
			)}
			{AppendIcon && (
				<span aria-hidden="true" className="b-icon-right">
					<AppendIcon />
					<AppendIcon />
				</span>
			)}
		</Tag>
	)
}

Button.defaultProps = {
	as: 'a',
	showLinkIcon: false
}

Button.propTypes = {
	as: PropTypes.any,
	className: PropTypes.string,
	children: PropTypes.node,
	variant: PropTypes.string,
	Icon: PropTypes.object,
	AppendIcon: PropTypes.object,
	showLinkIcon: PropTypes.bool,
	isDownload: PropTypes.bool,
	href: PropTypes.string,
	type: PropTypes.string,
}

export default Button
