import React from 'react'
import PropTypes from 'prop-types'

import { cn } from '../../helper'
import { Themes, defaultThemeName } from '../../constants/themes'
import HeadlineTag from './HeadlineTag'
import RichText from './RichText'
import Button from './Button'

const TeaserImageLarge = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  Link,
  linkText,
  image,
  copy,
  theme,
}) => {
  const {
    root: themeRootClasses,
    prose: themeProseClasses,
    buttonVariant,
  } = Themes[theme] || {}

  return (
    <Tag
      className={cn(
        'relative flex flex-col md:grid md:grid-cols-2 lg:grid-cols-12',
        themeRootClasses,
        className,
      )}
    >
      <div className="relative lg:col-span-7 xxl:col-span-8">
        {image && image.Component && (
          <>
            <div className="relative aspect-2/1" />
            <image.Component className="!absolute inset-0 w-full h-full" />
          </>
        )}
      </div>
      <div className="flex-1 flex flex-col justify-between p-10 text-center lg:col-span-5 xxl:col-span-4">
        {headline && (
          <HeadlineTag
            className="text-28 leading-1.2 font-bold uppercase break-words md:text-32 lg:text-48 xl:w-full xl:max-w-md xl:mx-auto"
            order={headlineOrder + 1}
          >
            {Link ? (
              <Link className="relative z-30">{headline}</Link>
            ) : (
              <span>{headline}</span>
            )}
          </HeadlineTag>
        )}
        {copy && (
          <RichText
            className={cn(
              'prose xl:w-full xl:max-w-md xl:mx-auto',
              themeProseClasses,
              headline && 'mt-6',
            )}
          >
            {copy}
          </RichText>
        )}
        {Link && linkText && (
          <div
            className={cn(
              'xl:w-full xl:max-w-md xl:mx-auto',
              (copy || headline) && 'pt-8',
            )}
          >
            <Button as={Link} variant={buttonVariant} showLinkIcon>
              {linkText}
            </Button>
          </div>
        )}
      </div>
      {Link && (
        <Link
          className="absolute top-0 left-0 w-full h-full z-20"
          tabindex="-1"
          aria-hidden
        >
          <span className="sr-only">{headline}</span>
        </Link>
      )}
    </Tag>
  )
}

TeaserImageLarge.defaultProps = {
  as: 'article',
  headlineOrder: 2,
  theme: defaultThemeName,
}

TeaserImageLarge.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  Link: PropTypes.any,
  image: PropTypes.any,
  headline: PropTypes.string,
  copy: PropTypes.string,
  linkText: PropTypes.string,
  theme: PropTypes.string,
}

export default TeaserImageLarge
