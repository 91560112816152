import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'
import { useTranslation } from 'react-i18next'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { ArticleTypes } from '../../constants/enums'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import ArticleCard from '../base/ArticleCard'
import LoadMoreButton from '../base/LoadMoreButton'
import LoadMoreCard from '../base/LoadMoreCard'
import Button from '../base/Button'
import PlatformFilter from '../base/PlatformFilter'
import { Platforms } from '../../constants/enums'
import Pagination from '../base/Pagination'

// const PAGE_SIZE = 9

const getCurrentPageSize = ({ hasPagination, pageSize, totalItems }) => {
  return hasPagination
    ? pageSize
    : totalItems === pageSize
    ? pageSize
    : pageSize - 1
}

const ArticleGrid = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  items: allItems,
  loadMoreButtonText,
  loadingText,
  loadMoreCardText,
  variant,
  loading,
  space,
  hasPagination,
  ...props
}) => {
  const { t } = useTranslation()
  const pageSize = parseInt(props.pageSize)
  const [items, setItems] = useState(allItems)
  const [paging, setPaging] = useState({
    currentPage: 1,
    offset: 0,
    limit: getCurrentPageSize({
      hasPagination,
      pageSize,
      totalItems: items.length,
    }),
  })

  // Filter by platform feature
  const platformFilterEnabled =
    variant === ArticleTypes.insight || variant === ArticleTypes.releaseNote
  const allPlatforms = [
    Platforms.Apple,
    Platforms.Android,
    Platforms.Windows,
    Platforms.ChromeOS,
    Platforms.Relution,
  ]
  const [platforms, setPlatforms] = useState(allPlatforms)
  const changePlatforms = (platforms) => {
    const filteredItems = allItems.filter((item) => {
      const itemPlatforms = item.platforms.map(({ platformId }) => platformId)
      return _.intersection(itemPlatforms, platforms).length > 0
    })
    setPlatforms(platforms)
    setItems(filteredItems)
    setPaging({
      currentPage: 1,
      offset: 0,
      limit: getCurrentPageSize({
        hasPagination,
        pageSize,
        totalItems: filteredItems.length,
      }),
    })
  }

  // Load more feature
  const numRemaining = () => items.length - paging?.limit
  const showLoadMore = () => !hasPagination && numRemaining() > 0
  const loadMore = () => {
    const limit = Math.min(paging?.limit + pageSize, items.length)
    setPaging({
      currentPage: 1,
      offset: 0,
      limit,
    })
  }

  // Pagination feature
  const showPagination = () => hasPagination && items.length > pageSize
  const handlePageChange = (newPage) => {
    const offset = (newPage - 1) * pageSize
    const limit = Math.min(offset + pageSize, items.length)

    setPaging({
      currentPage: newPage,
      offset,
      limit,
    })
  }

  return (
    <Tag className={cn(className)} {...addValidHtmlProps(props)}>
      <div className={cn('box bg-white', py(space))}>
        <div className="mb-10 flex justify-between items-end sm:col-span-5 xl:col-span-4">
          {headline && (
            <HeadlineTag className="break-words" order={headlineOrder}>
              <span className="block text-32 leading-1.2 font-bold uppercase md:text-44">
                {headline}
              </span>
            </HeadlineTag>
          )}
          {platformFilterEnabled && (
            <PlatformFilter
              size="xs"
              className="pb-2"
              platforms={allPlatforms}
              value={platforms}
              setAllFilterLabel={t(`ArticleFilter.${variant}.setAllFilter`)}
              onChange={changePlatforms}
            />
          )}
        </div>
        {items && items.length > 0 && (
          <ul className="grid gap-5 auto-rows-auto grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
            {items.slice(paging?.offset, paging?.limit).map((item) => (
              <li key={uid(item)} className="flex flex-col">
                <ArticleCard
                  {...item}
                  headlineOrder={headlineOrder + 1}
                  as="article"
                  variant={variant}
                  className="flex-1"
                />
              </li>
            ))}
            {showLoadMore() && (
              <li className="flex flex-col xlmax:hidden">
                <LoadMoreCard
                  className="flex-1"
                  variant={variant}
                  text={loadMoreCardText}
                  loadingText={loadingText}
                  loading={loading}
                  numRemaining={numRemaining()}
                  onPress={() => loadMore()}
                />
              </li>
            )}
          </ul>
        )}
        {items.length === 0 && platformFilterEnabled && (
          <div className="space-y-4">
            <p>{t(`ArticleFilter.${variant}.noResults`)}</p>
            <p>
              <Button as="button" onClick={() => changePlatforms(allPlatforms)}>
                {t(`ArticleFilter.${variant}.resetFilter`)}
              </Button>
            </p>
          </div>
        )}
        {loadMoreButtonText && showLoadMore() && (
          <div className="pt-10 text-center">
            <LoadMoreButton
              text={loadMoreButtonText}
              loadingText={loadingText}
              loading={loading}
              onPress={() => loadMore()}
            />
          </div>
        )}
        {showPagination() && (
          <div className="mt-24">
            <Pagination
              itemsPerPage={pageSize}
              totalItems={items?.length}
              currentPage={paging?.currentPage}
              onPageChange={handlePageChange}
              boundaryCount={1}
              siblingCount={2}
            />
          </div>
        )}
      </div>
    </Tag>
  )
}

ArticleGrid.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  items: [],
  variant: ArticleTypes.insight,
  noResults: `Leider konnten wir für Ihre Einstellungen keine Ergebnisse finden.`,
  resetFilter: `Filter zurücksetzen`,
  setAllFilter: `Alle`,
  hasPagination: false,
  pageSize: 9,
}

ArticleGrid.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  items: PropTypes.array,
  loadMoreButtonText: PropTypes.string,
  loadingText: PropTypes.string,
  loadMoreCardText: PropTypes.string,
  variant: PropTypes.string,
  hasPagination: PropTypes.bool,
  pageSize: PropTypes.number,
}

export default ArticleGrid
