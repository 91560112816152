import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { uid } from 'react-uid'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import TextColumn from '../blocks/TextColumn'
import ImageColumn from '../blocks/ImageColumn'
import VideoColumn from '../blocks/VideoColumn'
import HeadingColumn from '../blocks/HeadingColumn'
import HighlightColumn from '../blocks/HighlightColumn'
import Button from '../base/Button'
import StatsBox from '../base/StatsBox'

const ColumnsGroup = ({
  as: Tag,
  className,
  space,
  anchor,
  headingLeft,
  textLeft,
  linkLeft,
  mediaLeft,
  links,
  mediaBlock,
  mediaBlocks,
  headingBlock,
  highlightBlock,
  textBlock,
  statsHeadline,
  stats,
  ...props
}) => {
  const columnLeft = []
  const columnRight = []

  const headingElem = headingBlock && (
    <HeadingColumn {...headingBlock} key={uid(headingBlock)} />
  )
  if (headingElem) {
    if (headingLeft) {
      columnLeft.push(headingElem)
    } else {
      columnRight.push(headingElem)
    }
  }

  const highlightElem = highlightBlock && (
    <HighlightColumn key={uid(highlightBlock)} {...highlightBlock} />
  )
  if (highlightElem) {
    if (headingLeft) {
      columnLeft.push(highlightElem)
    } else {
      columnRight.push(highlightElem)
    }
  }

  const textElem = textBlock && (
    <TextColumn key={uid(textBlock)} richtext={textBlock.richtext} />
  )
  if (textElem) {
    if (textLeft) {
      columnLeft.push(textElem)
    } else {
      columnRight.push(textElem)
    }
  }

  const linkElems = links && Boolean(links.length) && (
    <ul className="space-y-5" key={uid(links)}>
      {links.map((link) => (
        <li key={uid(link)}>
          <Button variant="primary" showLinkIcon as={link.Link}>
            {link.linkText}
          </Button>
        </li>
      ))}
    </ul>
  )
  if (linkElems) {
    if (linkLeft) {
      columnLeft.push(linkElems)
    } else {
      columnRight.push(linkElems)
    }
  }

  const mediaElems = mediaBlocks && Boolean(mediaBlocks.length) && (
    <div className="space-y-10" key={uid(mediaBlocks)}>
      {mediaBlocks.map((mediaBlock) =>
        mediaBlock.videoId ? (
          <VideoColumn key={uid(mediaBlock)} {...mediaBlock} />
        ) : (
          <ImageColumn key={uid(mediaBlock)} {...mediaBlock} />
        ),
      )}
    </div>
  )
  if (mediaElems) {
    if (mediaLeft) {
      columnLeft.push(mediaElems)
    } else {
      columnRight.push(mediaElems)
    }
  }

  const mediaElem = mediaBlock && (
    <div className="space-y-10" key={uid(mediaBlocks)}>
      {mediaBlock.videoId ? (
        <VideoColumn key={uid(mediaBlock)} {...mediaBlock} />
      ) : (
        <ImageColumn key={uid(mediaBlock)} {...mediaBlock} />
      )}
    </div>
  )
  if (mediaElem) {
    if (mediaLeft) {
      columnLeft.push(mediaElem)
    } else {
      columnRight.push(mediaElem)
    }
  }

  const statsElem = stats?.length && (
    <StatsBox items={stats} headline={statsHeadline} headlineOrder={3} />
  )
  if (statsElem) {
    columnRight.push(statsElem)
  }

  return (
    <Tag className={cn(className)} id={anchor} {...addValidHtmlProps(props)}>
      <div
        className={cn(
          'box bg-white grid gap-x-15 gap-y-10 lg:grid-cols-2',
          py(space),
        )}
      >
        <div className="space-y-10">{columnLeft.map((column) => column)}</div>
        <div className="space-y-10">{columnRight.map((column) => column)}</div>
      </div>
    </Tag>
  )
}

ColumnsGroup.defaultProps = {
  as: 'div',
  columns: [],
  headingLeft: true,
  textLeft: true,
  linkLeft: true,
  mediaLeft: false,
  links: [],
  mediaBlocks: [],
  stats: [],
}

ColumnsGroup.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  columns: PropTypes.array,
  headingLeft: PropTypes.bool,
  textLeft: PropTypes.bool,
  linkLeft: PropTypes.bool,
  mediaLeft: PropTypes.bool,
  links: PropTypes.array,
  mediaBlock: PropTypes.object,
  mediaBlocks: PropTypes.array,
  headingBlock: PropTypes.object,
  highlightBlock: PropTypes.object,
  textBlock: PropTypes.object,
  anchor: PropTypes.string,
  stats: PropTypes.array,
}

export default ColumnsGroup

export const query = graphql`
  fragment SectionsColumnsHeadingImage on STRAPI__COMPONENT_SECTIONS_COLUMNS_HEADING_IMAGE {
    disableTopMargin
    space
    id
    strapi_component
    anchor
    tag
    headingBlock {
      ...BlocksHeadingBlock
    }
    headingLeft
    linkLeft
    links {
      ...SharedLink
    }
    mediaLeft
    mediaBlocks {
      ...BlocksImageBlock
    }
    textLeft
    textBlock {
      ...BlocksTextBlock
    }
    statsHeadline
    stats {
      ...SharedStatsItem
    }
  }
  fragment SectionsColumnsHeadingText on STRAPI__COMPONENT_SECTIONS_COLUMNS_HEADING_TEXT {
    anchor
    disableTopMargin
    space
    id
    strapi_component
    headingBlock {
      ...BlocksHeadingBlock
    }
    linkLeft
    headingLeft
    links {
      ...SharedLink
    }
    textBlock {
      ...BlocksTextBlock
    }
    textLeft
    statsHeadline
    stats {
      ...SharedStatsItem
    }
  }
  fragment SectionsColumnsHeadingVideo on STRAPI__COMPONENT_SECTIONS_COLUMNS_HEADING_VIDEO {
    anchor
    disableTopMargin
    space
    id
    strapi_component
    headingLeft
    headingBlock {
      ...BlocksHeadingBlock
    }
    linkLeft
    links {
      ...SharedLink
    }
    mediaLeft
    textLeft
    textBlock {
      ...BlocksTextBlock
    }
    mediaBlock {
      videoId
      image {
        ...SharedImage
      }
    }
    statsHeadline
    stats {
      ...SharedStatsItem
    }
  }
  fragment SectionsColumnsHighlightImage on STRAPI__COMPONENT_SECTIONS_COLUMNS_HIGHLIGHT_IMAGE {
    anchor
    disableTopMargin
    space
    id
    strapi_component
    headingLeft
    highlightBlock {
      ...BlocksHighlightBlock
    }
    linkLeft
    links {
      ...SharedLink
    }
    mediaBlocks {
      ...BlocksImageBlock
    }
    mediaLeft
    textLeft
    textBlock {
      ...BlocksTextBlock
    }
    statsHeadline
    stats {
      ...SharedStatsItem
    }
  }
  fragment SectionsColumnsHighlightText on STRAPI__COMPONENT_SECTIONS_COLUMNS_HIGHLIGHT_TEXT {
    anchor
    disableTopMargin
    space
    id
    strapi_component
    headingLeft
    highlightBlock {
      ...BlocksHighlightBlock
    }
    linkLeft
    links {
      ...SharedLink
    }
    textLeft
    textBlock {
      ...BlocksTextBlock
    }
    statsHeadline
    stats {
      ...SharedStatsItem
    }
  }
  fragment SectionsColumnsHighlightVideo on STRAPI__COMPONENT_SECTIONS_COLUMNS_HIGHLIGHT_VIDEO {
    anchor
    disableTopMargin
    space
    id
    strapi_component
    headingLeft
    highlightBlock {
      ...BlocksHighlightBlock
    }
    linkLeft
    links {
      ...SharedLink
    }
    mediaLeft
    mediaBlock {
      videoId
      image {
        ...SharedImage
      }
    }
    textLeft
    textBlock {
      ...BlocksTextBlock
    }
    statsHeadline
    stats {
      ...SharedStatsItem
    }
  }
`
