import React from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'
import { Disclosure } from '@headlessui/react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { cn } from '../../helper'

const GlobalNavCollapseSub = ({ item, defaultOpen }) => {
  // Third level nav
  const { title, items } = item || {}

  const ParentPageLink = item.Link ? item.Link : 'div'

  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <div className="flex items-center w-full">
            <ParentPageLink className="flex-auto flex items-center py-3">
              <span className={cn('text-base')}>
                {title}
                <ChevronRightIcon />
              </span>
            </ParentPageLink>
            <Disclosure.Button
              className={cn(
                'flex-none block py-3 transition-colors w-12',
                open ? 'text-current' : 'text-gray-400',
              )}
            >
              <div className="ml-auto w-6 h-6">
                {open ? <RemoveIcon aria-hidden /> : <AddIcon aria-hidden />}
              </div>
            </Disclosure.Button>
          </div>
          {items.length && (
            <Disclosure.Panel className="overflow-hidden">
              <ul className="ml-5 pb-3 divide-y divide-gray-200">
                {items.map((subItem) => (
                  <li key={uid(subItem)}>
                    {subItem.Link ? (
                      <subItem.Link className="-mx-5 px-5 py-3 flex items-center">
                        <span className="text-base">{subItem.title}</span>
                      </subItem.Link>
                    ) : (
                      <div className="-mx-5 px-5 py-3 flex items-center">
                        <span className="text-base">{subItem.title}</span>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  )
}

GlobalNavCollapseSub.defaultProps = {
  items: [],
}

GlobalNavCollapseSub.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
}

export default GlobalNavCollapseSub
