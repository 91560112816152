import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from '../../helper'
import HeadlineTag from './HeadlineTag'
import RichText from './RichText'
import Button from './Button'

const BreakingNewsCard = ({
	as: Tag,
	className,
	headlineOrder,
	headline,
	Link,
	linkText,
	image,
	copy,
}) => (
	<Tag
		className={classNames(
			'relative flex flex-col lg:grid lg:grid-cols-12 lg:gap-x-5',
			className,
		)}
	>
		<div className="relative bg-gray-100 lgmax:mb-6 lg:col-span-4">
			{image && (
				<>
					<div className="relative aspect-8/5" />
					<image.Component className="!absolute inset-0 w-full h-full" />
				</>
			)}
		</div>
		<div className="flex-1 flex flex-col lg:col-span-8">
			{headline && (
				<HeadlineTag
					className="leading-1.2 font-bold text-32 lg:text-48 uppercase break-words"
					order={headlineOrder + 1}
				>
					{Link ? (
						<Link className="relative z-30">{headline}</Link>
					) : (
						<span>{headline}</span>
					)}
				</HeadlineTag>
			)}
			{copy && (
				<RichText className={classNames('prose ', headline && 'mt-4')}>
					{copy}
				</RichText>
			)}
			{Link && linkText && (
				<div className={classNames('', (copy || headline) && 'mt-auto pt-6')}>
					<Button as={Link} variant="secondary" showLinkIcon>
						{linkText}
					</Button>
				</div>
			)}
		</div>
		{Link && (
			<Link
				className="absolute top-0 left-0 w-full h-full z-20"
				tabindex="-1"
				aria-hidden
			>
				<span className="sr-only">{headline}</span>
			</Link>
		)}
	</Tag>
)

BreakingNewsCard.defaultProps = {
	as: 'article',
	headlineOrder: 2,
}

BreakingNewsCard.propTypes = {
	as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
	className: PropTypes.string,
	headlineOrder: PropTypes.number,
	Link: PropTypes.any,
	image: PropTypes.object,
	headline: PropTypes.string,
	copy: PropTypes.string,
	linkText: PropTypes.string,
}

export default BreakingNewsCard
