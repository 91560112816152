import React from 'react'
import PropTypes from 'prop-types'

import { cn } from '../../helper'

const VideoEmbed = ({ videoId, className }) => (
  <iframe
    data-cmp-vendor="s30"
    data-cmp-src={`https://www.youtube-nocookie.com/embed/${videoId}`}
    className={cn('cmplazyload absolute inset-0 w-full h-full', className)}
    width="100%"
    height="100%"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)

VideoEmbed.propTypes = {
  className: PropTypes.string,
  videoId: PropTypes.string.isRequired,
}

export default VideoEmbed
