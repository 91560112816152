import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { cn } from '../../helper'
import PlatformIcon from './PlatformIcon'

const Sizes = {
  xs: 'w-6 h-8',
  default: 'w-8 h-[2.3333rem] sm:w-12 sm:h-14',
}

const variants = {
  fixed: `inline-flex items-center space-x-1`,
}

const PlatformFilter = ({
  className,
  platforms,
  value,
  onChange,
  inverted,
  size,
  setAllFilterLabel,
}) => {
  const iconSizeClasses = Sizes[size]

  const togglePlatform = (platform) => {
    const newValue = value.includes(platform)
      ? [...value.filter((current) => current !== platform)]
      : [...value, platform]
    onChange(newValue)
  }

  const activateAllPlatforms = () => {
    onChange(platforms)
  }

  return (
    <ul className={cn(variants.fixed, className)}>
      <li className="pr-3">
        <button
          type="button"
          className="text-14 uppercase font-bold"
          onClick={activateAllPlatforms}
        >
          {setAllFilterLabel}
        </button>
      </li>
      {platforms.map((platform) => (
        <li
          key={platform.platformId || platform}
          className={cn(
            'flex-none flex',
            iconSizeClasses,
            !value.includes(platform.platformId || platform) && 'grayscale',
          )}
        >
          <button
            type="button"
            className="flex"
            onClick={() => togglePlatform(platform.platformId || platform)}
          >
            <PlatformIcon
              platform={platform.platformId || platform}
              inverted={inverted}
            />
          </button>
        </li>
      ))}
    </ul>
  )
}

PlatformFilter.defaultProps = {
  inverted: false,
  size: 'default',
}

PlatformFilter.propTypes = {
  className: PropTypes.string,
  inverted: PropTypes.bool,
  size: PropTypes.string,
}

export default PlatformFilter
