import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py, Space } from '../../constants/space'
import BadgeDividerBlock from '../blocks/BadgeDividerBlock'

const BadgeDivider = ({ as: Tag, id, anchor, space, className, ...props }) => (
  <Tag
    id={anchor}
    className={cn('box', py(space || Space.none), className)}
    {...addValidHtmlProps(props)}
  >
    <BadgeDividerBlock as="div" {...props} />
  </Tag>
)

BadgeDivider.defaultProps = {
  as: 'div',
}

BadgeDivider.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  anchor: PropTypes.string,
}

export default BadgeDivider

export const query = graphql`
  fragment SectionsBadgeDivider on STRAPI__COMPONENT_SECTIONS_BADGE_DIVIDER {
    disableTopMargin
    space
    id
    platform {
      platformId
    }
    strapi_component
    text
    anchor
  }
`
