import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import Button from '../base/Button'
import RichText from '../base/RichText'
import AspectRatio from '../base/AspectRatio'

const TeaserLogo = ({
  as: Tag,
  className,
  headlineOrder,
  Link,
  headline,
  copy,
  linkText,
  image,
  space,
  ...props
}) => (
  <Tag className={cn('relative', className)} {...addValidHtmlProps(props)}>
    <div
      className={cn(
        'box bg-white sm:flex sm:justify-between sm:items-center',
        py(space),
      )}
    >
      <AspectRatio
        size={image}
        className="flex-none relative max-h-40 smmax:mb-8 sm:mr-10"
        placeholderClassName="max-h-40"
      >
        {image.Component && (
          <image.Component className="!absolute inset-0 w-full h-full object-contain sm:object-left" />
        )}
      </AspectRatio>
      <div className="flex flex-col relative z-10 max-w-4xl">
        {headline && (
          <HeadlineTag
            className="text-24 leading-1.2 font-bold uppercase break-words md:text-32"
            order={headlineOrder}
          >
            <span>{headline}</span>
          </HeadlineTag>
        )}
        {copy && (
          <RichText className={cn(headline && 'mt-2', 'prose max-w-4xl')}>
            {copy}
          </RichText>
        )}
        {Link && linkText && (
          <div className={cn((headline || copy) && 'mt-4', 'relative z-20')}>
            <Button as={Link} variant="primary" showLinkIcon>{linkText}</Button>
          </div>
        )}
      </div>
    </div>
    {/* {Link && (
      <Link className="absolute top-0 left-0 w-full h-full z-10">
        <span className="sr-only">{headline}</span>
      </Link>
    )} */}
  </Tag>
)

TeaserLogo.defaultProps = {
  as: 'article',
  headlineOrder: 2,
}

TeaserLogo.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  Link: PropTypes.any,
  headline: PropTypes.string,
  copy: PropTypes.string,
  linkText: PropTypes.string,
  image: PropTypes.object,
}

export default TeaserLogo

export const query = graphql`
  fragment SectionsTeaserLogo on STRAPI__COMPONENT_SECTIONS_TEASER_LOGO {
    disableTopMargin
    space
    id
    strapi_component
    anchor
    copy {
      data {
        copy
      }
    }
    headline
    headlineOrder
    image {
      mime
      url
      alt: alternativeText
      width
      height
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    tag
    link {
      ...SharedLink
    }
  }
`
