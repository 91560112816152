import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Menu, Transition } from '@headlessui/react'
import { useI18next } from 'gatsby-plugin-react-i18next'

import LanguageIcon from '@mui/icons-material/Language'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { cn } from '../../helper'

const LanguageSelect = ({ localizations, activeLocales }) => {
  const { language } = useI18next()
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          languageFallback
          languages {
            code
            hrefLang
            name
            localName
            langDir
            dateFormat
          }
        }
      }
    }
  `)

  const languages = data.site.siteMetadata.languages || []
  const activeLanguages = languages.filter((language) =>
    activeLocales.includes(language.code),
  )
  const languageFallback = data.site.siteMetadata.languageFallback || []

  const gotoLanguage = (code) => {
    if (currentLanguage.code === code) {
      return
    }
    let localization = localizations.find(
      (localization) => localization.locale === code,
    )
    if (!localization) {
      const fallbackLocalizationCode = languageFallback.find((code) => {
        const localization = localizations.find(({ locale }) => {
          return code === locale
        })
        return localization
      })
      if (fallbackLocalizationCode) {
        localization = localizations.find(
          ({ locale }) => locale === fallbackLocalizationCode,
        )
      }
    }

    if (localization) {
      // NOTE: In order to use the correct SSR lang attribute, a real reload is
      // required after a language change. Otherwise (e.g. for Turkish) the correct
      // characters are not displayed, because React Helmet changes the lang too late.
      // @see gatsby-ssr.js and seo.jsx
      window.location.assign(localization.route)
    }
  }

  const currentLanguage =
    activeLanguages.find((lang) => lang.code === language) || {}

  return (
    <Menu as="div" className="relative text-left lg:inline-block">
      <div>
        <Menu.Button
          className={cn(
            'relative inline-flex items-center justify-center px-3 lg:px-0 py-3 w-full text-14 leading-[1rem] bg-white text-sm font-medium text-gray-700',
            'after:absolute after:bottom-2 after:left-0 after:w-full after:top-2 lgmax:after:border after:border-gray-700',
            'lg:after:border-b-2 lg:after:top-auto',
          )}
        >
          <span className="w-6 h-6 mr-1">
            <LanguageIcon aria-hidden="true" />
          </span>
          <span className="mr-3 pt-1 pb-[0.1875rem] font-bold uppercase">
            <span className="lg:hidden">{currentLanguage.localName}</span>
            <span className="lgmax:hidden uppercase">
              {currentLanguage.code}
            </span>
          </span>
          <span className="w-6 h-6 ml-auto">
            <ExpandMoreIcon aria-hidden="true" />
          </span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="mt-1 lgmax:origin-bottom-left absolute lgmax:bottom-0 lgmax:left-0 w-full lg:w-30 shadow-xl bg-white ring-2 ring-gray-500 divide-y divide-gray-100 focus:outline-none lg:origin-top-right lg:right-0">
          <div className="py-1">
            {activeLanguages.map(({ code, localName }) => (
              <Menu.Item key={code}>
                {() => (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      gotoLanguage(code)
                    }}
                    className={cn(
                      language === code
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-700',
                      'group flex items-center w-full px-4 py-2 text-14 font-bold uppercase',
                    )}
                  >
                    <span>{localName}</span>
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

LanguageSelect.defaultProps = {
  activeLocales: [],
  localizations: [],
}

LanguageSelect.propTypes = {
  activeLocales: PropTypes.array,
  localizations: PropTypes.array,
}

export default LanguageSelect
