import React from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

import { cn } from '../../helper'
import { py } from '../../constants/space'
import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import HeadlineTag from '../base/HeadlineTag'
import DownloadCard from '../base/DownloadCard'

import { Disclosure, Transition } from '@headlessui/react'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

const Downloads = ({
	as: Tag,
	className,
	anchor,
	space,
	headlineOrder,
	headline,
	downloads,
	downloadCategories,
	...props
}) => {
	const { t } = useTranslation()

	// Filter "empty" categories without any downloads
	const finalDownloads = downloads
	const finalCategories = downloadCategories.filter((category) =>
		finalDownloads.some(
			(download) =>
				category.categoryId === download.downloadCategory.categoryId,
		),
	)

	// Render section only if at least one category has a download
	return (
		finalCategories &&
		finalCategories.length > 0 && (
			<Tag id={anchor} className={cn(className)} {...addValidHtmlProps(props)}>
				<div className={cn('box bg-white', py(space))}>
					{headline && (
						<HeadlineTag
							className="sm:col-span-5 xl:col-span-4 mb-10 break-words"
							order={headlineOrder}
						>
							<span className="block text-32 leading-1.2 font-bold uppercase md:text-44">
								{headline}
							</span>
						</HeadlineTag>
					)}
					<div className="space-y-5 download-block">

						{finalCategories.map((category) => (
							<div
								key={category.categoryId}
								className="bg-off-white p-8 space-y-10 download-category"
							>
								<Disclosure>
									{({ open }) => (
										<>
											<Disclosure.Button className="flex w-full justify-between text-left download-category-trigger">
												{category.title && (
													<HeadlineTag
														className="text-28 leading-1.2 font-bold uppercase break-words"
														order={headlineOrder + 1}
													>
														<span>{category.title}</span>
													</HeadlineTag>
												)}
												<ArrowCircleRightIcon
													className={`${open ? 'rotate-90 transform' : ''
														}`}
												/>
											</Disclosure.Button>
											<Transition
												enter="transition duration-100 ease-out"
												enterFrom="transform -translate-y-2 opacity-0"
												enterTo="transform translate-y-0 opacity-100"
												leave="transition duration-75 ease-out"
												leaveFrom="transform translate-y-0 opacity-100"
												leaveTo="transform -translate-y-2 opacity-0"
											>

												<Disclosure.Panel className="download-list">

													<ul className="grid gap-10 lg:grid-cols-2">
														{finalDownloads
															.filter(
																({ downloadCategory }) =>
																	downloadCategory.categoryId === category.categoryId,
															)
															.map((download) => (
																<li key={uid(download)} className="download-item">
																	<DownloadCard
																		{...download}
																		defaultButtonLabel={t(
																			'SectionsDownloads.downloadButtonLabel',
																		)}
																	/>
																</li>
															))}
													</ul>
												</Disclosure.Panel>
											</Transition>
										</>
									)}
								</Disclosure>
							</div>
						))}
					</div>
				</div>
			</Tag>
		)
	)
}

Downloads.defaultProps = {
	as: 'div',
	headlineOrder: 2,
	downloads: [],
	downloadCategories: [],
}

Downloads.propTypes = {
	as: PropTypes.string,
	className: PropTypes.string,
	tag: PropTypes.string,
	anchor: PropTypes.string,
	headline: PropTypes.string,
	headlineOrder: PropTypes.number,
	downloads: PropTypes.array,
	downloadCategories: PropTypes.array,
}

export default Downloads

export const query = graphql`
  fragment SectionsDownloads on STRAPI__COMPONENT_SECTIONS_DOWNLOADS {
    id
    strapi_component
    anchor
    tag
    space
    disableTopMargin
    headline
    headlineOrder
    downloadCategories {
      title
      strapi_id
    }
  }
  fragment Download on STRAPI_DOWNLOAD {
    strapi_id
    title
    ranking
    files {
      ...SharedFile
    }
    downloadCategory {
      categoryId: strapi_id
    }
    description {
      data {
        description
      }
    }
    image {
      ...SharedImage
    }
  }
  fragment DownloadCategory on STRAPI_DOWNLOAD_CATEGORY {
    strapi_id
    categoryId: strapi_id
    title
    ranking
  }
`
