import _ from 'lodash'

export const classNames = (...classes) => classes.filter(Boolean).join(' ')

export const cn = classNames

export const scrollToTop = () => {
  if (window) {
    window.scroll({ top: 0, behavior: 'smooth' })
  }
}

export const getMarkdown = (content) => _.get(content, 'data.content')
