import React, { Fragment, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'
import { Dialog, Popover, Transition } from '@headlessui/react'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import { cn } from '../../helper'
import { ReactComponent as RelutionLogo } from '../../images/relution-logo.svg'
import LanguageSelect from '../base/LanguageSelect'
import Button from '../base/Button'
import GlobalNavPopover from '../base/GlobalNavPopover'
import GlobalNavSubPopover from '../base/GlobalNavSubPopover'

const PageHeader = ({
  localizations,
  linkTextRelution,
  LinkRelution,
  linkTextContact,
  LinkContact,
  linkTextDemo,
  LinkDemo,
  linkTextLogin,
  LinkLogin,
  navigation,
  activeLocales,
  location,
}) => {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)

  const closeBurgerMenu = useCallback(() => {
    setBurgerMenuOpen(false)
  }, [setBurgerMenuOpen])

  const toggleBurgerMenu = useCallback(() => {
    setBurgerMenuOpen(!burgerMenuOpen)
  }, [setBurgerMenuOpen, burgerMenuOpen])

  return (
    <>
      <header className="sticky top-0 z-header ">
        <div className="relative z-50 bg-white shadow-md lg:min-h-[6.875rem]">
          <div className="box">
            <div className="flex justify-between items-center relative lg:items-start">
              {LinkRelution && (
                <LinkRelution className="block lg:pt-15 lg:min-h-[6.875rem]">
                  <RelutionLogo className="w-[8.186018481rem] lg:w-[10.1875rem]" />
                  {linkTextRelution && (
                    <span className="sr-only">{linkTextRelution}</span>
                  )}
                </LinkRelution>
              )}
              <div className="mdmax:hidden lgmax:ml-auto lg:absolute lg:top-0 lg:right-0">
                <ul className="flex md:space-x-2 lg:space-x-5">
                  {LinkContact && linkTextContact && (
                    <li className="flex-none">
                      <Button as={LinkContact} variant="tertiary">
                        {linkTextContact}
                      </Button>
                    </li>
                  )}
                  {LinkDemo && linkTextDemo && (
                    <li className="flex-none">
                      <Button as={LinkDemo} variant="primary">
                        {linkTextDemo}
                      </Button>
                    </li>
                  )}
                  {LinkLogin && linkTextLogin && (
                    <li className="flex-none">
                      <Button
                        as={LinkLogin}
                        variant="secondary"
                        Icon={AccountBoxIcon}
                      >
                        {linkTextLogin}
                      </Button>
                    </li>
                  )}
                </ul>
              </div>
              <div className="ml-5 lg:hidden">
                <button
                  type="button"
                  onClick={toggleBurgerMenu}
                  className={cn(
                    'flex items-center justify-center py-3 bg-white',
                    burgerMenuOpen && 'pointer-events-none',
                  )}
                >
                  {burgerMenuOpen ? (
                    <>
                      <span className="sr-only">Close menu</span>
                      <span className="flex w-6 h-6" aria-hidden="true">
                        <CloseIcon className="flex-none" />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="sr-only">Open menu</span>
                      <span className="flex w-6 h-6" aria-hidden="true">
                        <MenuIcon className="flex-none" />
                      </span>
                    </>
                  )}
                </button>
              </div>
              {navigation && (
                <div className="hidden lg:flex lg:pt-14">
                  <Popover.Group as="nav" className="flex">
                    {navigation.items &&
                      navigation.items.map((mainItem) =>
                        mainItem.items && mainItem.items.length ? (
                          <Popover key={uid(mainItem)}>
                            {({ open }) => (
                              <>
                                <Popover.Button
                                  className={cn(
                                    'flex items-center px-3 h-12 text-button xl:px-3',
                                    open ? 'text-rio-500' : 'text-charcoal',
                                  )}
                                >
                                  <span>{mainItem.title}</span>
                                  <span className="flex w-6 h-6">
                                    {open ? (
                                      <ExpandLessIcon
                                        className="flex-none"
                                        aria-hidden
                                      />
                                    ) : (
                                      <ExpandMoreIcon
                                        className="flex-none"
                                        aria-hidden
                                      />
                                    )}
                                  </span>
                                </Popover.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 -translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 -translate-y-1"
                                >
                                  <Popover.Panel className="lgmax:hidden -mx-10 absolute z-10 top-full inset-x-0 transform shadow-lg bg-white">
                                    {mainItem.items && mainItem.items.length && (
                                      <ul className="px-10 py-5 grid gap-5 grid-flow-col auto-cols-fr xxl:p-10">
                                        {mainItem.items.map((subItem) => (
                                          <li
                                            key={uid(subItem)}
                                            className="max-w-[14.125rem]"
                                          >
                                            <GlobalNavSubPopover
                                              Link={subItem.Link}
                                              image={subItem.image}
                                              title={subItem.title}
                                              items={subItem.items}
                                            />
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </Popover.Panel>
                                </Transition>
                              </>
                            )}
                          </Popover>
                        ) : (
                          mainItem.Link && (
                            <mainItem.Link
                              key={uid(mainItem)}
                              className="flex items-center px-3 h-12 text-button"
                            >
                              <span>{mainItem.title}</span>
                            </mainItem.Link>
                          )
                        ),
                      )}
                  </Popover.Group>
                </div>
              )}
              <div className="lgmax:hidden lg:pt-14">
                <LanguageSelect
                  localizations={localizations}
                  activeLocales={activeLocales}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <Transition appear show={burgerMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          onClose={closeBurgerMenu}
          className="fixed inset-0 z-menu-popover overflow-y-auto"
        >
          <div className="min-h-screen bg-white">
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <GlobalNavPopover
                location={location}
                localizations={localizations}
                navigation={navigation}
                linkTextContact={linkTextContact}
                LinkContact={LinkContact}
                linkTextDemo={linkTextDemo}
                LinkDemo={LinkDemo}
                linkTextLogin={linkTextLogin}
                LinkLogin={LinkLogin}
                activeLocales={activeLocales}
              />
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

PageHeader.defaultProps = {
  localizations: [],
  activeLocales: [],
}

PageHeader.propTypes = {
  linkTextRelution: PropTypes.string,
  LinkRelution: PropTypes.func,
  linkTextContact: PropTypes.string,
  LinkContact: PropTypes.func,
  linkTextDemo: PropTypes.string,
  LinkDemo: PropTypes.func,
  linkTextLogin: PropTypes.string,
  LinkLogin: PropTypes.func,
  navigation: PropTypes.object,
  localizations: PropTypes.array,
  activeLocales: PropTypes.array,
}

export default PageHeader
