import React from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'

const GlobalNavSubPopover = ({ image, title, items, Link }) => (
  <>
    <div>
      {Link && image && (
        <Link className="block relative -mt-5 mb-5 aspect-16/9 bg-gray-50 xxl:-mt-10">
          <image.Component className="!absolute inset-0 w-full h-full" />
        </Link>
      )}
      {Link && title && (
        <Link className="block font-bold uppercase mb-2">{title}</Link>
      )}
      {items.length > 0 && (
        <ul>
          {items.map((subItem) => (
            <li key={uid(subItem)}>
              {subItem.Link ? (
                <subItem.Link className="block py-2 break-words">
                  {subItem.title}
                </subItem.Link>
              ) : (
                <div className="py-2">{subItem.title}</div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  </>
)

GlobalNavSubPopover.defaultProps = {
  items: [],
}

GlobalNavSubPopover.propTypes = {
  image: PropTypes.any,
  Link: PropTypes.any,
  title: PropTypes.string,
  items: PropTypes.array,
}

export default GlobalNavSubPopover
