import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { uid } from 'react-uid'
import _ from 'lodash'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import RichText from '../base/RichText'
import ImageColumn from '../blocks/ImageColumn'

const HighlightPlatform = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  overline,
  images,
  space,
  ...props
}) => {
  const hasHeading = !_.isEmpty(overline) || !_.isEmpty(headline)
  const enableHighlighting = true

  return (
    <Tag className={cn(className)} {...addValidHtmlProps(props)}>
      <div
        className={cn(
          'box bg-white grid gap-x-15 gap-y-15 xl:grid-cols-12',
          py(space),
        )}
      >
        {hasHeading && (
          <HeadlineTag className="xl:col-span-9" order={headlineOrder}>
            {overline && (
              <span className="block text-16 leading-1.3 font-bold uppercase mb-1">
                {overline}
              </span>
            )}
            {headline && (
              <RichText
                as="span"
                className="block text-32 leading-1.2 font-bold uppercase"
                enableHighlighting={enableHighlighting}
              >
                {headline}
              </RichText>
            )}
          </HeadlineTag>
        )}
        {images && images.length && (
          <ul className="row-start-2 flex justify-around xl:col-start-2 xl:col-span-10">
            {images.map((image) => (
              <li key={uid(image)} className="basis-1/3">
                <ImageColumn
                  image={image}
                  className="max-w-[16.5rem] mx-auto"
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </Tag>
  )
}

HighlightPlatform.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  images: [],
}

HighlightPlatform.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  overline: PropTypes.string,
  headline: PropTypes.string,
  images: PropTypes.array,
}

export default HighlightPlatform

export const query = graphql`
  fragment SectionsHighlightPlatform on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_PLATFORM {
    disableTopMargin
    space
    id
    strapi_component
    anchor
    headline
    headlineOrder
    overline
    images: platformImages {
      alt: alternativeText
      width
      height
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    tag
  }
`
