import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import ArticleCardLarge from '../base/ArticleCardLarge'
import LoadMoreButton from '../base/LoadMoreButton'
import Pagination from '../base/Pagination'

const ArticleList = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  items,
  loadMoreButtonText,
  loadingText,
  loading,
  space,
  hasPagination,
  ...props
}) => {
  const pageSize = parseInt(props.pageSize)
  const [paging, setPaging] = useState({
    currentPage: 1,
    offset: 0,
    limit: pageSize,
  })

  const numRemaining = () => items.length - paging?.limit
  const showLoadMoreButton = () => !hasPagination && numRemaining() > 0
  const loadMore = () => {
    const limit = Math.min(paging?.limit + pageSize, items.length)
    setPaging({
      currentPage: 1,
      offset: 0,
      limit,
    })
  }

  const showPagination = () => hasPagination && items.length > pageSize

  const handlePageChange = (newPage) => {
    const offset = (newPage - 1) * pageSize
    const limit = Math.min(offset + pageSize, items.length)

    setPaging({
      currentPage: newPage,
      offset,
      limit,
    })
  }

  return (
    <Tag className={cn(className)} {...addValidHtmlProps(props)}>
      <div className={cn('box bg-white', py(space))}>
        {headline && (
          <HeadlineTag
            className="md:col-span-5 xl:col-span-4 mb-10 break-words"
            order={headlineOrder}
          >
            <span className="block text-32 leading-1.2 font-bold uppercase md:text-44">
              {headline}
            </span>
          </HeadlineTag>
        )}
        {items && items.length && (
          <ul className="grid gap-5 md:grid-cols-2 lg:grid-cols-1">
            {items.slice(paging?.offset, paging?.limit).map((item) => (
              <li key={item.id || uid(item)} className="flex flex-col">
                <ArticleCardLarge
                  headlineOrder={headlineOrder + 1}
                  as="article"
                  {...item}
                  className="flex-1"
                />
              </li>
            ))}
          </ul>
        )}
        {loadMoreButtonText && showLoadMoreButton() && (
          <div className="pt-10 text-center">
            <LoadMoreButton
              text={loadMoreButtonText}
              loadingText={loadingText}
              loading={loading}
              onPress={() => loadMore()}
            />
          </div>
        )}
        {showPagination() && (
          <div className="mt-24">
            <Pagination
              itemsPerPage={pageSize}
              totalItems={items?.length}
              currentPage={paging?.currentPage}
              onPageChange={handlePageChange}
              boundaryCount={1}
              siblingCount={2}
            />
          </div>
        )}
      </div>
    </Tag>
  )
}

ArticleList.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  items: [],
  numRemaining: 0,
  hasPagination: false,
  pageSize: 10,
}

ArticleList.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  items: PropTypes.array,
  loadMoreButtonText: PropTypes.string,
  loadingText: PropTypes.string,
  numRemaining: PropTypes.number,
  loading: PropTypes.bool,
  hasPagination: PropTypes.bool,
  pageSize: PropTypes.number,
}

export default ArticleList
