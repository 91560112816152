import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import { cn } from '../../helper'
import { Platforms } from '../../constants/enums'

const colors = {
  [Platforms.Apple]: 'text-red',
  [Platforms.Android]: 'text-purple',
  [Platforms.Windows]: 'text-blue',
  [Platforms.Relution]: 'text-rio-500',
  [Platforms.ChromeOS]: 'text-[#4b9f65]',
  // TODO: add technical changelog!
}

const BadgeDividerBlock = ({ as: Tag, className, platform, text }) => {
  const platformId = _.get(platform, 'platformId', Platforms.Relution)

  return (
    <Tag
      className={cn(
        'relative break-words before:absolute before:top-1/2 before:left-0 before:transform before:-translate-1/2 before:w-full before:h-[0.1875rem] before:bg-current',
        colors[platformId],
        className,
      )}
    >
      <span
        className={cn(
          'relative z-10 px-2 py-1 text-16 font-bold uppercase bg-current',
        )}
      >
        <span className="text-white">{text}</span>
      </span>
    </Tag>
  )
}

BadgeDividerBlock.defaultProps = {
  as: 'div',
  platform: {
    platformId: Platforms.Relution,
  },
  text: 'Relution',
}

BadgeDividerBlock.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  platform: PropTypes.object,
  text: PropTypes.string,
}

export default BadgeDividerBlock

export const query = graphql`
  fragment BlocksBadgeDividerBlock on STRAPI__COMPONENT_BLOCKS_BADGE_DIVIDER_BLOCK {
    strapi_component
    id
    text
    platform {
      platformId
    }
  }
`
