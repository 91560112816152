import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import RichText from '../base/RichText'
import Icon from '../base/Icon'

const HighlightStats = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  overline,
  items,
  space,
  ...props
}) => {
  const hasHeading = !_.isEmpty(overline) || !_.isEmpty(headline)
  const enableHighlighting = true

  return (
    <Tag className={cn(className)} {...addValidHtmlProps(props)}>
      <div
        className={cn(
          'box bg-white grid gap-x-15 gap-y-8 md:grid-cols-12',
          py(space),
        )}
      >
        {hasHeading && (
          <HeadlineTag
            className="md:col-span-11 xl:col-span-4"
            order={headlineOrder}
          >
            {overline && (
              <span className="block text-16 leading-1.3 font-bold uppercase mb-1">
                {overline}
              </span>
            )}
            {headline && (
              <RichText
                as="span"
                className="block text-24 leading-1.2 font-bold uppercase md:text-32"
                enableHighlighting={enableHighlighting}
              >
                {headline}
              </RichText>
            )}
          </HeadlineTag>
        )}
        {items && items.length > 0 && (
          <dl className="grid gap-x-15 gap-y-8 grid-cols-2 md:grid-cols-4 md:col-span-12 xl:col-start-5 xl:col-span-8 xl:grid-cols-4">
            {items.map((item) => (
              <div
                key={item.name}
                className="flex flex-col items-center overflow-hidden"
              >
                <dt>
                  {item.iconName && (
                    <span className="flex mx-auto h-15 w-15 -mt-0.5 mb-2 md:mb-5">
                      <Icon name={item.iconName} />
                    </span>
                  )}
                  <p className="text-16 font-bold uppercase text-center leading-1.2 md:text-18 mb-0.5">
                    {item.name}
                  </p>
                </dt>
                <dd className="text-24 leading-1.2 font-bold md:text-32">
                  {item.stat}
                </dd>
              </div>
            ))}
          </dl>
        )}
      </div>
    </Tag>
  )
}

HighlightStats.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  items: [],
}

HighlightStats.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  overline: PropTypes.string,
  headline: PropTypes.string,
  items: PropTypes.array,
}

export default HighlightStats

export const query = graphql`
  fragment SectionsHighlightStats on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_STATS {
    disableTopMargin
    space
    id
    strapi_component
    anchor
    headline
    headlineOrder
    items {
      iconName
      name
      stat
    }
    overline
    tag
  }
`
