import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py, Space } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import Button from '../base/Button'

const HeroCompact = ({
  as: Tag,
  className,
  headlineOrder,
  Link,
  image,
  badgeText,
  headline,
  linkText,
  space,
  ...props
}) => {
  const hastContent =
    Boolean(headline) || Boolean(badgeText) || Boolean(linkText)

  return (
    <Tag
      className={cn(py(space || Space.none), className)}
      {...addValidHtmlProps(props)}
    >
      <div className="relative box grid overflow-hidden text-white lgmax:min-h-screen-4/6">
        {image && image.Component && (
          <image.Component className="!absolute inset-0 w-full h-full" />
        )}
        <div className="col-start-1 row-start-1 aspect-21/9" aria-hidden />
        {hastContent && (
          <div className="col-start-1 row-start-1 grid grid-cols-1 grid-rows-1 items-end">
            <div className="col-start-1 row-start-1 flex flex-col">
              <div className="py-10 flex flex-col relative z-10 md:pt-20">
                {badgeText && (
                  <p>
                    <span className="px-2 py-1 bg-greyish-brown text-white text-14 font-bold uppercase">
                      {badgeText}
                    </span>
                  </p>
                )}
                {headline && (
                  <HeadlineTag
                    className={cn(
                      badgeText && 'mt-5',
                      'max-w-2xl font-bold uppercase break-words text-28 leading-[2.625rem] sm:text-32 sm:leading-[3rem] sm:w-9/12 md:text-48 md:leading-[4.375rem]',
                    )}
                    order={headlineOrder}
                  >
                    <span className="block">
                      <span className="px-2 bg-rio-500 text-white decoration-clone">
                        {headline}
                      </span>
                    </span>
                  </HeadlineTag>
                )}
                {Link && linkText && (
                  <div className={cn((badgeText || headline) && 'mt-8')}>
                    <Button as={Link}>{linkText}</Button>
                  </div>
                )}
              </div>
            </div>
            {Link && (
              <Link className="absolute top-0 left-0 w-full h-full z-20">
                <span className="sr-only">{headline}</span>
              </Link>
            )}
          </div>
        )}
      </div>
    </Tag>
  )
}

HeroCompact.defaultProps = {
  as: 'div',
  headlineOrder: 2,
}

HeroCompact.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  Link: PropTypes.any,
  image: PropTypes.object,
  badgeText: PropTypes.string,
  headline: PropTypes.string,
  linkText: PropTypes.string,
}

export default HeroCompact

export const query = graphql`
  fragment SectionsHeroCompact on STRAPI__COMPONENT_SECTIONS_HERO_COMPACT {
    disableTopMargin
    space
    id
    anchor
    badgeText
    headline
    headlineOrder
    image {
      ...SharedImage
    }
    link {
      ...SharedLink
    }
    strapi_component
    tag
  }
`
