import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import _ from 'lodash'
import { buildLink } from '@amazeelabs/react-framework-bridge/gatsby'

import { PageBackgroundColors } from '@/constants/enums'
import { getLinkProps } from '@/utils/links'
import { enhanceStrapiData } from '@/utils/enhanceStrapiData'
import PageHeader from './layout/PageHeader'
import PageFooter from './layout/PageFooter'
import Sections from '@/components/sections'
import { cn } from '../helper'

// Export Strapi Fragments that don't belong to a component
export { SharedImage } from '../fragments/SharedImage'
export { SharedLink } from '../fragments/SharedLink'

const PageBackgroundClasses = {
  [PageBackgroundColors.white]: 'bg-white',
  [PageBackgroundColors.gray]: 'bg-off-white',
}

const parseAnchorJSON = (data, pageUrl = '') => {
  if (!data) return []
  const content = _.get(data, 'internal.content')
  const anchorsJSON = JSON.parse(content)
  // Support object and array syntax:
  // { "Digitale Bildung": "digitale-bildung" }
  //
  // OR
  //
  // [{
  //   "title": "Digitale Bildung",
  //   "anchor": "digitale-bildung",
  //   (Or an external URL)
  //   "url": "https://...",
  //   "target": "_blank"
  // }]
  const anchorsList = Array.isArray(anchorsJSON)
    ? anchorsJSON
    : Object.entries(anchorsJSON).map(([title, anchor]) => ({ title, anchor }))
  const anchors = anchorsList.map((linkProps) => {
    return {
      title: linkProps.title,
      Link: buildLink(getLinkProps({ url: pageUrl, ...linkProps })),
    }
  })
  return anchors
}

const enhanceNavigationObject = (item) =>
  enhanceStrapiData({
    ...item,
    title: item.text,
    Link: buildLink(getLinkProps({ ...item })),
    items: item.items
      ? item.items.map((subitem) => {
          const linkProps = getLinkProps({ ...subitem })
          return {
            ...subitem,
            title: subitem.text,
            Link: buildLink(linkProps),
            items: parseAnchorJSON(subitem.anchors, linkProps.href),
          }
        })
      : [],
  })

const Layout = ({
  children,
  global,
  pageContext,
  backgroundColor,
  location,
}) => {
  const staticQuery = useStaticQuery(graphql`
    query LayoutQuery {
      allStrapiInternationalization {
        nodes {
          ...SharedInternationalization
        }
      }
    }
  `)

  const locales =
    staticQuery.allStrapiInternationalization?.nodes?.[0]?.locales || []
  const activeLocales = [
    ...new Set([
      'de',
      ...locales
        .filter(
          (locale) => locale.live || process.env.GATSBY_IS_PREVIEW === 'true',
        )
        .map((locale) => locale.code),
    ]),
  ]

  const { header = {}, footer = {} } = global || {}

  const { linkRelution, linkContact, linkDemo, linkLogin } = header

  const linkTextRelution = linkRelution && linkRelution.text
  const LinkRelution = buildLink(getLinkProps({ ...linkRelution }))
  const linkTextContact = linkContact && linkContact.text
  const LinkContact = buildLink(getLinkProps({ ...linkContact }))
  const linkTextDemo = linkDemo && linkDemo.text
  const LinkDemo = buildLink(getLinkProps({ ...linkDemo }))
  const linkTextLogin = linkLogin && linkLogin.text
  const LinkLogin = buildLink(getLinkProps({ ...linkLogin }))

  const pageBgClass =
    PageBackgroundClasses[backgroundColor || PageBackgroundColors.white] ||
    PageBackgroundClasses[PageBackgroundColors.white]

  const navigation =
    header && header.navigation
      ? {
          items: header.navigation.map((item) => {
            return enhanceStrapiData({
              ...item,
              title: item.text,
              Link: buildLink(getLinkProps({ ...item })),
              items: item.items.map((subitem) => {
                const linkProps = getLinkProps({ ...subitem })
                return {
                  ...subitem,
                  title: subitem.text,
                  Link: buildLink(linkProps),
                  items: parseAnchorJSON(subitem.anchors, linkProps.href),
                }
              }),
            })
          }),
        }
      : {}

  const footerProps = {
    directories: footer?.directories
      ? footer.directories.map((directory) => ({
          ...enhanceNavigationObject(directory),
        }))
      : [],
    socialLinks: footer?.socialLinks
      ? footer.socialLinks.map((item) => enhanceNavigationObject(item))
      : [],
    legalLinks: footer?.legalLinks
      ? footer.legalLinks.map((item) => enhanceNavigationObject(item))
      : [],
    copyright: footer.copyright,
    linkTextRelution: footer.linkTextRelution,
    LinkRelution: buildLink(getLinkProps({ ...footer.linkRelution })),
    linkTextMway: footer.linkTextMway,
    LinkMway: buildLink(getLinkProps({ ...footer.linkMway })),
    linkTextApps: _.get(footer, 'linkApps.text'),
    LinkApps: buildLink(getLinkProps({ ...footer.linkApps })),
    privacySettingsText: footer.privacySettingsText,
    socialLinksTitle: footer.socialLinksTitle,
    toTopText: footer.toTopText,
  }

  return (
    <div
      id="top"
      className={cn('flex flex-col justify-between min-h-screen', pageBgClass)}
    >
      {/* Aligned to the top */}
      <div className="flex-1">
        <PageHeader
          location={location}
          activeLocales={activeLocales}
          localizations={pageContext?.localizations}
          navigation={navigation}
          linkTextRelution={linkTextRelution}
          LinkRelution={LinkRelution}
          linkTextContact={linkTextContact}
          LinkContact={LinkContact}
          linkTextDemo={linkTextDemo}
          LinkDemo={LinkDemo}
          linkTextLogin={linkTextLogin}
          LinkLogin={LinkLogin}
        />
        <div>{children}</div>
      </div>
      {/* Aligned to the bottom */}
      <div>
        {footer.sections && Boolean(footer.sections.length) && (
          <Sections sections={footer.sections} />
        )}
        <PageFooter {...footerProps} />
      </div>
    </div>
  )
}

export default Layout

export const query = graphql`
  fragment Site on STRAPI_SITE {
    metaTitleSuffix
    seo {
      ...SharedSeo
    }
  }
  fragment Header on STRAPI_HEADER {
    linkRelution {
      ...SharedLink
    }
    linkContact {
      ...SharedLink
    }
    linkDemo {
      ...SharedLink
    }
    linkLogin {
      ...SharedLink
    }
    navigation {
      text
      page {
        id
        strapi_id
        pageId: strapi_id
        route
      }
      items {
        text
        url
        target
        page {
          id
          strapi_id
          pageId: strapi_id
          route
        }
        anchors {
          internal {
            content
          }
        }
        image {
          alt: alternativeText
          width
          height
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
  fragment Footer on STRAPI_FOOTER {
    copyright
    privacySettingsText
    socialLinksTitle
    toTopText
    linkRelution {
      ...SharedLink
    }
    linkMway {
      ...SharedLink
    }
    linkApps {
      ...SharedLink
    }
    directories {
      text
      page {
        id
        strapi_id
        pageId: strapi_id
        route
      }
      items {
        text
        target
        url
        page {
          id
          strapi_id
          pageId: strapi_id
          route
        }
        anchors {
          internal {
            content
          }
        }
        image {
          alt: alternativeText
          width
          height
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    socialLinks {
      url
      title
      iconName
    }
    legalLinks {
      ...SharedLink
    }
    sections {
      ... on STRAPI__COMPONENT_SECTIONS_CONTACT_OPTIONS {
        ...SectionsContactOptions
      }
    }
  }
`
