import React from 'react'
import PropTypes from 'prop-types'

import { cn } from '../../helper'
import Image from '../base/Image'

const PartnerCollectionItem = ({ className, partner }) => {
  const Tag = partner.Link || 'div'
  return (
    <Tag className={cn(className, 'flex flex-col text-center')}>
      <span className="relative w-full max-w-[11rem] mx-auto overflow-hidden">
        <span className="aspect-3/2" />
        {partner.logo && (
          <Image
            className="!absolute inset-0 w-full h-full object-fit"
            media={partner.logo}
            objectFit="contain"
          />
        )}
      </span>
      {partner.name && (
        <span className="block mt-auto pt-1 text-14">{partner.name}</span>
      )}
    </Tag>
  )
}

PartnerCollectionItem.propTypes = {
  className: PropTypes.string,
  partner: PropTypes.object,
}

export default PartnerCollectionItem
