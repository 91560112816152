import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import _ from 'lodash'

import { mediaPropTypes } from '../../utils/types'
import { getStrapiMedia } from '../../utils/media'

const Image = ({ media, className, ...props }) => {
  const isDynamicImage = Boolean(_.get(media, 'localFile.childImageSharp'))
  const alt = media.alternativeText || 'An image uploaded to Strapi'

  if (isDynamicImage) {
    return (
      <GatsbyImage
        className={className}
        image={getImage(media.localFile)}
        alt={alt}
        {...props}
      />
    )
  }

  return <img src={getStrapiMedia(media.url)} alt={alt} className={className} />
}

Image.propTypes = {
  media: mediaPropTypes,
  className: PropTypes.string,
}

export default Image
