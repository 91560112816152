import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { uid } from 'react-uid'
import Flickity from 'react-flickity-component'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { pt, pb, Space } from '../../constants/space'
import BreakingNewsCard from '../base/BreakingNewsCard'
import HeadlineTag from '../base/HeadlineTag'

const BreakingNews = ({
  as: Tag,
  className,
  headlineOrder,
  tickerText,
  tickerEnabled,
  items,
  space,
  ...props
}) => {
  const flktyElem = useRef(null)

  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    if (!flktyElem.current) {
      return undefined
    }

    function handleChange() {
      if (flktyElem.current) {
        setSelectedIndex(flktyElem.current.selectedIndex)
      }
    }

    flktyElem.current.on('change', handleChange)

    return () => flktyElem.current.off('change', handleChange)
  })

  const gotoSlide = (index) => {
    if (flktyElem.current) {
      flktyElem.current.stopPlayer()
      flktyElem.current.select(index)
    }
  }

  const flickityOptions = {
    autoPlay: true,
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
    cellAlign: 'left',
  }

  const tickerTextDisplayed = `${tickerText} +++ `.repeat(20)

  return (
    <Tag
      className={cn(pb(space || Space.none), className)}
      {...addValidHtmlProps(props)}
    >
      <div className=" ">
        {tickerText && tickerEnabled && (
          <div
            className={cn(
              'box bg-rio-500 bg-lines-45 text-white',
              pt(space || Space.none),
            )}
          >
            <div className="pb-10">
              <HeadlineTag className="sr-only">{tickerText}</HeadlineTag>
              <div className="flex p-2 bg-white text-body sm:col-span-5 xl:col-span-4 whitespace-nowrap overflow-hidden">
                <span className="flex-none animate-ticker text-20 leading-1.2 font-bold uppercase md:text-24">
                  {tickerTextDisplayed}
                </span>
              </div>
            </div>
          </div>
        )}
        <div className={cn(' ')}>
          {items && items.length && (
            <>
              <Flickity
                flickityRef={(c) => {
                  flktyElem.current = c
                }}
                className="carousel box bg-rio-500 text-white pt-8 pb-10"
                elementType="ul"
                options={flickityOptions}
                static={false}
              >
                {items.map((item) => (
                  <li
                    key={item.id || uid(item)}
                    className="flex flex-col min-w-0 min-h-full w-full"
                  >
                    <BreakingNewsCard
                      headlineOrder={headlineOrder + 1}
                      as="article"
                      {...item}
                      className="flex-1"
                    />
                  </li>
                ))}
              </Flickity>
              <ol className="flex justify-center mx-auto px-5">
                {items.map((item, index) => (
                  <li
                    key={item.id || uid(item)}
                    className="flex-1 mx-1 max-w-25 md:mx-2"
                  >
                    <button
                      type="button"
                      className="group py-5 w-full"
                      onClick={() => gotoSlide(index)}
                    >
                      <span
                        className={cn(
                          'block h-1 transform transition group-hover:scale-y-150',
                          selectedIndex === index
                            ? 'bg-rio-500'
                            : 'bg-gray-200',
                        )}
                      />
                    </button>
                  </li>
                ))}
              </ol>
            </>
          )}
        </div>
      </div>
    </Tag>
  )
}

BreakingNews.defaultProps = {
  as: 'aside',
  headlineOrder: 2,
  items: [],
  tickerEnabled: true,
}

BreakingNews.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headline: PropTypes.string,
  headlineOrder: PropTypes.number,
  items: PropTypes.array,
}

export default BreakingNews

export const query = graphql`
  fragment SectionsBreakingNews on STRAPI__COMPONENT_SECTIONS_BREAKING_NEWS {
    strapi_component
    disableTopMargin
    space
    id
    anchor
    tickerText
    showTicker
    items {
      headline
      copy {
        data {
          copy
        }
      }
      image {
        mime
        url
        alt: alternativeText
        width
        height
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      link {
        ...SharedLink
      }
      badgeText
    }
  }
`
