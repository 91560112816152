import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py, Space } from '../../constants/space'

import HeadlineTag from '../base/HeadlineTag'
import Button from '../base/Button'
import RichText from '../base/RichText'

const HeroSection = ({
  as: Tag,
  className,
  headlineOrder,
  Link,
  image,
  badgeText,
  headline,
  linkText,
  copy,
  space,
  ...props
}) => (
  <Tag
    className={cn(py(space || Space.none), className)}
    {...addValidHtmlProps(props)}
  >
    <div className="box grid relative min-h-screen-4/6 overflow-hidden bg-black text-white">
      {image && image.Component && (
        <image.Component className="!absolute inset-0 w-full h-full" />
      )}
      <div className="grid grid-cols-1 grid-rows-1 items-end">
        <div
          className="col-start-1 row-start-1 aspect-16/9 max-h-[calc(100vh-10rem)]"
          aria-hidden
        />
        <div className="col-start-1 row-start-1 box flex flex-col pb-12">
          <div className="flex flex-col relative z-10">
            {badgeText && (
              <p className="mt-6">
                <span className="px-2 py-1 bg-greyish-brown text-white text-14 font-bold uppercase">
                  {badgeText}
                </span>
              </p>
            )}
            {headline && (
              <HeadlineTag
                className={cn(
                  badgeText && 'mt-5',
                  'max-w-2xl font-bold uppercase break-words text-28 leading-[2.625rem] sm:text-32 sm:leading-[3rem] sm:w-9/12 md:text-48 md:leading-[4.375rem]',
                )}
                order={headlineOrder}
              >
                <span className="block">
                  <span className="px-2 bg-rio-500 text-white decoration-clone">
                    {headline}
                  </span>
                </span>
              </HeadlineTag>
            )}
            {copy && (
              <RichText
                className={cn(
                  (headline || badgeText) && 'mt-6',
                  'w-9/12 max-w-2xl prose prose-white smmax:hidden',
                )}
              >
                {copy}
              </RichText>
            )}
            {Link && linkText && (
              <div className={cn((badgeText || headline || copy) && 'mt-8')}>
                <Button as={Link} showLinkIcon variant="primary">
                  {linkText}
                </Button>
              </div>
            )}
          </div>
        </div>
        {Link && (
          <Link className="absolute top-0 left-0 w-full h-full z-20">
            <span className="sr-only">{headline}</span>
          </Link>
        )}
      </div>
    </div>
  </Tag>
)

HeroSection.defaultProps = {
  as: 'article',
  headlineOrder: 2,
}

HeroSection.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  Link: PropTypes.any,
  image: PropTypes.any,
  badgeText: PropTypes.string,
  headline: PropTypes.string,
  linkText: PropTypes.string,
  copy: PropTypes.string,
}

export default HeroSection

export const query = graphql`
  fragment SectionsHeroSection on STRAPI__COMPONENT_SECTIONS_HERO_SECTION {
    disableTopMargin
    space
    anchor
    badgeText
    copy {
      data {
        copy
      }
    }
    headline
    headlineOrder
    tag
    image {
      mime
      url
      alt: alternativeText
      width
      height
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    link {
      ...SharedLink
    }
  }
`
