import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import { cn } from '../../helper'

const NarrowingClasses = ['', 'mx-auto w-3/4', 'mx-auto w-1/2']

const ImageColumn = ({ as: Tag, className, image, aspectRatio, narrowing }) => {
  const narrowingIndex = parseInt(narrowing)
  const narrowingClasses =
    NarrowingClasses[narrowingIndex || 0] || narrowingIndex[0]

  const { width, height } = image || { width: 0, height: 0 }
  const [ratioWidth, ratioHeight] = _.isString(aspectRatio)
    ? aspectRatio.replace('aspect_', '').split('_')
    : []
  const ratioWidthInt = parseInt(ratioWidth, 10)
  const ratioHeightInt = parseInt(ratioHeight, 10)

  const ratioX = ratioWidthInt > 0 ? ratioWidthInt : parseInt(width, 10)
  const ratioY = ratioHeightInt > 0 ? ratioHeightInt : parseInt(height, 10)
  const ratio = Math.round((ratioY / ratioX) * 1000000) / 10000

  const style = {
    '--ratio': `${ratio}%`,
  }

  return (
    <Tag
      className={cn(
        `relative before:float-left before:pt-ratio after:block after:clear-both`,
        narrowingClasses,
        className,
      )}
      style={style}
    >
      {image && image.Component && (
        <image.Component className="!absolute inset-0 w-full h-full" />
      )}
    </Tag>
  )
}

ImageColumn.defaultProps = {
  as: 'div',
  narrowing: 0,
}

ImageColumn.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.object,
  aspectRatio: PropTypes.string,
  narrowing: PropTypes.number,
}

export default ImageColumn

export const query = graphql`
  fragment BlocksImageBlock on STRAPI__COMPONENT_BLOCKS_IMAGE_BLOCK {
    strapi_component
    id
    aspectRatio
    narrowing
    image {
      ...SharedImage
    }
  }
`
