import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import { cn } from '../../helper'
import VideoEmbed from '../base/VideoEmbed'

const NarrowingClasses = ['', 'mx-auto w-3/4', 'mx-auto w-1/2']

const VideoColumn = ({ as: Tag, className, image, videoId, narrowing }) => {
  const narrowingIndex = parseInt(narrowing)
  const narrowingClasses =
    NarrowingClasses[narrowingIndex || 0] || narrowingIndex[0]

  return (
    <Tag className={cn('relative aspect-16/9', narrowingClasses, className)}>
      {image && image.Component && (
        <image.Component className="!absolute inset-0 w-full h-full" alt="" />
      )}
      {videoId && <VideoEmbed videoId={videoId} className="z-20" />}
    </Tag>
  )
}

VideoColumn.defaultProps = {
  as: 'div',
  narrowing: 0,
}

VideoColumn.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.object,
  videoId: PropTypes.string,
  narrowing: PropTypes.number,
}

export default VideoColumn

export const query = graphql`
  fragment BlocksVideoBlock on STRAPI__COMPONENT_BLOCKS_VIDEO_BLOCK {
    strapi_component
    id
    videoId
    image {
      ...SharedImage
    }
  }
`
