import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { cn } from '../../helper'
import PlatformIcon from './PlatformIcon'
import { Platforms } from '../../constants/enums'

const Sizes = {
  xs: 'w-6 h-8',
  default: 'w-8 h-[2.3333rem] sm:w-12 sm:h-14',
}

const variants = {
  fixed: `inline-flex space-x-1`,
}

const platformsOrder = [
  Platforms.Apple,
  Platforms.Android,
  Platforms.Windows,
  Platforms.ChromeOS,
  Platforms.Relution,
]

const PlatformShelf = ({ className, platforms, inverted, size }) => {
  const iconSizeClasses = Sizes[size]
  const sortedPLatforms = platforms.sort(
    (a, b) =>
      platformsOrder.indexOf(a.platformId) -
      platformsOrder.indexOf(b.platformId),
  )

  return (
    <ul className={cn(variants.fixed, className)}>
      {sortedPLatforms &&
        sortedPLatforms.map((platform) => (
          <li
            key={platform.platformId || platform}
            className={cn('flex-none', iconSizeClasses)}
          >
            <PlatformIcon
              platform={platform.platformId || platform}
              inverted={inverted}
            />
          </li>
        ))}
    </ul>
  )
}

PlatformShelf.defaultProps = {
  platforms: [],
  inverted: false,
  size: 'default',
}

PlatformShelf.propTypes = {
  className: PropTypes.string,
  platforms: PropTypes.array,
  inverted: PropTypes.bool,
  size: PropTypes.string,
}

export default PlatformShelf
