import React from 'react'
import PropTypes from 'prop-types'

import { Platforms } from '../../constants/enums'
import { ReactComponent as Android } from '../../icons/platforms/platforms-hex-android.svg'
import { ReactComponent as Apple } from '../../icons/platforms/platforms-hex-apple.svg'
import { ReactComponent as Relution } from '../../icons/platforms/platforms-hex-relution.svg'
import { ReactComponent as RelutionInverted } from '../../icons/platforms/platforms-hex-relution-inverted.svg'
import { ReactComponent as Windows } from '../../icons/platforms/platforms-hex-windows.svg'
import { ReactComponent as Chrome } from '../../icons/platforms/platforms-hex-chrome.svg'

const icons = {
  [Platforms.Apple]: {
    icon: Apple,
    iconInverted: Apple,
  },
  [Platforms.Android]: {
    icon: Android,
    iconInverted: Android,
  },
  [Platforms.Windows]: {
    icon: Windows,
    iconInverted: Windows,
  },
  [Platforms.Relution]: {
    icon: Relution,
    iconInverted: RelutionInverted,
  },
  [Platforms.ChromeOS]: {
    icon: Chrome,
    iconInverted: Chrome,
  },
}

const PlatformIcon = ({ platform, inverted }) => {
  const platformConfig = platform && icons[platform]
  const Tag = inverted ? platformConfig.iconInverted : platformConfig.icon

  return <Tag />
}

PlatformIcon.defaultProps = {
  platform: Platforms.Relution,
  inverted: false,
}

PlatformIcon.propTypes = {
  platform: PropTypes.string,
  inverted: PropTypes.bool,
}

export default PlatformIcon
