import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { uid } from 'react-uid'
import Flickity from 'react-flickity-component'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py, Space } from '../../constants/space'
import HeroSection from './HeroSection'

const HeroCollection = ({ as: Tag, className, items, space, id, ...props }) => {
  const flktyElem = useRef(null)

  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    if (!flktyElem.current) {
      return undefined
    }

    function handleChange() {
      if (flktyElem.current) {
        setSelectedIndex(flktyElem.current.selectedIndex)
      }
    }

    flktyElem.current.on('change', handleChange)

    return () => flktyElem.current.off('change', handleChange)
  })

  const gotoSlide = (index) => {
    if (flktyElem.current) {
      flktyElem.current.stopPlayer()
      flktyElem.current.select(index)
    }
  }

  const flickityOptions = {
    autoPlay: true,
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
  }

  const slides = items.map((item) => ({ ...item, space: 'none' }))

  return (
    <Tag
      className={cn(
        'relative overflow-hidden',
        py(space || Space.small),
        className,
      )}
      {...addValidHtmlProps(props)}
    >
      {slides && slides.length > 0 && (
        <div className="relative">
          <Flickity
            key={id}
            flickityRef={(c) => {
              flktyElem.current = c
            }}
            className="carousel"
            elementType="ul"
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate={false}
            static={false}
          >
            {slides.map((item) => (
              <li key={item.id || uid(item)} className={cn('w-full')}>
                <HeroSection as="article" {...item} />
              </li>
            ))}
          </Flickity>
          <ol className="absolute bottom-0 left-0 w-full flex justify-center px-5">
            {slides.map((item, index) => (
              <li
                key={uid('dot', index)}
                className="flex-1 mx-1 max-w-25 md:mx-2"
              >
                <button
                  type="button"
                  className="group py-5 w-full"
                  onClick={() => gotoSlide(index)}
                >
                  <span
                    className={cn(
                      'block h-1 transform transition group-hover:scale-y-150',
                      selectedIndex === index ? 'bg-rio-500' : 'bg-white',
                    )}
                  />
                </button>
              </li>
            ))}
          </ol>
        </div>
      )}
    </Tag>
  )
}

HeroCollection.defaultProps = {
  as: 'div',
  items: [],
}

HeroCollection.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  items: PropTypes.array,
}

export default HeroCollection

export const query = graphql`
  fragment SectionsHeroCollection on STRAPI__COMPONENT_SECTIONS_HERO_COLLECTION {
    space
    disableTopMargin
    id
    anchor
    strapi_component
    hero {
      items {
        anchor
        badgeText
        copy {
          data {
            copy
          }
        }
        headline
        headlineOrder
        tag
        image {
          mime
          url
          alt: alternativeText
          width
          height
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        link {
          ...SharedLink
        }
      }
    }
  }
`
