import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { uid } from 'react-uid'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import PartnerCollectionItem from '../base/PartnerCollectionItem'

const PartnerCollection = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  partners = [],
  space,
  ...props
}) => {
  const sortedPartners = partners.sort(function (a, b) {
    return a.name.trim().localeCompare(b.name.trim())
  })

  return (
    <Tag className={cn(className)} {...addValidHtmlProps(props)}>
      <div className={cn('box bg-white space-y-5', py(space))}>
        {headline && (
          <HeadlineTag
            className="relative break-words before:absolute before:top-1/2 before:left-0 before:transform before:-translate-1/2 before:w-full before:h-[0.1875rem] before:bg-gray-100"
            order={headlineOrder}
          >
            <span className="relative z-10 inline-block text-16 font-bold uppercase pr-5 bg-white">
              {headline}
            </span>
          </HeadlineTag>
        )}
        {sortedPartners && sortedPartners.length && (
          <ul className="grid gap-x-15 gap-y-10 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {partners.map((partner) => (
              <li key={partner.id || uid(partner)} className="flex flex-col">
                <PartnerCollectionItem partner={partner} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </Tag>
  )
}

PartnerCollection.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  partners: [],
}

PartnerCollection.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  partners: PropTypes.array,
}

export default PartnerCollection

export const query = graphql`
  fragment SectionsPartnerCollection on STRAPI__COMPONENT_SECTIONS_PARTNER_COLLECTION {
    id
    space
    disableTopMargin
    strapi_component
    anchor
    headline
    headlineOrder
    tag
    partners {
      title
      name
      route
      url
      activateInternalPage
      logo {
        mime
        url
        alt: alternativeText
        width
        height
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
