// NOTE: Watch out to safelist all classes in tailwind.config.js
// Otherwise they might not be available in prodcution.

export const Themes = {
  black: {
    root: 'text-white bg-black',
    prose: 'prose-white',
    buttonVariant: 'primary',
  },
  dark: {
    root: 'text-white bg-charcoal',
    prose: 'prose-white',
    buttonVariant: 'primary',
  },
  darkgreen: {
    root: 'text-white bg-rio-800',
    prose: 'prose-white',
    buttonVariant: 'primaryinverted',
  },
  gray: {
    root: 'text-charcoal bg-off-white',
    prose: '',
    buttonVariant: 'primary',
  },
  green: {
    root: 'text-white bg-rio',
    prose: 'prose-white',
    buttonVariant: 'primaryinverted',
  },
  white: {
    root: 'text-charcoal bg-white',
    prose: '',
    buttonVariant: 'primary',
  },
  transparent: {
    root: 'text-charcoal',
    prose: '',
    buttonVariant: 'primary',
  },
}

export const defaultThemeName = 'black'
