import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadingColumn from '../blocks/HeadingColumn'
import RichText from '../base/RichText'

const ContentSection = ({
  as: Tag,
  className,
  headlineOrder,
  overline,
  headline,
  badgeText,
  subline,
  copy,
  space,
  ...props
}) => (
  <Tag className={cn(className)} {...addValidHtmlProps(props)}>
    <div className={cn('box bg-white space-y-10', py(space))}>
      {(badgeText || overline || headline || subline) && (
        <HeadingColumn
          className="text-16 font-bold uppercase leading-1.3"
          headlineOrder={headlineOrder}
          overline={overline}
          headline={headline}
          badgeText={badgeText}
          subline={subline}
        />
      )}
      {copy && (
        <RichText className="prose break-words max-w-none">{copy}</RichText>
      )}
    </div>
  </Tag>
)

ContentSection.defaultProps = {
  as: 'div',
  headlineOrder: 2,
}

ContentSection.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  overline: PropTypes.string,
  copy: PropTypes.string,
}

export default ContentSection

export const query = graphql`
  fragment SectionsTextSection on STRAPI__COMPONENT_SECTIONS_TEXT_SECTION {
    disableTopMargin
    space
    id
    strapi_component
    anchor
    headline
    headlineOrder
    overline
    tag
    copy {
      data {
        copy
      }
    }
  }
`
