import React from 'react'
import PropTypes from 'prop-types'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { cn } from '../../helper'
import { useTranslation } from 'react-i18next'

const pageNumberClasses = {
  fixed: `relative inline-flex items-center px-2 sm:px-3 py-2 text-sm font-semibold focus:z-20`,
  ellipsis: `ring-1 ring-inset ring-off-white`,
  default: `ring-1 ring-inset ring-off-white hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rio`,
  current: `ring-1 ring-inset ring-off-white hover:bg-gray-50 z-10 text-rio-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rio`,
}

const arrowButtonClasses = {
  fixed: `relative inline-flex items-center px-2 min-h-full ring-1 ring-inset ring-off-white`,
  default: `hover:bg-gray-50 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rio`,
  disabled: `text-off-white cursor-not-allowed`,
}

const renderPageNumber = ({ number, currentPage, onClick }) => (
  <li key={number}>
    <a
      href="#"
      className={cn(
        pageNumberClasses.fixed,
        number === currentPage
          ? pageNumberClasses.current
          : pageNumberClasses.default,
      )}
      onClick={onClick}
    >
      {number}
    </a>
  </li>
)

const renderEllipsis = ({ key }) => (
  <li key={key} className="xsmax:hidden">
    <span className={cn(pageNumberClasses.fixed, pageNumberClasses.ellipsis)}>
      ...
    </span>
  </li>
)

const Pagination = ({
  itemsPerPage,
  totalItems,
  currentPage,
  onPageChange,
  boundaryCount,
  siblingCount,
  showSiblingEllipsis,
  className,
}) => {
  const { t } = useTranslation()

  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const handleClick = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      onPageChange(newPage)
    }
  }

  const showEllipsis = boundaryCount > 0 || showSiblingEllipsis

  const renderPageNumbers = () => {
    const pageNumbers = []
    const minPage = Math.max(1, currentPage - siblingCount)
    const maxPage = Math.min(totalPages, currentPage + siblingCount)

    // Render boundary pages at the start
    for (let i = 1; i <= boundaryCount && i <= totalPages; i++) {
      pageNumbers.push(
        renderPageNumber({
          number: i,
          currentPage,
          onClick: () => handleClick(i),
        }),
      )
    }

    // Render start ellipsis
    if (showEllipsis & (minPage > boundaryCount + 1)) {
      pageNumbers.push(renderEllipsis({ key: 'start-ellipsis' }))
    }

    // Render sibling pages
    for (let i = minPage; i <= maxPage; i++) {
      if (i > boundaryCount && i <= totalPages - boundaryCount) {
        pageNumbers.push(
          renderPageNumber({
            number: i,
            currentPage,
            onClick: (e) => {
              e.preventDefault()
              handleClick(i)
            },
          }),
        )
      }
    }

    // Render end ellipsis
    if (showEllipsis && maxPage < totalPages - boundaryCount) {
      pageNumbers.push(renderEllipsis({ key: 'end-ellipsis' }))
    }

    // Render boundary pages at the end
    for (let i = totalPages - boundaryCount + 1; i <= totalPages; i++) {
      pageNumbers.push(
        renderPageNumber({
          number: i,
          currentPage,
          onClick: (e) => {
            e.preventDefault()
            handleClick(i)
          },
        }),
      )
    }

    return pageNumbers
  }

  return (
    <nav
      aria-label="Pagination"
      className={cn('flex items-center justify-between bg-white', className)}
    >
      <ul className="isolate mx-auto inline-flex -space-x-px">
        <li>
          <a
            href="#"
            title={t('Global.previousPage')}
            className={cn(
              arrowButtonClasses.fixed,
              currentPage <= 1
                ? arrowButtonClasses.disabled
                : arrowButtonClasses.default,
            )}
            onClick={(e) => (e.preventDefault(), handleClick(currentPage - 1))}
          >
            <span className="sr-only">{t('Global.previousPage')}</span>
            <ArrowBackIcon className="text-20" aria-hidden="true" />
          </a>
        </li>
        {renderPageNumbers()}
        <li>
          <a
            href="#"
            title={t('Global.nextPage')}
            className={cn(
              arrowButtonClasses.fixed,
              currentPage === totalPages
                ? arrowButtonClasses.disabled
                : arrowButtonClasses.default,
            )}
            onClick={(e) => (e.preventDefault(), handleClick(currentPage + 1))}
          >
            <span className="sr-only">{t('Global.nextPage')}</span>
            <ArrowForwardIcon className="text-20" aria-hidden="true" />
          </a>
        </li>
      </ul>
    </nav>
  )
}

Pagination.defaultProps = {
  itemsPerPage: 10,
  currentPage: 0,
  boundaryCount: 0,
  siblingCount: 2,
  showSiblingEllipsis: false,
}

Pagination.propTypes = {
  itemsPerPage: PropTypes.number,
  totalItems: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  boundaryCount: PropTypes.number,
  siblingCount: PropTypes.number,
  showSiblingEllipsis: PropTypes.bool,
}

export default Pagination
