import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'
import { classNames } from '../../helper'

const RichText = ({ className, enableHighlighting, children }) => {
  const components = {
    ...(enableHighlighting && {
      em: ({ node, ...props }) => (
        <span className="text-rio-grande-green" {...props} />
      ),
    }),
    a: ({ node, ...props }) => {
      const isDownload =
        props.href?.match(/\/uploads\//gm) || props.href?.match(/\/static\//gm)
      const isExternal =
        isDownload ||
        props.href.startsWith('http') ||
        props.href.startsWith('mailto') ||
        props.href.startsWith('tel')
      const finalProps = {
        ...props,
        ...(isExternal && { target: '_blank' }),
        ...(isExternal && { rel: 'noreferrer' }),
        ...(isDownload && { download: true }),
      }
      return <a {...finalProps} />
    },
  }

  return (
    <Markdown className={classNames(className)} components={components}>
      {children}
    </Markdown>
  )
}

RichText.defaultProps = {
  enableHighlighting: false,
}

RichText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  enableHighlighting: PropTypes.bool,
}

export default RichText
