import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { buildLink } from '@amazeelabs/react-framework-bridge/gatsby'
import { useTranslation } from 'react-i18next'

import { ArticleTypes } from '@/constants/enums'
import { cn } from '@/helper'
import ArticleGrid from '@/components/sections/ArticleGrid'

const LatestReleaseNotes = ({ className, releaseNotes, ...props }) => {
  const { t } = useTranslation()
  const items = releaseNotes.map((item) => ({
    ...item,
    Link: buildLink({ href: item.route }),
    headline: item.teaserHeadline || item.title,
    badgeText: t('Global.releaseNote'),
  }))

  return (
    <ArticleGrid
      variant={ArticleTypes.releaseNote}
      items={items}
      className={cn(className)}
      hasPagination={true}
      pageSize={9}
      {...props}
    />
  )
}

LatestReleaseNotes.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  releaseNotes: [],
}

LatestReleaseNotes.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  loadMoreButtonText: PropTypes.string,
  loadingText: PropTypes.string,
  loadMoreCardText: PropTypes.string,
  releaseNotes: PropTypes.array,
}

export default LatestReleaseNotes

export const query = graphql`
  fragment SectionsLatestReleaseNotes on STRAPI__COMPONENT_SECTIONS_LATEST_RELEASE_NOTES {
    disableTopMargin
    space
    id
    anchor
    headline
    headlineOrder
    loadMoreButtonText
    loadMoreCardText
    loadingText
    strapi_component
  }
`
