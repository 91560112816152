import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import RichText from '../base/RichText'

const HighlightText = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  overline,
  copy,
  space,
  ...props
}) => {
  const hasHeading = !_.isEmpty(overline) || !_.isEmpty(headline)
  const enableHighlighting = true

  return (
    <Tag className={cn(className)} {...addValidHtmlProps(props)}>
      <div
        className={cn(
          'box bg-white grid gap-x-15 gap-y-4 sm:grid-cols-12',
          py(space),
        )}
      >
        {hasHeading && (
          <HeadlineTag
            className="sm:col-span-5 sm:max-w-100 xl:col-span-4"
            order={headlineOrder}
          >
            {overline && (
              <span className="block text-16 leading-1.3 font-bold uppercase mb-1">
                {overline}
              </span>
            )}
            {headline && (
              <RichText
                as="span"
                className="block text-32 leading-1.2 font-bold uppercase"
                enableHighlighting={enableHighlighting}
              >
                {headline}
              </RichText>
            )}
          </HeadlineTag>
        )}
        {copy && (
          <RichText className="max-w-reading sm:pt-6 sm:col-start-7 sm:col-span-6 xl:col-start-5 xl:col-span-8">
            {copy}
          </RichText>
        )}
      </div>
    </Tag>
  )
}

HighlightText.defaultProps = {
  as: 'div',
  headlineOrder: 2,
}

HighlightText.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  overline: PropTypes.string,
  headline: PropTypes.string,
  copy: PropTypes.string,
}

export default HighlightText

export const query = graphql`
  fragment SectionsHighlightText on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_TEXT {
    disableTopMargin
    space
    id
    strapi_component
    anchor
    headline
    headlineOrder
    copy {
      data {
        copy
      }
    }
    overline
    tag
  }
`
