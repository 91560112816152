import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadingColumn from '../blocks/HeadingColumn'
import RichText from '../base/RichText'
import FormContact from '../base/FormContact'

const Contact = ({
  as: Tag,
  className,
  headingBlock,
  copy,
  terms,
  space,
  redirect,
  ...props
}) => {
  const { t } = useTranslation()
  const headlineOrder = headingBlock?.headlineOrder || 2

  return (
    <Tag className={cn(className)} {...addValidHtmlProps(props)}>
      <div className={cn('box bg-white space-y-10', py(space))}>
        {headingBlock && (
          <HeadingColumn headlineOrder={headlineOrder} {...headingBlock} />
        )}
        {copy && (
          <RichText className="prose break-words max-w-none">{copy}</RichText>
        )}
        <div>
          <FormContact
            name="registration"
            className="mt-6 max-w-720"
            terms={terms?.data?.terms}
            redirectUri={redirect?.route}
          />
        </div>
      </div>
    </Tag>
  )
}

Contact.defaultProps = {
  as: 'div',
}

Contact.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
}

export default Contact

export const query = graphql`
  fragment SectionsContact on STRAPI__COMPONENT_SECTIONS_CONTACT {
    id
    strapi_component
    space
    disableTopMargin
    anchor
    tag
    headingBlock {
      ...BlocksHeadingBlock
    }
    copy {
      data {
        copy
      }
    }
    terms {
      data {
        terms
      }
    }
    redirect {
      route
    }
  }
`
