import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { classNames } from '../../helper'
import { py } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import Icon from '../base/Icon'
import Button from '../base/Button'

const FeatureList = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  items,
  Link,
  linkText,
  space,
  ...props
}) => (
  <Tag
    className={classNames('bg-white', py(space), className)}
    {...addValidHtmlProps(props)}
  >
    <div>
      {headline && (
        <HeadlineTag
          className="box sm:col-span-5 xl:col-span-4 mb-10 break-words"
          order={headlineOrder}
        >
          <span className="block text-32 leading-1.2 font-bold uppercase md:text-44">
            {headline}
          </span>
        </HeadlineTag>
      )}
      <div className="box pt-8 pb-12 bg-white space-y-10">
        {items && items.length > 0 && (
          <ul className="grid gap-6 grid-cols-2 sm:col-start-6 sm:col-span-7 xl:col-start-5 xl:col-span-8 xl:grid-cols-4">
            {items.map((item) => (
              <li
                key={item.text}
                className="flex flex-col items-center overflow-hidden"
              >
                {item.text && (
                  <HeadlineTag
                    className="mt-2 text-16 sm:text-20 leading-1.3 font-bold uppercase text-center md:text-24"
                    order={headlineOrder + 1}
                  >
                    {item.iconName && (
                      <span className="flex mx-auto h-18 w-18 mb-4">
                        <Icon name={item.iconName} />
                      </span>
                    )}
                    {item.text}
                  </HeadlineTag>
                )}
              </li>
            ))}
          </ul>
        )}
        {Link && (
          <p className="mt-auto pt-5 text-center">
            <Button as={Link} variant="primary" showLinkIcon>{linkText}</Button>
          </p>
        )}
      </div>
    </div>
  </Tag>
)

FeatureList.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  items: [],
}

FeatureList.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  items: PropTypes.array,
  Link: PropTypes.any,
  linkText: PropTypes.string,
}

export default FeatureList

export const query = graphql`
  fragment SectionsFeatureList on STRAPI__COMPONENT_SECTIONS_FEATURE_LIST {
    disableTopMargin
    space
    id
    strapi_component
    anchor
    headline
    headlineOrder
    items {
      iconName
      text
    }
    link {
      ...SharedLink
    }
    tag
  }
`
