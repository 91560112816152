import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import TextField from './TextField'
import Checkbox from './Checkbox'
import Button from './Button'
import RichText from './RichText'

const FormNewsletter = ({
  name,
  className,
  formAction,
  fieldNameGivenName,
  fieldNameFamilyName,
  fieldNameEmail,
  terms,
  submitLabel,
  information,
}) => {
  const { t } = useTranslation()
  const formName = name + '-'
  const finalSubmitLabel = submitLabel || t('Form.submit')

  return (
    <form
      autoComplete="on"
      className={className}
      action={formAction}
      method="post"
      target="_blank"
    >
      <div className="grid gap-x-5 gap-y-4 sm:grid-cols-2">
        <TextField
          label={t('Form.label.givenName')}
          htmlId={formName + 'givenName'}
          name={fieldNameGivenName}
          autoComplete="given-name"
          required
        />
        <TextField
          label={t('Form.label.familyName')}
          htmlId={formName + 'familyName'}
          name={fieldNameFamilyName}
          autoComplete="family-name"
          required
        />
        <TextField
          label={t('Form.label.email')}
          htmlId={formName + 'email'}
          name={fieldNameEmail}
          required
          type="email"
          className="col-start-1"
        />
      </div>
      <div className="mt-15 space-y-10">
        {information && (
          <RichText className="prose break-words">{information}</RichText>
        )}
        <div>
          <Checkbox
            name="privacypolicy"
            htmlId={formName + 'privacypolicy'}
            required
            label={terms}
          />
        </div>
        <div>
          <Button as="button" type="submit">
            {finalSubmitLabel}
          </Button>
        </div>
      </div>
    </form>
  )
}

FormNewsletter.defaultProps = {
  name: 'newsletter',
}

FormNewsletter.propTypes = {
  name: PropTypes.string,
  formAction: PropTypes.string,
  fieldNameGivenName: PropTypes.string,
  fieldNameFamilyName: PropTypes.string,
  fieldNameEmail: PropTypes.string,
  terms: PropTypes.string,
  information: PropTypes.string,
  submitLabel: PropTypes.string,
}

export default FormNewsletter
