import React from 'react'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Spinner from './Spinner'

const LoadMoreButton = ({ text, loading, loadingText, onPress }) => (
  <div>
    {loading ? (
      <span className="inline-flex py-1">
        <Spinner text={loadingText} />
      </span>
    ) : (
      <button
        type="button"
        className="inline-flex flex-col items-center text-14 font-bold leading-[1rem] uppercase"
        onClick={onPress}
      >
        <span className="">{text}</span>
        <span className="w-6 h-6">
          <ExpandMoreIcon />
        </span>
      </button>
    )}
  </div>
)

LoadMoreButton.defaultProps = {
  loading: false,
}

LoadMoreButton.propTypes = {
  text: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  onPress: PropTypes.func,
}

export default LoadMoreButton
