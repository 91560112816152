import React from 'react'
import PropTypes from 'prop-types'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import HeadlineTag from '../base/HeadlineTag'
import PlatformShelf from '../base/PlatformShelf'

const HeroInsight = ({
  as: Tag,
  className,
  headlineOrder,
  platforms,
  badgeText,
  headline,
  date,
  ...props
}) => {
  const hasContent = Boolean(headline) || Boolean(badgeText)

  return (
    <Tag
      className={cn('grid relative overflow-hidden text-white', className)}
      {...addValidHtmlProps(props)}
    >
      <div className="absolute inset-0 w-full h-full bg-cover bg-green-pattern before:absolute before:inset-0 before:bg-gradient-to-r before:from-white before:to-rio-500  before:mix-blend-multiply before:opacity-80" />
      {hasContent && (
        <div className="col-start-1 row-start-1 grid grid-cols-1 grid-rows-1 items-end">
          <div className="col-start-1 row-start-1 box flex flex-col">
            <div className="py-10 flex flex-col relative z-10 md:py-20">
              {badgeText && (
                <div className="flex items-center space-x-5">
                  <span className="px-2 py-1 text-rio-500  bg-white text-14 font-bold uppercase">
                    {badgeText}
                  </span>
                  {date && (
                    <span className="flex-none text-16 md:text-18">{date}</span>
                  )}
                </div>
              )}
              {headline && (
                <HeadlineTag
                  className={cn(
                    badgeText && 'mt-8',
                    'max-w-4xl font-bold uppercase break-words text-28 leading-1.2 sm:text-32 sm:w-9/12 md:text-48',
                  )}
                  order={headlineOrder}
                >
                  {headline}
                </HeadlineTag>
              )}
              {platforms && (
                <PlatformShelf
                  platforms={platforms}
                  inverted
                  className="overflow-hidden mt-12"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Tag>
  )
}

HeroInsight.defaultProps = {
  as: 'div',
  headlineOrder: 1,
  platforms: [],
}

HeroInsight.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  platforms: PropTypes.array,
  badgeText: PropTypes.string,
  headline: PropTypes.string,
  date: PropTypes.string,
}

export default HeroInsight
