export const mapRequestData = ({ mapping, data = {} }) => {
  if (typeof mapping !== 'function') return {}
  return {
    ...data,
    'g-recaptcha-response': data.recaptcha,
    ...mapping(data),
  }
}

export const cloudRegistrationMapping = (data = {}) => ({
  company: data.organization,
  email: data.email,
  fromWhereDidYouKnowUs: data.howhear,
  // fromWhereDidYouKnowUsText: data.howhear,
  givenName: data.givenName,
  language: data.language,
  newsletter: false,
  phone: data.phone,
  privacypolicy: true,
  surname: data.familyName,
  // New
  recaptcha: data.recaptcha,
  message: data.message,
  organizationType: data.organizationType,
})

export const onPremiseRegistrationMapping = (data = {}) => ({
  company: data.organization,
  email: data.email,
  firstname: data.givenName,
  fromWhereDidYouKnowUs: data.howhear,
  // fromWhereDidYouKnowUsText: data.howhear,
  language: data.language,
  lastname: data.familyName,
  newsletter: false,
  phone: data.phone,
  privacypolicy: true,
  // New
  recaptcha: data.recaptcha,
  message: data.message,
  organizationType: data.organizationType,
})

export const contactMapping = (data = {}) => ({
  // *** new mapping ***
  // user: {
  //   firstname: data.givenName,
  //   lastname: data.familyName,
  //   organization: data.organization,
  // },
  // contact: {
  //   email: data.email,
  //   phone: data.phone,
  // },
  // payload: {
  //   message_title: data.subject,
  //   message_content: data.message,
  //   contact_context: 'contact',
  // },
  // newsletter: {
  //   newsletter_subscription: false,
  //   newsletter_identifier: 'og87tz587tz5847t',
  // },
  // *** CURRENT MAPPING ***
  company: data.organization,
  email: data.email,
  language: data.language,
  message: data.message,
  name: `${data.givenName} ${data.familyName}`,
  newsletter: false,
  phone: data.phone,
  privacypolicy: true,
  subject: data.subject,
  // New
  recaptcha: data.recaptcha,
  organizationType: data.organizationType,
  fromWhereDidYouKnowUs: data.howhear,
  // fromWhereDidYouKnowUsText: data.howhear,
  product: data.product,
  eventInformation: data.eventInformation,
})

export const trainingMapping = (data = {}) => data
