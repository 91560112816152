import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

import { enhanceStrapiData } from '@/utils/enhanceStrapiData'
import { buildLink } from '@amazeelabs/react-framework-bridge/gatsby'
import ArticleCollection from '@/components/sections/ArticleCollection'
import { ArticleTypes } from '../../constants/enums'

const LatestArticles = ({ posts, insights, releaseNotes, ...props }) => {
  const { t } = useTranslation()

  const badgeTexts = {
    [ArticleTypes.insight]: t('Global.insight'),
    [ArticleTypes.news]: t('Global.news'),
    [ArticleTypes.successStory]: t('Global.successStory'),
    [ArticleTypes.releaseNote]: t('Global.releaseNote'),
  }

  const finalItems = [
    ...posts.map((item) => ({
      ...item,
      variant: ArticleTypes[item.category || 'news'] || ArticleTypes.news,
    })),
    ...insights.map((item) => ({
      ...item,
      variant: ArticleTypes.insight,
    })),
    ...releaseNotes.map((item) => ({
      ...item,
      variant: ArticleTypes.releaseNote,
    })),
  ]
    .sort(function (a, b) {
      return new Date(b.releasedAt) - new Date(a.releasedAt)
    })
    .slice(0, 9)
    .map((item) => enhanceStrapiData(item))
    .map((item) => ({
      ...item,
      badgeText: badgeTexts[item.variant],
      image: item.cover,
      headline: item.teaserHeadline || item.title,
      linkText: t('Global.more'),
      Link: buildLink({ href: item.route }),
    }))
  return <ArticleCollection as="div" items={finalItems} {...props} />
}

LatestArticles.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  posts: [],
  insights: [],
  releaseNotes: [],
}

LatestArticles.propTypes = {
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  posts: PropTypes.array,
  insights: PropTypes.array,
  releaseNotes: PropTypes.array,
  linkText: PropTypes.string,
  Link: PropTypes.any,
}

export default LatestArticles

export const query = graphql`
  fragment SectionsLatestArticles on STRAPI__COMPONENT_SECTIONS_LATEST_ARTICLES {
    space
    disableTopMargin
    id
    headline
    headlineOrder
    strapi_component
    anchor
    link {
      ...SharedLink
    }
  }
`
