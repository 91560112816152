export const Platforms = {
  Android: 'android',
  Apple: 'apple',
  Relution: 'relution',
  Windows: 'windows',
  ChromeOS: 'chromeos',
}

export const ArticleTypes = {
  insight: 'insight',
  releaseNote: 'releaseNote',
  news: 'news',
  successStory: 'successStory',
  event: 'event',
}

export const PageBackgroundColors = {
  white: 'white',
  gray: 'gray',
}

export const Products = {
  free: 'free',
  cloud: 'cloud',
  onpremise: 'onpremise',
}

export const EventTypes = {
  training: 'training',
  event: 'event',
}
