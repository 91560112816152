import React from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'

import { cn } from '../../helper'
import ArticleCardSmall from '../base/ArticleCardSmall'
import HeadlineTag from '../base/HeadlineTag'

const ArticleCollectionColumn = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  items,
}) => (
  <Tag className={cn(className)}>
    {headline && (
      <HeadlineTag
        className="text-16 leading-1.2 font-bold uppercase break-words mb-8 md:text-18"
        order={headlineOrder + 1}
      >
        {headline}
      </HeadlineTag>
    )}
    {items && items.length && (
      <ul className="grid gap-2 sm:grid-cols-2 sm:gap-5 md:grid-cols-3 lg:grid-cols-1 lg:gap-y-2">
        {items.map((item) => (
          <li key={uid(item)} className="flex flex-col">
            <ArticleCardSmall
              headlineOrder={headlineOrder + 1}
              as="article"
              {...item}
              className="flex-1"
            />
          </li>
        ))}
      </ul>
    )}
  </Tag>
)

ArticleCollectionColumn.defaultProps = {
  as: 'aside',
  items: [],
  headlineOrder: 2,
}

ArticleCollectionColumn.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  headline: PropTypes.string,
  headlineOrder: PropTypes.number,
  items: PropTypes.array,
}

export default ArticleCollectionColumn
