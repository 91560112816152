import React from 'react'
import PropTypes from 'prop-types'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'

import { classNames } from '../../helper'
import RichText from './RichText'

const TestimonialCard = ({
  as: Tag,
  className,
  name,
  description,
  text,
  image,
}) => (
  <Tag
    className={classNames(
      'flex flex-col px-5 pb-5 bg-white xl:px-8',
      className,
    )}
  >
    <div className="flex items-end space-x-4 sm:space-x-6">
      <div className="relative flex-none w-22 h-22 bg-gray-50">
        {image && image.Component && (
          <image.Component className="!absolute inset-0 w-full h-full" />
        )}
      </div>
      <div className="text-12 leading-1.2 sm:text-14">
        {name && <p className="font-bold">{name}</p>}
        {description && <p>{description}</p>}
      </div>
    </div>
    {text && <p className="prose mt-4 sm:mt-8 lg:pr-12">{text}</p>}
    <div
      className="mt-auto pt-4 ml-auto text-rio-500 transform -scale-x-100 sm:pt-8"
      aria-hidden
    >
      <div className="w-12 h-12">
        <DoubleArrowIcon />
      </div>
    </div>
  </Tag>
)

TestimonialCard.defaultProps = {
  as: 'article',
}

TestimonialCard.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.any,
}

export default TestimonialCard
