import React from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'
import { graphql } from 'gatsby'
import { Disclosure, Transition } from '@headlessui/react'

import { cn } from '../../helper'
import { py } from '../../constants/space'
import { Themes, defaultThemeName } from '../../constants/themes'
import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import Button from '../base/Button'
import RichText from '../base/RichText'
import HighlightColumn from '../blocks/HighlightColumn'
import ImageColumn from '../blocks/ImageColumn'
import VideoColumn from '../blocks/VideoColumn'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

const ToggleButtonContainer = ({ children, hasOverline }) => (
  <div
    className={cn(
      'absolute  right-0 md:right-auto md:left-[-3.5rem]',
      hasOverline
        ? 'top-[-0.125rem] md:top-[-0.5rem]'
        : 'top-[0.625rem] md:top-[0.125rem]',
    )}
  >
    {children}
  </div>
)

const ToggleButton = ({ animates, toggled }) => (
  <ArrowCircleRightIcon
    className={cn(
      'pointer-events-none !w-6 !h-6 rounded-full text-rio-500 md:!w-[2.25rem] md:!h-[2.25rem]',
      animates && 'transition',
      animates && toggled && 'rotate-90',
    )}
  />
)

const FeatureDescription = ({
  as: Tag,
  className,
  anchor,
  headlineOrder,
  overline,
  headline,
  space,
  copy,
  collapsible,
  initialCollapsed,
  theme,
  links,
  mediaBlocks,
  video,
  ...props
}) => {
  const {
    root: themeRootClasses,
    prose: themeProseClasses,
    buttonVariant,
  } = Themes[theme] || {}

  const headingElem = (
    <div className="relative mdmax:pr-8">
      {overline && (
        <div className="block">
          <span className="text-16 font-bold uppercase leading-1.3">
            {overline}
          </span>
        </div>
      )}
      <HighlightColumn
        className="text-16 font-bold uppercase leading-1.3"
        headlineOrder={headlineOrder}
        headline={headline}
      />
    </div>
  )

  const contentElems = (
    <div className="pt-7 space-y-7">
      {copy && (
        <RichText
          className={cn('prose break-words max-w-none', themeProseClasses)}
        >
          {copy}
        </RichText>
      )}
      {mediaBlocks && Boolean(mediaBlocks.length) && (
        <div className="space-y-10" key={uid(mediaBlocks)}>
          {mediaBlocks.map((mediaBlock) => (
            <ImageColumn key={uid(mediaBlock)} {...mediaBlock} />
          ))}
        </div>
      )}
      {video && (
        <div className="mx-auto w-3/4">
          <VideoColumn key={uid(video)} {...video} />
        </div>
      )}
      {links && Boolean(links.length) && (
        <ul className="flex flex-wrap -mt-5 -ml-5" key={uid(links)}>
          {links.map((link) => (
            <li key={uid(link)} className="mt-5 ml-5">
              <Button as={link.Link}>{link.linkText}</Button>
            </li>
          ))}
        </ul>
      )}
    </div>
  )

  const containerClasses = cn(
    'box md:grid md:gap-5 md:grid-cols-12',
    themeRootClasses,
    py(space),
  )

  return (
    <Tag id={anchor} className={cn(className)} {...addValidHtmlProps(props)}>
      {collapsible ? (
        <Disclosure defaultOpen={!initialCollapsed}>
          {({ open }) => (
            <div className={containerClasses}>
              <div className="relative md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8">
                {(overline || headline) && (
                  <Disclosure.Button className="block w-full text-left">
                    {headingElem}
                    <ToggleButtonContainer hasOverline={Boolean(overline)}>
                      <ToggleButton animates={true} toggled={open} />
                    </ToggleButtonContainer>
                  </Disclosure.Button>
                )}
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform -translate-y-2 opacity-0"
                  enterTo="transform translate-y-0 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform translate-y-0 opacity-100"
                  leaveTo="transform -translate-y-2 opacity-0"
                >
                  <Disclosure.Panel>{contentElems}</Disclosure.Panel>
                </Transition>
              </div>
            </div>
          )}
        </Disclosure>
      ) : (
        <div className={containerClasses}>
          <div className="relative md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8">
            <div>
              {headingElem}
              <ToggleButtonContainer hasOverline={Boolean(overline)}>
                <ToggleButton />
              </ToggleButtonContainer>
            </div>
            {contentElems}
          </div>
        </div>
      )}
    </Tag>
  )
}

FeatureDescription.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  collapsible: true,
  initialCollapsed: true,
}

FeatureDescription.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  tag: PropTypes.string,
  anchor: PropTypes.string,
  headlineOrder: PropTypes.number,
  headingBlock: PropTypes.object,
  Link: PropTypes.any,
  linkText: PropTypes.string,
  links: PropTypes.array,
  mediaBlocks: PropTypes.array,
  video: PropTypes.object,
}

export default FeatureDescription

export const query = graphql`
  fragment SectionsFeatureDescription on STRAPI__COMPONENT_SECTIONS_FEATURE_DESCRIPTION {
    id
    strapi_component
    anchor
    tag
    space
    disableTopMargin
    collapsible
    copy {
      data {
        copy
      }
    }
    headline
    headlineOrder
    initialCollapsed
    overline
    theme
    links {
      ...SharedLink
    }
    video {
      videoId
      image {
        ...SharedImage
      }
    }
    mediaBlocks {
      ...BlocksImageBlock
    }
  }
`
