import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { uid } from 'react-uid'
import Flickity from 'react-flickity-component'

import { cn } from '../../helper'
import { py, Space } from '../../constants/space'
import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import TestimonialCard from '../base/TestimonialCard'

const TestimonialCollection = ({
  as: Tag,
  className,
  headlineOrder,
  items,
  space,
  id,
  ...props
}) => {
  const flktyElem = useRef(null)

  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    if (!flktyElem.current) {
      return undefined
    }

    function handleChange() {
      if (flktyElem.current) {
        setSelectedIndex(flktyElem.current.selectedIndex)
      }
    }

    flktyElem.current.on('change', handleChange)

    return () => flktyElem.current.off('change', handleChange)
  })

  const gotoSlide = (index) => {
    if (flktyElem.current) {
      flktyElem.current.stopPlayer()
      flktyElem.current.select(index)
    }
  }

  const flickityOptions = {
    autoPlay: true,
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
  }

  return (
    <Tag
      className={cn('bg-off-white overflow-hidden', className)}
      {...addValidHtmlProps(props)}
    >
      <div className={cn('box', py(space || Space.none))}>
        {items && items.length && (
          <>
            <Flickity
              key={id}
              flickityRef={(c) => {
                flktyElem.current = c
              }}
              className="carousel"
              elementType="ul"
              options={flickityOptions}
              static={false}
            >
              {items.map((item) => (
                <li
                  key={item.id || uid(item)}
                  className="flex flex-col min-w-0 min-h-full w-10/12 max-w-165 mr-2 md:w-1/2 md:mr-5"
                >
                  <TestimonialCard
                    headlineOrder={headlineOrder + 1}
                    as="article"
                    {...item}
                    className="flex-1"
                  />
                </li>
              ))}
            </Flickity>
            <ol className="flex justify-center mx-auto px-5">
              {items.map((item, index) => (
                <li
                  key={uid('dot', index)}
                  className="flex-1 mx-1 max-w-25 md:mx-2"
                >
                  <button
                    type="button"
                    className="group py-5 w-full"
                    onClick={() => gotoSlide(index)}
                  >
                    <span
                      className={cn(
                        'block h-1 transform transition group-hover:scale-y-150',
                        selectedIndex === index ? 'bg-rio-500' : 'bg-white',
                      )}
                    />
                  </button>
                </li>
              ))}
            </ol>
          </>
        )}
      </div>
    </Tag>
  )
}

TestimonialCollection.defaultProps = {
  as: 'aside',
  headlineOrder: 2,
  items: [],
}

TestimonialCollection.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  items: PropTypes.array,
}

export default TestimonialCollection

export const query = graphql`
  fragment SectionsTestimonialCollection on STRAPI__COMPONENT_SECTIONS_TESTIMONIAL_COLLECTION {
    disableTopMargin
    space
    id
    anchor
    strapi_component
    items: testimonials {
      name
      description
      text
      image {
        mime
        url
        alt: alternativeText
        width
        height
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
