import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { uid } from 'react-uid'
import { Popover, Transition } from '@headlessui/react'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import CloseIcon from '@mui/icons-material/Close'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import Button from '../base/Button'
import { addLeadingHash } from '../../utils/links'
import { cn } from '../../helper'
// NOTE: For the AnchorNavigation space is deactivated, because it was already
// defined with "medium" in several places in Strapi and this value does not
// correspond to the desired design.
// import { py, Space } from '../../constants/space'

const AnchorNavigation = ({ items, className, space, ...props }) => (
  <div
    className={cn(
      'fixed bottom-0 z-anchor-navigation w-full lg:sticky lg:bottom-auto lg:top-[6.875rem]',
      className,
    )}
    {...addValidHtmlProps(props)}
  >
    {items && items.length > 0 && (
      <>
        {/* Desktop version as scrollable horizontal list */}
        <ul className="lgmax:hidden box py-3 bg-white shadow-lg flex items-center space-x-3 overflow-auto whitespace-nowrap scrollbar-hidden">
          {items.map((item) => (
            <li key={uid(item)} className="flex-none">
              {item && (
                <Button
                  as="a"
                  href={addLeadingHash(item.anchor)}
                  variant="tertiary"
                >
                  {item.text}
                </Button>
              )}
            </li>
          ))}
        </ul>
        {/* Mobile version with floating button an menu */}
        <Popover className="lg:hidden relative">
          {({ open }) => (
            /* Use the `open` state to conditionally change the direction of the chevron icon. */
            <>
              <div className="flex justify-center pb-5">
                <Popover.Button className="grid">
                  <div
                    className={cn(
                      'grid-stacked inline-flex items-center gap-x-2 px-4 py-3 rounded-full text-14 leading-none font-bold uppercase text-white bg-charcoal',
                      'transition',
                      open && 'opacity-0 scale-0',
                    )}
                  >
                    <FormatListBulletedIcon />
                    <span>Inhalt</span>
                  </div>
                  <div className="grid-stacked">
                    <div
                      className={cn(
                        'grid-stacked inline-flex justify-center items-center gap-x-2 w-12 h-12 rounded-full text-14 leading-none font-bold uppercase text-charcoal bg-off-white',
                        'transition',
                        !open && 'opacity-0 scale-0',
                      )}
                    >
                      <CloseIcon />
                      <span className="sr-only">Close</span>
                    </div>
                  </div>
                </Popover.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-250"
                enterFrom="opacity-0 translate-y-5"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-5"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  className={cn(
                    'absolute inset-x-0 bottom-0 pt-8 pb-22 text-14 leading-1.2 break-words bg-white',
                    'after:absolute after:inset-0 after:bg-red-500 after:shadow-lg after:rotate-180 after:-z-1',
                  )}
                >
                  <div className="w-screen max-w-md flex-auto overflow-hidden">
                    <div className="mb-3 px-5 sm:px-10">
                      <h3 className="font-bold uppercase">Auf dieser Seite</h3>
                    </div>
                    <ul className="">
                      {items.map((item) => (
                        <li key={uid(item)} className="flex-none">
                          {item && (
                            <Popover.Button
                              as="a"
                              href={addLeadingHash(item.anchor)}
                              className="block px-5 sm:px-10 py-2 text-16 cursor-pointer"
                            >
                              {item.text}
                            </Popover.Button>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="absolute left-0 bottom-0 w-full z-10 flex justify-center pb-5">
                    <Popover.Button className="grid">
                      <div
                        className={cn(
                          'grid-stacked inline-flex items-center gap-x-2 px-4 py-3 rounded-full text-14 leading-none font-bold uppercase text-white bg-charcoal',
                          'transition',
                          open && 'opacity-0 scale-0',
                        )}
                      >
                        <FormatListBulletedIcon />
                        <span>Inhalt</span>
                      </div>
                      <div className="grid-stacked">
                        <div
                          className={cn(
                            'grid-stacked inline-flex justify-center items-center gap-x-2 w-12 h-12 rounded-full text-14 leading-none font-bold uppercase text-charcoal bg-off-white',
                            'transition',
                            !open && 'opacity-0 scale-0',
                          )}
                        >
                          <CloseIcon />
                          <span className="sr-only">Close</span>
                        </div>
                      </div>
                    </Popover.Button>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </>
    )}
  </div>
)

AnchorNavigation.defaultProps = {
  items: [],
}

AnchorNavigation.propTypes = {
  items: PropTypes.array,
}

export default AnchorNavigation

export const query = graphql`
  fragment SectionsAnchorNavigation on STRAPI__COMPONENT_SECTIONS_ANCHOR_NAVIGATION {
    disableTopMargin
    space
    id
    strapi_component
    items {
      anchor
      text
    }
  }
`
