import React, { useState } from 'react'
import _ from 'lodash'

import { cn } from '../../helper'

const TextArea = ({
  className,
  htmlId,
  name,
  value,
  onChange,
  label,
  required,
  autoComplete,
  rows = 3,
}) => {
  return (
    <div className={className}>
      <label
        htmlFor={htmlId}
        className={cn(
          'block text-12 font-bold uppercase transition transform',
          value === ''
            ? 'opacity-0 translate-y-full'
            : 'opacity-100 translate-y-0',
        )}
      >
        {label}
        {required && '*'}
      </label>
      <div className="relative mt-1">
        <textarea
          rows={rows}
          name={name}
          id={htmlId}
          className="block w-full text-16 px-[0.5em] py-[0.4375em] shadow-off bg-white placeholder-gray-400 placeholder-italic border border-gray-400 focus:border-black focus:outline-0 focus:ring-0"
          autoComplete={autoComplete}
          placeholder={label + (required ? '*' : '')}
          value={value}
          required={required}
          onChange={(event) => onChange(event.target.value)}
        />
      </div>
    </div>
  )
}

export default TextArea
