import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { uid } from 'react-uid'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import TopicCard from '../base/TopicCard'

const TopicCollection = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  items,
  space,
  ...props
}) => (
  <Tag className={cn(className)} {...addValidHtmlProps(props)}>
    <div className={cn('box bg-white', py(space))}>
      {headline && (
        <HeadlineTag
          className="sm:col-span-5 xl:col-span-4 mb-10 break-words"
          order={headlineOrder}
        >
          <span className="block text-32 leading-1.2 font-bold uppercase md:text-44">
            {headline}
          </span>
        </HeadlineTag>
      )}
      {items && items.length > 0 && (
        <ul className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
          {items.map((item) => (
            <li key={item.id || uid(item)} className="flex flex-col">
              <TopicCard
                headlineOrder={headlineOrder + 1}
                as="article"
                {...item}
                className="flex-1"
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  </Tag>
)

TopicCollection.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  items: [],
}

TopicCollection.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  items: PropTypes.array,
}

export default TopicCollection

export const query = graphql`
  fragment SectionsTopicCollection on STRAPI__COMPONENT_SECTIONS_TOPIC_COLLECTION {
    disableTopMargin
    space
    id
    anchor
    strapi_component
    headline
    headlineOrder
    tag
    topic {
      items {
        headline
        copy {
          data {
            copy
          }
        }
        image {
          mime
          url
          alt: alternativeText
          width
          height
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        link {
          ...SharedLink
        }
        title
      }
    }
  }
`
