import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { cn } from '../../helper'

const AspectRatio = ({
  as: Tag,
  className,
  aspectRatio,
  size,
  children,
  placeholderClassName,
}) => {
  const { width, height } = size || { width: 0, height: 0 }
  const [ratioWidth, ratioHeight] = _.isString(aspectRatio)
    ? aspectRatio.split('/')
    : []
  const ratioWidthInt = parseInt(ratioWidth, 10)
  const ratioHeightInt = parseInt(ratioHeight, 10)

  const ratioX = ratioWidthInt > 0 ? ratioWidthInt : parseInt(width, 10)
  const ratioY = ratioHeightInt > 0 ? ratioHeightInt : parseInt(height, 10)
  const viewBox = `0 0 ${ratioX} ${ratioY}`

  return (
    <Tag className={cn(className)}>
      <svg viewBox={viewBox} className={placeholderClassName}></svg>
      {children}
    </Tag>
  )
}

AspectRatio.defaultProps = {
  as: 'div',
}

AspectRatio.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  size: PropTypes.object,
  aspectRatio: PropTypes.string,
  placeholderClassName: PropTypes.string,
}

export default AspectRatio
