import React from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'

import { cn } from '../../helper'
import { py } from '../../constants/space'
import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import TextColumn from '../blocks/TextColumn'
import ImageColumn from '../blocks/ImageColumn'
import ArticleCollectionColumn from '../blocks/ArticleCollectionColumn'
import BadgeDividerBlock from '../blocks/BadgeDividerBlock'

const columnComponents = {
  TextColumn,
  ImageColumn,
  ArticleCollectionColumn,
  BadgeDividerBlock,
}

const defaultSpacing = {
  TextColumn: `mt-15`,
  ImageColumn: `mt-15`,
  ArticleCollectionColumn: `mt-15`,
  BadgeDividerBlock: `mt-30`,
}

const asideComponentClasses = {
  ArticleCollectionColumn: `lgmax:pt-5 lgmax:border-t lgmax:border-t-gray-500 lg:max-w-xxs lg:pl-5 lg:border-l lg:border-l-gray-500`,
}

const indents = { 0: undefined, 1: 'mx-auto w-3/4', 2: 'mx-auto w-1/2' }

const mapColumns = (list, componentClasses = {}) =>
  list.map(({ component, attributes }, index) => {
    const Column = columnComponents[component]
    const defaultClasses = componentClasses[component]
    const indentClass = attributes.indent
      ? indents[attributes.indent]
      : undefined

    return (
      <Column
        key={uid(attributes)}
        className={cn(
          defaultClasses,
          indentClass,
          index > 0 && defaultSpacing[component],
        )}
        {...attributes}
      />
    )
  })

const ArticleColumns = ({
  as: Tag,
  className,
  contentItems,
  asideItems,
  space,
  ...props
}) => {
  const contentColumn = mapColumns(contentItems)
  const asideColumn = mapColumns(asideItems, asideComponentClasses)

  return (
    <Tag
      className={cn('bg-white', py('xlarge' || space), className)}
      {...addValidHtmlProps(props)}
    >
      <div className="box grid gap-x-15 gap-y-30 lg:grid-cols-12">
        <div className="min-w-0 lg:col-span-7">{contentColumn}</div>
        <div className="min-w-0 lg:col-start-9 lg:col-span-4">
          {asideColumn}
        </div>
      </div>
    </Tag>
  )
}

ArticleColumns.defaultProps = {
  as: 'div',
  contentItems: [],
  asideItems: [],
}

ArticleColumns.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  contentItems: PropTypes.array,
  asideItems: PropTypes.array,
}

export default ArticleColumns
