import React from 'react'
import PropTypes from 'prop-types'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { classNames } from '../../helper'

const VARIANT = {
  Insight: 'Insight',
  Release_Note: 'Release_Note',
}

const LoadMoreCard = ({
  as: Tag,
  className,
  text,
  numRemaining,
  loading,
  loadingText,
  variant = VARIANT.Release_Note,
  onPress,
}) => {
  const Background =
    variant === VARIANT.Insight ? (
      <div className="absolute inset-0 w-full h-full bg-cover bg-green-pattern before:absolute before:inset-0 before:bg-gradient-to-r before:from-rio-500 before:to-barf-green before:mix-blend-multiply before:opacity-80" />
    ) : (
      <div className="absolute inset-0 w-full h-full bg-cover bg-gray-pattern before:absolute before:inset-0 before:bg-gradient-to-r before:from-charcoal before:to-off-white before:mix-blend-multiply before:opacity-60" />
    )

  return (
    <Tag
      className={classNames(
        'relative grid text-white bg-off-white transition-colors',
        loading && 'opacity-50',
        className,
      )}
    >
      <div className="grid-stacked flex flex-col min-w-0">
        {Background}
        <button
          type="button"
          className={classNames(
            'relative flex-1 flex flex-col z-10 px-3 pt-17 pb-4 min-w-0 text-28 leading-1.2 font-bold uppercase break-words md:px-5',
          )}
          onClick={onPress}
        >
          <span>
            <span>+</span>
            <span>{numRemaining}</span>
          </span>
          <span>{text}</span>
          {loading && (
            <span className="sr-only" role="status">
              {loadingText}
            </span>
          )}
          <span className="mt-auto ml-auto w-12 h-12" aria-hidden>
            <ArrowForwardIcon />
          </span>
        </button>
      </div>
      <div className="aspect-3/2 grid-stacked" aria-hidden />
    </Tag>
  )
}

LoadMoreCard.defaultProps = {
  as: 'div',
  loading: false,
  numRemaining: 0,
}

LoadMoreCard.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  variant: PropTypes.string,
  text: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  numRemaining: PropTypes.number,
  onPress: PropTypes.func,
}

export default LoadMoreCard
