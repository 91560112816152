import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { buildLink } from '@amazeelabs/react-framework-bridge/gatsby'
import { useTranslation } from 'react-i18next'

import { ArticleTypes } from '@/constants/enums'
import { cn } from '@/helper'
import ArticleGrid from '@/components/sections/ArticleGrid'

const LatestInsights = ({ className, insights, ...props }) => {
  const { t } = useTranslation()
  const items = insights.map((item) => ({
    ...item,
    Link: buildLink({ href: item.route }),
    headline: item.teaserHeadline || item.title,
    badgeText: t('Global.insight'),
  }))

  return (
    <ArticleGrid
      variant={ArticleTypes.insight}
      items={items}
      className={cn(className)}
      {...props}
    />
  )
}

LatestInsights.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  insights: [],
}

LatestInsights.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  loadMoreButtonText: PropTypes.string,
  loadingText: PropTypes.string,
  loadMoreCardText: PropTypes.string,
  insights: PropTypes.array,
}

export default LatestInsights

export const query = graphql`
  fragment SectionsLatestInsights on STRAPI__COMPONENT_SECTIONS_LATEST_INSIGHTS {
    space
    disableTopMargin
    id
    anchor
    headline
    headlineOrder
    loadMoreButtonText
    loadMoreCardText
    loadingText
    strapi_component
  }
`
