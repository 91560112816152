import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as apps } from '../../icons/icons/rel-apps.svg'
import { ReactComponent as clients } from '../../icons/icons/rel-clients.svg'
import { ReactComponent as devices } from '../../icons/icons/rel-devices.svg'
import { ReactComponent as dsgvo } from '../../icons/icons/rel-dsgvo.svg'
import { ReactComponent as freetest } from '../../icons/icons/rel-freetest.svg'
import { ReactComponent as integrations } from '../../icons/icons/rel-integrations.svg'
import { ReactComponent as mail } from '../../icons/icons/rel-mail.svg'
import { ReactComponent as newsletter } from '../../icons/icons/rel-newsletter.svg'
import { ReactComponent as onpremisecloud } from '../../icons/icons/rel-onpremisecloud.svg'
import { ReactComponent as phone } from '../../icons/icons/rel-phone.svg'
import { ReactComponent as platforms } from '../../icons/icons/rel-platforms.svg'
import { ReactComponent as users } from '../../icons/icons/rel-users.svg'

const Icons = {
  apps,
  clients,
  devices,
  dsgvo,
  freetest,
  integrations,
  mail,
  newsletter,
  onpremisecloud,
  phone,
  platforms,
  users,
}

const Icon = ({ name }) => {
  const SvgIcon = Icons[name]

  return SvgIcon ? (
    <SvgIcon className="inline-block w-full h-full pointer-events-none fill-current" />
  ) : (
    <span>{name} not found</span>
  )
}

Icon.propTypes = {
  name: PropTypes.string,
}

export default Icon
