import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Themes, defaultThemeName } from '../../constants/themes'
import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py, Space } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import Button from '../base/Button'
import RichText from '../base/RichText'
import Sticker from '../base/Sticker'

const TeaserSplitImage = ({
  as: Tag,
  className,
  headlineOrder,
  Link,
  image,
  stickerText,
  headline,
  copy,
  linkText,
  theme = 'black',
  space,
  ...props
}) => {
  const {
    root: themeRootClasses,
    prose: themeProseClasses,
    buttonVariant,
  } = Themes[theme || defaultThemeName] || Themes[defaultThemeName]

  return (
    <Tag
      className={cn(
        'relative',
        py(space || Space.none),
        themeRootClasses,
        className,
      )}
      {...addValidHtmlProps(props)}
    >
      <div className="box grid gap-x-15 lgmax:pt-10 lg:grid-cols-12">
        {image && image.Component && (
          <div className="relative lg:min-h-full lg:row-start-1 lg:col-start-7 lg:col-span-6 hd:col-start-6 hd:col-span-7">
            <div className="aspect-16/9"></div>
            <image.Component className="!absolute inset-0 w-full h-full" />
            {stickerText && (
              <div className="absolute top-0 right-0 pr-12 pt-12">
                <Sticker text={stickerText} />
              </div>
            )}
          </div>
        )}
        <div className="flex flex-col relative z-10 py-10 lg:row-start-1 lg:col-start-1 lg:col-span-6 lg:max-w-100">
          {headline && (
            <HeadlineTag
              className={cn(
                'max-w-2xl text-24 leading-1.2 font-bold uppercase break-words md:text-32',
              )}
              order={headlineOrder}
            >
              <span>{headline}</span>
            </HeadlineTag>
          )}
          {copy && (
            <RichText
              className={cn(
                (headline || stickerText) && 'mt-6',
                'max-w-2xl prose',
                themeProseClasses,
              )}
            >
              {copy}
            </RichText>
          )}
          {Link && linkText && (
            <div
              className={cn(
                (stickerText || headline || copy) && 'mt-8',
                'relative z-20',
              )}
            >
              <Button as={Link} variant='primary'>
                {linkText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Tag>
  )
}

TeaserSplitImage.defaultProps = {
  as: 'article',
  headlineOrder: 2,
}

TeaserSplitImage.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  Link: PropTypes.any,
  image: PropTypes.any,
  stickerText: PropTypes.string,
  headline: PropTypes.string,
  copy: PropTypes.string,
  linkText: PropTypes.string,
  theme: PropTypes.string,
}

export default TeaserSplitImage

export const query = graphql`
  fragment SectionsTeaserImage on STRAPI__COMPONENT_SECTIONS_TEASER_IMAGE {
    disableTopMargin
    space
    id
    strapi_component
    anchor
    copy {
      data {
        copy
      }
    }
    headline
    headlineOrder
    image {
      ...SharedImage
    }
    imageLeft
    link {
      ...SharedLink
    }
    stickerText
    tag
    theme
  }
`
