import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import { cn } from '../../helper'
import { py } from '../../constants/space'
import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { EventTypes } from '../../constants/enums'
import Button from '../base/Button'
import RichText from '../base/RichText'
import HeadlineTag from '../base/HeadlineTag'
import BadgeDividerBlock from '../blocks/BadgeDividerBlock'
import ImageColumn from '../blocks/ImageColumn'
import FormContact from '../base/FormContact'
import FormTraining from '../base/FormTraining'
import Image from '../base/Image'

const EventDetail = ({
  as: Tag,
  className,
  anchor,
  headlineOrder,
  copy,
  description,
  metaInformation,
  type,
  terms,
  dates,
  price,
  image,
  space,
  logo,
  redirectUri,
  eventDateFormatted,
  place,
  title,
  ...props
}) => {
  const { t } = useTranslation()
  const isTraining = type && type === 'training'
  const bookingAnchor = 'booking'
  const aspectRatio = 'aspect_16_9'
  const eventInformationContactForm = `${title}, ${place}, ${eventDateFormatted}`

  return (
    <Tag
      id={anchor}
      className={cn(className, py(space))}
      {...addValidHtmlProps(props)}
    >
      <div className="box grid gap-x-15 gap-y-10 lg:grid-cols-12">
        {logo && (
          <div className="lg:col-start-8 lg:col-span-5">
            <Image
              className="w-full max-h-30"
              media={logo}
              objectFit="contain"
              objectPosition="0% 0%"
            />
          </div>
        )}
        <div className="min-w-0 space-y-24 lg:row-start-1 lg:col-span-7">
          <div className="space-y-10">
            <HeadlineTag order={headlineOrder}>
              <BadgeDividerBlock
                as="span"
                className="block"
                text={t(`Events.${type}.factsHeadline`)}
              />
            </HeadlineTag>
            {description && (
              <p className="prose break-words max-w-none whitespace-pre-line">
                {description}
              </p>
            )}
            {metaInformation && metaInformation.length > 0 && (
              <div>
                <ul className="flex flex-wrap -ml-10 -mt-5">
                  {metaInformation.map((meta) => (
                    <li className="ml-10 mt-5" key={meta.id}>
                      <HeadlineTag className="" order={headlineOrder}>
                        {meta.title}
                      </HeadlineTag>
                      <p className="text-18 font-bold">{meta.description}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {isTraining && (
              <div>
                <Button
                  href={'#' + bookingAnchor}
                  AppendIcon={ArrowDownwardIcon}
                >
                  {t('Events.training.scrollToBooking')}
                </Button>
              </div>
            )}
            {!isTraining && image && (
              <ImageColumn
                image={image}
                aspectRatio={aspectRatio}
                className="!mt-24"
              />
            )}
          </div>
          {copy && (
            <div className="space-y-10">
              <HeadlineTag order={headlineOrder}>
                <BadgeDividerBlock
                  as="span"
                  className="block"
                  text={t(`Events.${type}.copyHeadline`)}
                />
              </HeadlineTag>
              <RichText className="prose break-words max-w-none">
                {copy}
              </RichText>
            </div>
          )}
        </div>
      </div>
      {type === EventTypes.event && (
        <div className="mt-24 box grid gap-x-15 gap-y-24 lg:grid-cols-12">
          <div className="min-w-0 space-y-10 lg:col-span-7">
            <HeadlineTag order={headlineOrder}>
              <BadgeDividerBlock
                as="span"
                className="block"
                text={t('Events.event.formHeadline')}
              />
            </HeadlineTag>
            <p className="text-24 leading-1.2 font-bold uppercase break-words md:text-32">
              {t('Events.event.formCopy')}
            </p>
            <FormContact
              terms={terms?.data?.terms}
              submitLabel={t('Events.event.submit')}
              redirectUri={redirectUri}
              eventInformation={eventInformationContactForm}
            />
          </div>
        </div>
      )}
      {type === EventTypes.training && (
        <FormTraining
          anchor={bookingAnchor}
          terms={terms?.data?.terms}
          redirectUri={redirectUri}
          headlineOrder={2}
          submitLabel={t('Events.training.submit')}
          dates={dates}
          price={price}
          className="mt-24"
          eventInformation={eventInformationContactForm}
        />
      )}
    </Tag>
  )
}

EventDetail.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  metaInformation: [],
  dates: [],
  price: 0,
  maxNumberPeople: -1,
}

EventDetail.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  tag: PropTypes.string,
  anchor: PropTypes.string,
  headlineOrder: PropTypes.number,
  copy: PropTypes.string,
  description: PropTypes.string,
  endDate: PropTypes.string,
  dates: PropTypes.array,
  image: PropTypes.any,
  logo: PropTypes.any,
  maxNumberPeople: PropTypes.number,
  metaInformation: PropTypes.array,
  place: PropTypes.string,
  price: PropTypes.number,
  startDate: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  redirectUri: PropTypes.string,
  eventDateFormatted: PropTypes.string,
}

export default EventDetail
