import React, { useState } from 'react'
import _ from 'lodash'

import RichText from './RichText'
import { cn } from '../../helper'

const Checkbox = ({
  className,
  htmlId,
  name,
  value,
  onChange,
  label,
  required,
}) => {
  const finalLabel = `${label}${required ? '*' : ''}`
  const [validityChecked, setValidityChecked] = useState(false)

  return (
    <div className={className}>
      <div className="relative flex items-start">
        <div className="flex-none flex items-center h-5">
          <input
            id={htmlId}
            name={name}
            type="checkbox"
            checked={value}
            required={required}
            onInvalid={() => setValidityChecked(true)}
            {...(onChange && {
              onChange: (event) => onChange(event.target.checked),
            })}
            className={cn(
              'h-5 w-5 text-body border-body focus:ring-0 focus:outline-0',
              validityChecked && 'invalid:border-red',
            )}
          />
        </div>
        <div className="ml-3">
          <label htmlFor={htmlId} className="">
            <RichText className="prose">{finalLabel}</RichText>
          </label>
        </div>
      </div>
    </div>
  )
}

export default Checkbox
