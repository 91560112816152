import _ from 'lodash'

const COLLECTION_TYPE_AS_PAGE_IN_ORDER = [
  'page',
  'post',
  'insight',
  'releaseNote',
  'partner',
]

export const getLinkTarget = ({ newTab, rel }) => {
  const finalRel = _.isString(rel)
    ? rel
    : rel === null
    ? undefined
    : newTab === true
    ? 'noreferrer'
    : undefined

  return {
    ...(newTab === true ? { target: '_blank', rel: finalRel } : undefined),
  }
}

export const addLeadingHash = (str) =>
  str && !str.startsWith('#') ? `#${str}` : str

export const addLeadingSlash = (str) =>
  str && !str.startsWith('/') ? `/${str}` : str

export const addTrailingSlash = (str) =>
  str && !str.endsWith('/') ? `${str}/` : str

export const findCollectionType = (props) =>
  COLLECTION_TYPE_AS_PAGE_IN_ORDER.find((fieldName) =>
    _.isObject(props[fieldName]),
  )

export const getInternalLink = (props) => {
  const linkedCollectionTypeName = findCollectionType(props)
  const linkedCollectionType =
    linkedCollectionTypeName && props[linkedCollectionTypeName]

  const { newTab = false, anchor, url = '/' } = props
  const route = linkedCollectionType?.route
  const path = [route || url, anchor ? `#${anchor}` : null].filter(Boolean)
  const href = path.length ? path.join('') : undefined
  const linkProps = {
    href,
    ...getLinkTarget({ newTab }),
  }

  return linkProps
}

export const getExternalLink = (props) => {
  const { url, newTab } = props
  const linkProps = url
    ? {
        href: url,
        ...getLinkTarget({ newTab: newTab }),
      }
    : {}
  return linkProps
}

export const getLinkProps = (props = {}) => {
  if (props === null) {
    return {}
  }
  const linkProps =
    props.url && !props.url.startsWith('/')
      ? getExternalLink(props)
      : getInternalLink(props)
  return linkProps
}
