import _ from 'lodash'

// Transforms 01.02.2022 to 2022/02/01
export const transformStrapiDateString = (dateStr) => {
  return dateStr ? dateStr.split('.').reverse().join('/') : undefined
}

export const parseStrapiDate = (dateStr) => {
  return dateStr ? new Date(transformStrapiDateString(dateStr)) : undefined
}

const rangeOptions = { year: 'numeric', month: 'numeric', day: 'numeric' }
const rangeTimeOptions = {
  weekday: 'long',
  month: '2-digit',
  year: 'numeric',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}

export const formatRange = ({ startDate, endDate, locale }) => {
  if (!_.isDate(startDate)) return

  const format = new Intl.DateTimeFormat(locale, rangeOptions)
  const formattedDate = _.isDate(endDate)
    ? format.formatRange(startDate, endDate)
    : format.format(startDate)
  return formattedDate
}

export const formatRangeWithTime = ({ startDate, endDate, locale }) => {
  if (!_.isDate(startDate)) return

  const format = new Intl.DateTimeFormat(locale, rangeTimeOptions)
  const formattedDate = _.isDate(endDate)
    ? format.formatRange(startDate, endDate)
    : format.format(startDate)
  return formattedDate
}
