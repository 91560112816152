import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { uid } from 'react-uid'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadingColumn from '../blocks/HeadingColumn'
import TeaserImageLarge from '../base/TeaserImageLarge'
import LoadMoreButton from '../base/LoadMoreButton'
import RichText from '../base/RichText'

const PAGE_SIZE = 10

const TeaserCollection = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  subline,
  copy,
  items,
  loadMoreButtonText,
  loadingText,
  loading,
  space,
  ...props
}) => {
  const initialLimit = items.length === PAGE_SIZE ? PAGE_SIZE - 1 : PAGE_SIZE
  const [itemsLimit, setItemsLimit] = useState(initialLimit)

  const numRemaining = () => items.length - itemsLimit
  const showLoadMore = () => numRemaining() > 0

  const loadMore = () => {
    const limit = Math.min(itemsLimit + PAGE_SIZE, items.length)
    setItemsLimit(limit)
  }

  return (
    <Tag className={cn(className)} {...addValidHtmlProps(props)}>
      <div className={cn('box bg-white space-y-10', py(space))}>
        {headline && (
          <HeadingColumn
            headline={headline}
            subline={subline}
            headlineOrder={headlineOrder}
          />
        )}
        {copy && (
          <RichText
            className={cn(
              'prose smmax:hidden max-w-2xl lg:max-w-none lg:w-7/12 xxl:w-4/6',
            )}
          >
            {copy}
          </RichText>
        )}
        {items && items.length > 0 && (
          <ul className="space-y-5">
            {items.slice(0, itemsLimit).map((item) => (
              <li key={item.id || uid(item)} className="flex flex-col">
                <TeaserImageLarge
                  headlineOrder={headlineOrder + 1}
                  theme="green"
                  as="article"
                  {...item}
                  className="flex-1"
                />
              </li>
            ))}
          </ul>
        )}
        {loadMoreButtonText && showLoadMore() && (
          <div className="pt-10 text-center">
            <LoadMoreButton
              text={loadMoreButtonText}
              loadingText={loadingText}
              loading={loading}
              onPress={() => loadMore()}
            />
          </div>
        )}
      </div>
    </Tag>
  )
}

TeaserCollection.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  items: [],
  numRemaining: 0,
}

TeaserCollection.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  subline: PropTypes.string,
  copy: PropTypes.string,
  items: PropTypes.array,
  loadMoreButtonText: PropTypes.string,
  loadingText: PropTypes.string,
  numRemaining: PropTypes.number,
  loading: PropTypes.bool,
}

export default TeaserCollection

export const query = graphql`
  fragment SectionsTeaserCollection on STRAPI__COMPONENT_SECTIONS_TEASER_COLLECTION {
    disableTopMargin
    space
    id
    anchor
    copy {
      data {
        copy
      }
    }
    headline
    headlineOrder
    strapi_component
    subline
    tag
    teaser {
      items {
        copy {
          data {
            copy
          }
        }
        headline
        image {
          mime
          url
          alt: alternativeText
          width
          height
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        link {
          ...SharedLink
        }
      }
    }
  }
`
