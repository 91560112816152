import React from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'
import { Disclosure } from '@headlessui/react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import { cn } from '../../helper'
import GlobalNavCollapseSub from './GlobalNavCollapseSub'

const GlobalNavCollapse = ({ title, items, defaultOpen, location }) => {
  const activeSubItem =
    defaultOpen && items.find((item) => item.page?.route === location.pathname)

  // Second level nav
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button className="py-3 flex items-center justify-between w-full">
            <span
              className={cn('text-base font-bold uppercase transition-colors')}
            >
              {title}
            </span>
            <span
              className={cn(
                'w-6 h-6 transition transform',
                open ? 'text-current' : 'text-gray-400',
              )}
            >
              {open ? <RemoveIcon aria-hidden /> : <AddIcon aria-hidden />}
            </span>
          </Disclosure.Button>
          {items.length && (
            <Disclosure.Panel className="overflow-hidden">
              <ul className="ml-5 pb-3 divide-y divide-gray-200">
                {items.map((subItem) => (
                  <li key={uid(subItem)}>
                    {subItem.items && subItem.items.length ? (
                      <GlobalNavCollapseSub
                        item={subItem}
                        defaultOpen={activeSubItem === subItem}
                      />
                    ) : subItem.Link ? (
                      <subItem.Link className="py-3 flex items-center">
                        <span className="text-base">{subItem.title}</span>
                        <ChevronRightIcon />
                      </subItem.Link>
                    ) : (
                      <div className="py-3 flex items-center">
                        <span className="text-base">{subItem.title}</span>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  )
}

GlobalNavCollapse.defaultProps = {
  items: [],
  defaultOpen: false,
}

GlobalNavCollapse.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  location: PropTypes.object,
  defaultOpen: PropTypes.boolean,
}

export default GlobalNavCollapse
