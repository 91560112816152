import React from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import Button from './Button'
import LanguageSelect from './LanguageSelect'
import GlobalNavCollapse from './GlobalNavCollapse'

const GlobalNavPopover = ({
	localizations,
	linkTextContact,
	LinkContact,
	linkTextDemo,
	LinkDemo,
	linkTextLogin,
	LinkLogin,
	navigation,
	activeLocales,
	location,
}) => {
	// Find the current second level item for the current page
	let activeFirstLevelItem
	if (location) {
		navigation?.items?.forEach((item) => {
			item.items?.forEach((subItem, index) => {
				const active = subItem.page?.route === location.pathname
				if (active) {
					activeFirstLevelItem = item
				}
			})
		})
	}
	return (
		<div className="pt-12">
			<div className="box py-10">
				{navigation && navigation.items && navigation.items.length && (
					<div>
						<ul className="border-t border-b border-gray-200 divide-y divide-gray-200">
							{navigation.items.map((mainItem) => (
								<li key={uid(mainItem)}>
									<div className="-mx-5 px-5">
										{mainItem.items && mainItem.items.length ? (
											// First level with subnav
											<GlobalNavCollapse
												title={mainItem.title}
												items={mainItem.items}
												defaultOpen={activeFirstLevelItem === mainItem}
												location={location}
											/>
										) : (
											mainItem.Link && (
												// First level without subnav
												<mainItem.Link className="py-3 flex items-center">
													<span className="text-base font-bold uppercase">
														{mainItem.title}
													</span>
													<ChevronRightIcon />
												</mainItem.Link>
											)
										)}
									</div>
								</li>
							))}
						</ul>
					</div>
				)}
				<div className="pt-10">
					<div>
						<ul className="space-y-3">
							{LinkDemo && linkTextDemo && (
								<li className="col-span-2">
									<Button
										as={LinkDemo}
										variant="primary"
										className="w-full justify-center"
									>
										{linkTextDemo}
									</Button>
								</li>
							)}
							{LinkContact && linkTextContact && (
								<li>
									<Button
										as={LinkContact}
										variant="tertiary"
										className="w-full justify-center"
									>
										{linkTextContact}
									</Button>
								</li>
							)}
							{LinkLogin && linkTextLogin && (
								<li>
									<Button
										as={LinkLogin}
										variant="secondary"
										Icon={AccountBoxIcon}
										className="w-full justify-center"
									>
										{linkTextLogin}
									</Button>
								</li>
							)}
							<li className="!mt-10 text-center">
								<LanguageSelect
									localizations={localizations}
									activeLocales={activeLocales}
								/>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

GlobalNavPopover.defaultProps = {
	localizations: [],
	activeLocales: [],
}

GlobalNavPopover.propTypes = {
	linkTextContact: PropTypes.string,
	LinkContact: PropTypes.func,
	linkTextDemo: PropTypes.string,
	LinkDemo: PropTypes.func,
	linkTextLogin: PropTypes.string,
	LinkLogin: PropTypes.func,
	navigation: PropTypes.object,
	localizations: PropTypes.array,
	activeLocales: PropTypes.array,
	location: PropTypes.object,
}

export default GlobalNavPopover
