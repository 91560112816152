import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { cn } from '../../helper'
import RichText from '../base/RichText'

const Hash = '#'

const HighlightColumn = ({
	className,
	enableHighlighting,
	headline,
	...props
}) => {
	const headlineOrder = props.headlineOrder === null ? 2 : props.headlineOrder
	const finalHeadline =
		headlineOrder > 0
			? `${Hash.repeat(Math.max(1, Math.min(headlineOrder, 6)))} ${headline}`
			: headline

	return (
		<RichText
			className={cn(
				'block head-l max-w-4xl',
				className,
			)}
			enableHighlighting={enableHighlighting}
		>
			{finalHeadline}
		</RichText>
	)
}

HighlightColumn.defaultProps = {
	headlineOrder: 2,
	enableHighlighting: true,
}

HighlightColumn.propTypes = {
	className: PropTypes.string,
	headlineOrder: PropTypes.number,
	headline: PropTypes.string,
	enableHighlighting: PropTypes.bool,
}

export default HighlightColumn

export const query = graphql`
  fragment BlocksHighlightBlock on STRAPI__COMPONENT_BLOCKS_HIGHLIGHT_BLOCK {
    strapi_component
    id
    headline
    headlineOrder
    enableHighlighting
  }
`
