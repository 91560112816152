import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py, Space } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'

const HeroSection = ({
  as: Tag,
  className,
  headlineOrder,
  image,
  headline,
  subline,
  space,
  ...props
}) => (
  <Tag
    className={cn(
      'box grid relative overflow-hidden text-white',
      py(space || Space.none),
      className,
    )}
    {...addValidHtmlProps(props)}
  >
    {image && image.Component && (
      <image.Component className="!absolute inset-0 w-full h-full" />
    )}
    <div className="grid-stacked aspect-16/9 max-h-screen" aria-hidden />
    <div className="grid-stacked grid grid-cols-1 grid-rows-1 items-end">
      <div className="col-start-1 row-start-1 flex flex-col py-5 sm:py-10">
        <div className="flex flex-col relative z-10 max-h-full">
          {headline && (
            <HeadlineTag
              className="max-w-xl font-bold uppercase break-words text-28 leading-[2.625rem] sm:text-32 sm:leading-[3rem] sm:w-9/12 md:text-48 md:leading-[4.375rem]"
              order={headlineOrder}
            >
              <span className="block">
                <span className="px-2 bg-rio-500 text-white decoration-clone">
                  {headline}
                </span>
              </span>
            </HeadlineTag>
          )}
          {subline && (
            <p className="mt-1 max-w-3xl">
              <span className="px-2 py-1 bg-greyish-brown text-white text-22 font-bold uppercase md:text-28 decoration-clone">
                {subline}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  </Tag>
)

HeroSection.defaultProps = {
  as: 'article',
  headlineOrder: 2,
}

HeroSection.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  image: PropTypes.any,
  headline: PropTypes.string,
  subline: PropTypes.string,
}

export default HeroSection

export const query = graphql`
  fragment SectionsHeadingImage on STRAPI__COMPONENT_SECTIONS_HEADING_IMAGE {
    disableTopMargin
    space
    id
    strapi_component
    headline
    anchor
    headlineOrder
    subline
    tag
    image {
      mime
      url
      alt: alternativeText
      width
      height
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
