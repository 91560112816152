import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'

import { cn } from '../../helper'
import { py, Space } from '../../constants/space'
import { addValidHtmlProps } from '../../utils/addValidHtmlProps'

const TestimonialSection = ({
  as: Tag,
  className,
  backgroundImage,
  testimonial,
  space,
  ...props
}) => (
  <Tag
    className={cn(py(space || Space.none), className)}
    {...addValidHtmlProps(props)}
  >
    <div className="relative">
      {backgroundImage && backgroundImage.Component && (
        <backgroundImage.Component className="!absolute inset-0 w-full h-full" />
      )}
      {testimonial && (
        <div className="relative z-10 box py-5 grid xl:grid-cols-12">
          <div className="flex bg-white p-5 sm:p-10 sm:space-x-5 xl:col-span-7">
            <div className="smmax:hidden flex-none text-rio-500 w-9 h-9 -mt-px lg:-mt-0.5 lg:w-12 lg:h-12 ">
              <DoubleArrowIcon />
            </div>
            <div>
              {testimonial.text && (
                <p className="text-22 leading-1.2 italic md:text-28 lg:text-36">
                  {testimonial.text}
                </p>
              )}
              {(testimonial.name || testimonial.description) && (
                <div className="mt-10 text-12 leading-1.2 sm:text-14">
                  {testimonial.name && (
                    <p className="font-bold">{testimonial.name}</p>
                  )}
                  {testimonial.description && <p>{testimonial.description}</p>}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  </Tag>
)

TestimonialSection.defaultProps = {
  as: 'article',
}

TestimonialSection.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  testimonial: PropTypes.object,
  backgroundImage: PropTypes.any,
}

export default TestimonialSection

export const query = graphql`
  fragment SectionsTestimonialSection on STRAPI__COMPONENT_SECTIONS_TESTIMONIAL_SECTION {
    disableTopMargin
    space
    id
    strapi_component
    anchor
    backgroundImage {
      alt: alternativeText
      width
      height
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    testimonial {
      name
      description
      text
      image {
        mime
        url
        alt: alternativeText
        width
        height
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
