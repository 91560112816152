import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import { Products } from '../../constants/enums'
import HeadingColumn from '../blocks/HeadingColumn'
import RichText from '../base/RichText'
import FormRegistration from '../base/FormRegistration'
import HeadlineTag from '../base/HeadlineTag'

const AccountRegistration = ({
  as: Tag,
  className,
  headingBlock,
  copy,
  copyPreProducts,
  copyPostProducts,
  copyPreForm,
  copyPostForm,
  productCards,
  pageFree: redirectUriOther,
  pageCloud: redirectUriCloud,
  pageOP: redirectUriOnPremise,
  terms,
  space,
  ...props
}) => {
  const { t } = useTranslation()
  const headlineOrder = headingBlock?.headlineOrder || 2

  const products = productCards.map((card) => ({
    title: card.title,
    headline: card.headline,
    copy: card.copy?.data?.copy,
    value: Products[card.product],
  }))

  const [currentProduct, setProduct] = useState(products[0].value)

  const redirects = {
    other: redirectUriOther?.route,
    [Products.cloud]: redirectUriCloud?.route,
    [Products.onpremise]: redirectUriOnPremise?.route,
  }

  return (
    <Tag className={cn(className)} {...addValidHtmlProps(props)}>
      <div className={cn('box bg-white space-y-10', py(space))}>
        {headingBlock && (
          <HeadingColumn headlineOrder={headlineOrder} {...headingBlock} />
        )}
        {copy && (
          <RichText className="prose break-words max-w-none">{copy}</RichText>
        )}
        <div>
          {copyPreProducts?.data?.copyPreProducts && (
            <RichText className="mt-10 prose break-words max-w-none">
              {copyPreProducts?.data?.copyPreProducts}
            </RichText>
          )}
          <ul className="mt-10 grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {products.map((plan, index) => (
              <li
                key={plan.value}
                onClick={() => setProduct(plan.value)}
                className={cn(
                  'p-5 flex flex-col cursor-pointer bg-cover overflow-hidden md:p-10',
                  index === 0 && 'sm:col-span-2 lg:col-span-1',
                  currentProduct === plan.value
                    ? 'text-white bg-green-pattern'
                    : 'bg-gray-pattern',
                )}
              >
                <div className="max-w-sm">
                  <HeadlineTag
                    order={headlineOrder + 1}
                    className="uppercase break-words font-bold text-28 sm:text-32"
                  >
                    <span>{plan.title}</span>
                  </HeadlineTag>
                  <HeadlineTag
                    order={headlineOrder + 2}
                    className="mt-8 font-bold uppercase break-words"
                  >
                    <span>{plan.headline}</span>
                  </HeadlineTag>
                  <RichText
                    className={cn(
                      'mt-5 prose break-words max-w-none',
                      currentProduct === plan.value && 'prose-white',
                    )}
                  >
                    {plan.copy}
                  </RichText>
                </div>
                <div className="mt-auto pt-10">
                  <div className="text-center uppercase font-bold">
                    {currentProduct === plan.value
                      ? t('SectionsAccountRegistration.selected')
                      : t('SectionsAccountRegistration.select')}
                  </div>
                  <div
                    className={cn(
                      'mt-2 mx-auto relative text-center w-10 h-10 border border-current rounded-full',
                      currentProduct === plan.value &&
                        'after:block after:absolute after:left-1/2 after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:h-6 after:w-6 after:bg-current after:rounded-full',
                    )}
                  />
                </div>
              </li>
            ))}
          </ul>
          {copyPostProducts?.data?.copyPostProducts && (
            <RichText className="mt-10 prose break-words max-w-none">
              {copyPostProducts?.data?.copyPostProducts}
            </RichText>
          )}
        </div>
        <div>
          {copyPreForm?.data?.copyPreForm && (
            <RichText className="mt-10 prose break-words max-w-none">
              {copyPreForm?.data?.copyPreForm}
            </RichText>
          )}
          <FormRegistration
            name="registration"
            className="mt-6 max-w-720"
            variant={currentProduct}
            terms={terms?.data?.terms}
            redirects={redirects}
          />
          {copyPostForm?.data?.copyPostForm && (
            <RichText className="mt-10 prose break-words max-w-none">
              {copyPostForm?.data?.copyPostForm}
            </RichText>
          )}
        </div>
      </div>
    </Tag>
  )
}

AccountRegistration.defaultProps = {
  as: 'div',
  productCards: [],
}

AccountRegistration.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
}

export default AccountRegistration

export const query = graphql`
  fragment SectionsAccountRegistration on STRAPI__COMPONENT_SECTIONS_ACCOUNT_REGISTRATION {
    id
    strapi_component
    space
    disableTopMargin
    anchor
    tag
    headingBlock {
      ...BlocksHeadingBlock
    }
    copy {
      data {
        copy
      }
    }
    copyPreForm {
      data {
        copyPreForm
      }
    }
    copyPostForm {
      data {
        copyPostForm
      }
    }
    copyPreProducts {
      data {
        copyPreProducts
      }
    }
    copyPostProducts {
      data {
        copyPostProducts
      }
    }
    terms {
      data {
        terms
      }
    }
    productCards {
      product
      title
      headline
      copy {
        data {
          copy
        }
      }
    }
    pageOP {
      route
    }
    pageFree {
      route
    }
    pageCloud {
      route
    }
  }
`
