import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { classNames } from '../../helper'
import { py } from '../../constants/space'
import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import RichText from '../base/RichText'
import Icon from '../base/Icon'

const ContactOptions = ({ as: Tag, className, items, space, ...props }) => (
  <Tag
    className={classNames('bg-off-white', py(space), className)}
    {...addValidHtmlProps(props)}
  >
    <div className="box">
      {items && items.length > 0 && (
        <ul className="grid gap-6 sm:grid-cols-2 xl:grid-cols-4">
          {items.map((item) => (
            <li
              key={item.linkText}
              className="flex flex-col items-center overflow-hidden"
            >
              {item.Link && (
                <p className="mt-2 text-20 leading-1.3 font-bold uppercase text-center md:text-24">
                  <item.Link>
                    {item.iconName && (
                      <span
                        className="mx-auto mb-3 flex items-center justify-center h-18 w-18 text-rio-grande-green"
                        aria-hidden
                      >
                        <Icon name={item.iconName} />
                      </span>
                    )}
                    {item.linkText}
                  </item.Link>
                </p>
              )}
              {item.title && (
                <RichText className="prose mt-auto pt-2">{item.title}</RichText>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  </Tag>
)

ContactOptions.defaultProps = {
  as: 'div',
  items: [],
}

ContactOptions.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  items: PropTypes.array,
}

export default ContactOptions

export const query = graphql`
  fragment SectionsContactOptions on STRAPI__COMPONENT_SECTIONS_CONTACT_OPTIONS {
    disableTopMargin
    space
    id
    strapi_component
    items {
      title
      iconName
      link {
        ...SharedLink
      }
    }
  }
`
