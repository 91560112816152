export const formatCurrency = ({
  value,
  digits = 2,
  language = 'de',
  currency = 'EUR',
}) => {
  if (isNaN(value)) return

  const format = new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    minimumFractionDigits: digits,
  })

  return format.format(value)
}
