export function getStrapiMedia(url) {
  if (url == null) {
    return null
  }

  // Return the full URL if the media is hosted on an external provider
  if (url.startsWith('http') || url.startsWith('//')) {
    return url
  }

  // Otherwise prepend the URL path with the Strapi URL
  return `${process.env.GATSBY_STRAPI_URL || 'http://localhost:1337'}${url}`
}

export const getAspectRatio = ({ width, height }) => {
  const w = parseInt(width)
  const h = parseInt(height)
  const valid = !isNaN(w) && w > 0 && !isNaN(h) && h > 0
  const aspectRatio = valid ? h / w : 1
  return aspectRatio
}

export const getAspectRatioInverted = ({ width, height }) =>
  1 / getAspectRatio({ width, height })

export const getAspectRatioCssPercent = ({ width, height }) =>
  `${getAspectRatio({ width, height } || 0) * 100}%`

export const getAspectRatioCssPercentInverted = ({ width, height }) =>
  `${getAspectRatioInverted({ width, height } || 0) * 100}%`

export const getAspectRatioCssString = ({ width, height }) => {
  const w = parseInt(width)
  const h = parseInt(height)
  const valid = !isNaN(w) && w > 0 && !isNaN(h) && h > 0
  const aspectRatio = `${h} / ${w}`
  return valid && aspectRatio
}

export const insertAspectRatioCssProp = ({ width, height }) => ({
  aspectRatio: getAspectRatio({ width, height }),
})
