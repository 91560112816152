import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import { Themes, defaultThemeName } from '../../constants/themes'
import HeadlineTag from '../base/HeadlineTag'
import RichText from '../base/RichText'

const HeadingSection = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  copy,
  theme,
  space,
  ...props
}) => {
  const enableHighlighting = true

  const { root: themeRootClasses, prose: themeProseClasses } =
    Themes[theme] || {}

  return (
    <Tag
      className={cn(themeRootClasses, className)}
      {...addValidHtmlProps(props)}
    >
      <div className={cn('box space-y-8 text-center', py(space))}>
        {headline && (
          <HeadlineTag className="mx-auto max-w-lg" order={headlineOrder}>
            {headline && (
              <RichText
                as="span"
                className="block text-32 leading-1.2 font-bold uppercase md:text-44"
                enableHighlighting={enableHighlighting}
              >
                {headline}
              </RichText>
            )}
          </HeadlineTag>
        )}
        {copy && (
          <RichText
            className={cn(
              'prose prose-white mx-auto max-w-lg',
              themeProseClasses,
            )}
          >
            {copy}
          </RichText>
        )}
      </div>
    </Tag>
  )
}

HeadingSection.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  theme: defaultThemeName,
}

HeadingSection.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  copy: PropTypes.string,
  theme: PropTypes.string,
}

export default HeadingSection

export const query = graphql`
  fragment SectionsHeadingSection on STRAPI__COMPONENT_SECTIONS_HEADING_SECTION {
    disableTopMargin
    space
    id
    anchor
    copy {
      data {
        copy
      }
    }
    headline
    headlineOrder
    strapi_component
    tag
    theme
  }
`
