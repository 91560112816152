import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../helper'
import HeadlineTag from './HeadlineTag'
import PlatformShelf from './PlatformShelf'
import Image from './Image'
import { ArticleTypes } from '../../constants/enums'
import { formatRange, parseStrapiDate } from '../../utils/date'

const HEADLINE_CLASSES = {
  [ArticleTypes.news]: `px-2 bg-rio-500 text-white decoration-clone`,
  [ArticleTypes.successStory]: `px-2 bg-rio-500 text-white decoration-clone`,
  [ArticleTypes.insight]: ``,
  [ArticleTypes.releaseNote]: ``,
  [ArticleTypes.event]: ``,
}

const ArticleCard = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  subline,
  badgeText,
  datetime,
  Link,
  image,
  platforms,
  variant = ArticleTypes.releaseNote,
  highlighted,
  startDate,
  endDate,
  logo,
}) => {
  const { i18n } = useTranslation()
  const { language } = i18n

  const Background =
    variant === ArticleTypes.insight ? (
      <div className="absolute inset-0 w-full h-full bg-cover bg-green-pattern" />
    ) : variant === ArticleTypes.releaseNote ||
      variant === ArticleTypes.event ? (
      <div className="absolute inset-0 w-full h-full bg-cover bg-gray-pattern" />
    ) : (
      image &&
      image.Component && (
        <image.Component className="!absolute inset-0 w-full h-full" alt="" />
      )
    )

  const showDatetime =
    variant === ArticleTypes.insight || variant === ArticleTypes.releaseNote

  const headlineContainerClasses =
    variant === ArticleTypes.news || variant === ArticleTypes.successStory
      ? 'mt-auto'
      : ''

  const headlineWithBg =
    variant === ArticleTypes.news || variant === ArticleTypes.successStory

  const showDateRange = variant === ArticleTypes.event
  const dateRange = formatRange({
    startDate: parseStrapiDate(startDate),
    endDate: parseStrapiDate(endDate),
    locale: language,
  })

  const showMetaInfo = Boolean(
    (showDatetime && datetime) ||
      (platforms && platforms.length) ||
      (showDateRange && dateRange),
  )

  return (
    <Tag
      className={classNames(
        'relative grid bg-off-white',
        variant === ArticleTypes.insight && 'text-white',
        className,
      )}
    >
      <div className="grid-stacked flex flex-col min-w-0">
        {Background}
        <div
          className={classNames(
            'flex-1 flex flex-col relative z-10 px-3 min-w-0 md:px-5',
            showMetaInfo ? 'pb-4' : 'pb-3 md:pb-5',
          )}
        >
          {badgeText && (
            <p className="mt-6 break-words">
              <span
                className={classNames(
                  'px-2 py-1  text-16 font-bold uppercase',
                  variant === ArticleTypes.insight
                    ? 'bg-white text-rio-grande-green'
                    : 'bg-greyish-brown text-white',
                )}
              >
                {badgeText}
              </span>
            </p>
          )}
          {headline && (
            <HeadlineTag
              className={classNames(
                headlineContainerClasses,
                'pt-5 text-28 font-bold uppercase break-words',
                headlineWithBg ? 'leading-[2.625rem]' : 'leading-1.2',
                highlighted && 'max-w-xl md:w-9/12',
              )}
              order={headlineOrder + 1}
            >
              <span className="block">
                <span className={HEADLINE_CLASSES[variant]}>{headline}</span>
              </span>
              {subline && (
                <span className="block font-normal normal-case break-words">
                  {subline}
                </span>
              )}
            </HeadlineTag>
          )}
          {showMetaInfo && (
            <div className="mt-auto flex justify-between items-end space-x-5">
              {datetime && (
                <p className="flex-none text-16 md:text-18">{datetime}</p>
              )}
              {dateRange && (
                <p className="flex-none text-16 md:text-18">{dateRange}</p>
              )}
              {logo && (
                <Image
                  className="w-1/2 max-h-15"
                  media={logo}
                  objectFit="contain"
                  objectPosition="100% 100%"
                />
              )}
              {platforms && platforms.length > 0 && (
                <PlatformShelf
                  platforms={platforms}
                  inverted={variant === ArticleTypes.insight}
                  className="overflow-hidden"
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="aspect-3/2 grid-stacked" aria-hidden />
      {Link && (
        <Link className="absolute top-0 left-0 w-full h-full z-20">
          <span className="sr-only">
            {badgeText}: {headline}
          </span>
        </Link>
      )}
    </Tag>
  )
}

ArticleCard.defaultProps = {
  as: 'article',
  headlineOrder: 2,
  platforms: [],
  highlighted: false,
}

ArticleCard.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  variant: PropTypes.string,
  Link: PropTypes.any,
  image: PropTypes.any,
  badgeText: PropTypes.string,
  headline: PropTypes.string,
  subline: PropTypes.string,
  platforms: PropTypes.array,
  datetimetime: PropTypes.string,
  highlighted: PropTypes.bool,
}

export default ArticleCard
