import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as StickerIcon } from '../../icons/sticker-star.svg'

const Sticker = ({ text }) => (
  <span className="flex justify-center items-center relative aspect-1/1">
    <span className="relative z-10 p-5 text-center text-white text-12 font-bold uppercase leading-1.2 whitespace-pre">
      <span className="pt-1">{text}</span>
    </span>
    <StickerIcon className="absolute inset-0 w-full h-full text-rio-grande-green fill-current" />
  </span>
)

Sticker.defaultProps = {}

Sticker.propTypes = {
  text: PropTypes.string,
}

export default Sticker
