import _, { isString } from 'lodash'
import {
  buildImage,
  buildLink,
} from '@amazeelabs/react-framework-bridge/gatsby'
import { getImage } from 'gatsby-plugin-image'
import { getLinkProps } from './links'

export const transformDownloadLinks = (downloads = []) => {
  return downloads
    .flatMap((download) => {
      return download.files
        ? download.files.map((file) => {
            return {
              url: file.url,
              publicURL: file.localFile?.publicURL,
            }
          })
        : []
    })
    .reduce(
      (obj, download) => ({ ...obj, [download.url]: download.publicURL }),
      {},
    )
}

export const transformImage = (data) => {
  const isLocalFileDefined = _.get(data, 'localFile.childImageSharp')
  const localFile = _.get(data, 'localFile')
  const gatsbyImageData = getImage(localFile)

  const imageSrc = data?.url || data?.src

  const withImages = data
    ? {
        ...data,
        Component: isLocalFileDefined
          ? buildImage({
              image: gatsbyImageData,
              alt: data?.alt || data?.alternativeText || '',
            })
          : imageSrc
          ? buildImage({
              src: imageSrc,
              alt: data?.alt || data?.alternativeText || '',
            })
          : undefined,
      }
    : data
  return withImages
}

export const insertImages = (data, propName = 'image', insertAs = 'image') => {
  const withImages = data[propName]
    ? {
        [insertAs]: transformImage(data[propName]),
      }
    : undefined
  return withImages
}

export const transformLink = (data) => {
  const enhancedData = data
    ? {
        linkText: data.linkText || data.text,
        Link: buildLink(getLinkProps(data)),
      }
    : undefined
  return enhancedData
}

export const insertLinks = (data) => {
  const enhancedData = data.link
    ? {
        ...transformLink(data.link),
      }
    : undefined
  return enhancedData
}

const richtextPropNames = ['richtext', 'copy']
const allUploadLinksRegex = /\[([^\]]+)\]\((\/uploads\/[^\)]+)\)/gm

export const insertRichtext = (data, richtextReferences) => {
  const key = richtextPropNames.find((propName) =>
    data.hasOwnProperty(propName),
  )
  const valueRaw = data[key]

  if (!valueRaw || !key || !valueRaw.data) return { [key]: undefined }

  // Get richtext value wether it is a string or a richtext.data.richtext object
  let value = isString(valueRaw) ? valueRaw : valueRaw.data[key]

  if (richtextReferences) {
    // Transforms link to a local reference if it exsits
    //  /uploads/whatever.xyz -> /static/[hash]/whatever.xyz
    value = value.replace(allUploadLinksRegex, (_, p1, p2) => {
      const downloadRef = richtextReferences[p2]
      return `[${p1}](${downloadRef})`
    })
  }

  const enhancedData = {
    [key]: value,
  }
  return enhancedData
}

export const enhanceStrapiData = (data, global) => {
  const richtextReferences = global
    ? transformDownloadLinks(global.downloads)
    : {}

  const enhancedData = {
    ...data,
    uid: `${data.strapi_component}-${data.id}`,
    ...(data.tag && { as: data.tag }),
    ...insertRichtext(data, richtextReferences),
    ...insertImages(data),
    ...insertImages(data, 'cover', 'cover'),
    ...insertImages(data, 'logo', 'logo'),
    ...insertImages(data, 'backgroundImage', 'backgroundImage'),
    ...insertImages(data, 'iconImage', 'iconImage'),
    ...insertLinks(data),
    ...(data.items && data.items.length
      ? {
          items: data.items.map((item) => ({
            ...item,
            ...insertRichtext(item, richtextReferences),
            ...insertImages(item),
            ...insertImages(item, 'cover', 'cover'),
            ...insertImages(item, 'backgroundImage', 'backgroundImage'),
            ...insertImages(item, 'iconImage', 'iconImage'),
            ...insertLinks(item),
          })),
        }
      : undefined),
    ...(data.links && data.links.length
      ? {
          links: data.links.map((item) => ({
            ...item,
            ...transformLink(item),
          })),
        }
      : undefined),
    ...(data.mediaBlock
      ? {
          mediaBlock: {
            ...data.mediaBlock,
            ...insertImages(data.mediaBlock),
          },
        }
      : undefined),
    ...(data.video
      ? {
          video: {
            ...data.video,
            ...insertImages(data.video),
          },
        }
      : undefined),
    ...(data.textBlock
      ? {
          textBlock: {
            ...data.textBlock,
            ...insertRichtext(data.textBlock, richtextReferences),
          },
        }
      : undefined),
    ...(data.mediaBlocks && data.mediaBlocks.length
      ? {
          mediaBlocks: data.mediaBlocks.map((item) => ({
            ...item,
            ...insertImages(item),
          })),
        }
      : undefined),
    ...(data.images && data.images.length
      ? {
          images: data.images.map((item) => ({
            ...transformImage(item),
          })),
        }
      : undefined),
    ...(data.partners && data.partners.length
      ? {
          partners: data.partners.map((item) => ({
            ...item,
            ...(!item.activateInternalPage &&
              item.url && {
                Link: buildLink({ href: item.url }),
              }),
            ...(item.activateInternalPage &&
              item.route && {
                Link: buildLink({ href: item.route }),
              }),
            ...(item.logo && { logo: transformImage(item.logo) }),
          })),
        }
      : undefined),
  }

  return enhancedData
}
