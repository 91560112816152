// NOTE: Watch out to safelist all classes in tailwind.config.js
// Otherwise they might not be available in prodcution.

export const Space = {
  none: { py: 'py-0', pt: 'pt-0', pb: 'pb-0' },
  small: { py: 'py-5', pt: 'pt-5', pb: 'pb-5' },
  medium: { py: 'py-10', pt: 'pt-10', pb: 'pb-10' },
  large: { py: 'py-15', pt: 'pt-15', pb: 'pb-15' },
  xlarge: { py: 'py-20', pt: 'pt-20', pb: 'pb-20' },
  xxlarge: { py: 'py-24', pt: 'pt-24', pb: 'pb-24' },
}

export const defaultSpaceName = 'medium'

const getSpace = ({ space, type }) => {
  const spaceConfig =
    Space[space || defaultSpaceName] || Space[defaultSpaceName]
  const spaceClass = spaceConfig[type || 'py']
  return spaceClass
}

export const py = (space) => {
  return getSpace({ space, type: 'py' })
}

export const pt = (space) => {
  return getSpace({ space, type: 'pt' })
}

export const pb = (space) => {
  return getSpace({ space, type: 'pb' })
}
