import React from 'react'
import PropTypes from 'prop-types'

import { classNames } from '../../helper'

const Sizes = {
  8: 'w-8 h-8',
}

const StrokeWidth = {
  2: 'before:border-2',
}

const Spinner = ({ text, size, strokeWidth }) => (
  <div
    className={classNames(
      'relative animate-spin inline-block rounded-full before:border-gray-100 before:border-r-current before:absolute before:inset-0 before:rounded-full',
      Sizes[size],
      StrokeWidth[strokeWidth],
    )}
    role="status"
  >
    <span className="sr-only">{text}</span>
  </div>
)

Spinner.defaultProps = {
  size: 8,
  strokeWidth: 2,
}

Spinner.propTypes = {
  text: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
}

export default Spinner
