import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { enhanceStrapiData } from '@/utils/enhanceStrapiData'
import { sortByRankingAndTitle } from '@/utils/sorting'
import { cn } from '@/helper'

import AccountRegistration from '@/components/sections/AccountRegistration'
import AnchorNavigation from '@/components/sections/AnchorNavigation'
import ArticleCarousel from '@/components/sections/ArticleCarousel'
import ArticleCollection from '@/components/sections/ArticleCollection'
import ArticleColumns from '@/components/sections/ArticleColumns'
import ArticleGrid from '@/components/sections/ArticleGrid'
import ArticleList from '@/components/sections/ArticleList'
import BadgeDivider from '@/components/sections/BadgeDivider'
import Columns from '@/components/sections/Columns'
import ColumnsGroup from '@/components/sections/ColumnsGroup'
import Contact from '@/components/sections/Contact'
import ContactOptions from '@/components/sections/ContactOptions'
import ContentSection from '@/components/sections/ContentSection'
import Downloads from '@/components/sections/Downloads'
import EventDetail from '@/components/sections/EventDetail'
import FeatureDescription from '@/components/sections/FeatureDescription'
import FeatureList from '@/components/sections/FeatureList'
import HeadingSection from '@/components/sections/HeadingSection'
import HeroCollection from '@/components/sections/HeroCollection'
import HeroCompact from '@/components/sections/HeroCompact'
import HeroInsight from '@/components/sections/HeroInsight'
import HeroRelease from '@/components/sections/HeroRelease'
import HeroSection from '@/components/sections/HeroSection'
import HighlightFigure from '@/components/sections/HighlightFigure'
import HighlightLogo from '@/components/sections/HighlighLogo'
import HighlightPlatform from '@/components/sections/HighlightPlatform'
import HighlightStats from '@/components/sections/HighlightStats'
import HighlightText from '@/components/sections/HighlightText'
import ImageHeader from '@/components/sections/ImageHeader'
import LogoCloud from '@/components/sections/LogoCloud'
import Newsletter from '@/components/sections/Newsletter'
import PartnerCollection from '@/components/sections/PartnerCollection'
import TeaserCollection from '@/components/sections/TeaserCollection'
import TeaserLogo from '@/components/sections/TeaserLogo'
import TeaserSplitImage from '@/components/sections/TeaserSplitImage'
import TestimonialCollection from '@/components/sections/TestimonialCollection'
import TestimonialSection from '@/components/sections/TestimonialSection'
import TopicCollection from '@/components/sections/TopicCollection'
import TrainingCollection from '@/components/sections/TrainingCollection'
import CustomPostSelection from '@/components/sections/CustomPostSelection'
import BreakingNews from '@/components/sections/BreakingNews'

import BadgeDividerBlock from '@/components/blocks/BadgeDividerBlock'
import HeadingColumn from '@/components/blocks/HeadingColumn'
import HighlightColumn from '@/components/blocks/HighlightColumn'
import ImageColumn from '@/components/blocks/ImageColumn'
import TextColumn from '@/components/blocks/TextColumn'

import LatestArticles from '@/components/backed/LatestArticles'
import LatestPosts from '@/components/backed/LatestPosts'
import LatestInsights from '@/components/backed/LatestInsights'
import LatestReleaseNotes from '@/components/backed/LatestReleaseNotes'
import LatestPostsCarousel from '@/components/backed/LatestPostsCarousel'
import NextEvents from '@/components/backed/NextEvents'

// ----------------------------------------------------------------------------
// Map available Section/Block Components
// ----------------------------------------------------------------------------

const sectionComponents = {
  // Sections
  'sections.account-registration': AccountRegistration,
  'sections.anchor-navigation': AnchorNavigation,
  'sections.article-carousel': ArticleCarousel,
  'sections.article-collection': ArticleCollection,
  'sections.article-columns': ArticleColumns,
  'sections.article-grid': ArticleGrid,
  'sections.article-list': ArticleList,
  'sections.badge-divider': BadgeDivider,
  'sections.columns-heading-image': ColumnsGroup,
  'sections.columns-heading-text': ColumnsGroup,
  'sections.columns-heading-video': ColumnsGroup,
  'sections.columns-highlight-image': ColumnsGroup,
  'sections.columns-highlight-text': ColumnsGroup,
  'sections.columns-highlight-video': ColumnsGroup,
  'sections.columns-text-image': Columns,
  'sections.columns': Columns,
  'sections.contact': Contact,
  'sections.contact-options': ContactOptions,
  'sections.downloads': Downloads,
  'sections.event-detail': EventDetail,
  'sections.feature-list': FeatureList,
  'sections.feature-description': FeatureDescription,
  'sections.heading-image': ImageHeader,
  'sections.heading-section': HeadingSection,
  'sections.hero-collection': HeroCollection,
  'sections.hero-compact': HeroCompact,
  'sections.hero-insight': HeroInsight,
  'sections.hero-release': HeroRelease,
  'sections.hero-section': HeroSection,
  'sections.highlight-logo': HighlightLogo,
  'sections.highlight-figure': HighlightFigure,
  'sections.highlight-platform': HighlightPlatform,
  'sections.highlight-stats': HighlightStats,
  'sections.highlight-text': HighlightText,
  'sections.newsletter': Newsletter,
  'sections.partner-cloud': LogoCloud,
  'sections.partner-collection': PartnerCollection,
  'sections.teaser-collection': TeaserCollection,
  'sections.teaser-image': TeaserSplitImage,
  'sections.teaser-logo': TeaserLogo,
  'sections.testimonial-collection': TestimonialCollection,
  'sections.testimonial-section': TestimonialSection,
  'sections.text-section': ContentSection,
  'sections.topic-collection': TopicCollection,
  'sections.training-collection': TrainingCollection,
  'sections.custom-post-selection': CustomPostSelection,
  'sections.breaking-news': BreakingNews,

  // Blocks
  'blocks.badge-divider-block': BadgeDividerBlock,
  'blocks.heading-block': HeadingColumn,
  'blocks.highlight-block': HighlightColumn,
  'blocks.image-block': ImageColumn,
  'blocks.text-block': TextColumn,

  // Baked sections
  'sections.latest-articles': LatestArticles,
  'sections.latest-posts': LatestPosts,
  'sections.latest-insights': LatestInsights,
  'sections.latest-release-notes': LatestReleaseNotes,
  'sections.latest-posts-carousel': LatestPostsCarousel,
  'sections.next-events': NextEvents,
}

const ComponentDefaults = {
  'sections.anchor-navigation': {
    disableTopMargin: true,
  },
  'sections.hero-collection': {
    disableTopMargin: true,
  },
  'sections.hero-compact': {
    disableTopMargin: true,
  },
  'sections.hero-insight': {
    disableTopMargin: true,
  },
  'sections.hero-release': {
    disableTopMargin: true,
  },
  'sections.hero-section': {
    disableTopMargin: true,
  },
}

// ----------------------------------------------------------------------------
// Single Section Component
// ----------------------------------------------------------------------------

const Section = ({ className, sectionData }) => {
  const SectionComponent = sectionComponents[sectionData.strapi_component]

  if (!SectionComponent) {
    // No matching component for this page section
    return undefined
  }

  // strip non-related section component props (like strapi internal props)
  const { strapi_component: sectionComponentName, ...props } = sectionData

  const componentDefaults = ComponentDefaults[sectionComponentName] || {}
  const disableTopMargin =
    sectionData.disableTopMargin === null
      ? componentDefaults.disableTopMargin
      : sectionData.disableTopMargin

  const customSpacing = !disableTopMargin && '!mt-15'

  return (
    <SectionComponent
      className={cn(className, customSpacing)}
      data-component={sectionComponentName}
      {...props}
    />
  )
}

Section.defaultProps = {}

Section.propTypes = {
  className: PropTypes.string,
  sectionData: PropTypes.object,
}

// ----------------------------------------------------------------------------
// List of Section Components
// ----------------------------------------------------------------------------

const Sections = ({ as: Tag, sections, global }) => {
  const downloads = sortByRankingAndTitle(global.downloads).map((download) =>
    enhanceStrapiData(download),
  )
  const downloadCategories = sortByRankingAndTitle(global.downloadCategories)

  const preparedSections = sections
    ? sections.map((data) => {
        const parsedData = {
          ...data,
          ...(data.columnsItem ? { items: data.columnsItem.items } : undefined),
          ...(data.hero ? { items: data.hero.items } : undefined),
          ...(data.teaser ? { items: data.teaser.items } : undefined),
          ...(data.topic ? { items: data.topic.items } : undefined),
        }
        return parsedData
      })
    : []

  const enhancedSections = preparedSections.map((data) =>
    enhanceStrapiData(data, global),
  )

  return (
    <Tag className="flex flex-col">
      {enhancedSections.map((section, i) => {
        const key =
          `${section.id}` || `${section.strapi_component}-${section.strapi_id}`

        switch (section.strapi_component) {
          case 'sections.latest-posts':
            section = {
              ...section,
              posts: global.posts,
            }
            break
          case 'sections.latest-insights':
            section = {
              ...section,
              insights: global.insights,
            }
            break
          case 'sections.latest-release-notes':
            section = {
              ...section,
              releaseNotes: global.releaseNotes,
            }
            break
          case 'sections.latest-articles':
            section = {
              ...section,
              posts: global.posts.slice(0, 9),
              insights: global.insights.slice(0, 9),
              releaseNotes: global.releaseNotes.slice(0, 9),
            }
            break
          case 'sections.latest-posts-carousel':
            section = {
              ...section,
              posts: global.posts.slice(0, 6),
            }
            break
          case 'sections.downloads':
            const requestedDownloadCategoryIds = section.downloadCategories.map(
              ({ categoryId, strapi_id }) => categoryId || strapi_id,
            )

            section = {
              ...section,
              downloads: downloads.filter(
                ({ downloadCategory }) =>
                  downloadCategory &&
                  requestedDownloadCategoryIds.includes(
                    downloadCategory.categoryId,
                  ),
              ),
              downloadCategories: downloadCategories.filter(({ categoryId }) =>
                requestedDownloadCategoryIds.includes(categoryId),
              ),
            }
            break
          case 'sections.training-collection':
            section = {
              ...section,
              items: global.trainings.map((item) => enhanceStrapiData(item)),
            }
            break
          case 'sections.next-events':
            section = {
              ...section,
              events: global.events.map((item) => enhanceStrapiData(item)),
            }
            break
        }

        const elem = sectionComponents[section.strapi_component] ? (
          <Section sectionData={section} key={key} />
        ) : undefined

        return elem
      })}
    </Tag>
  )
}

Sections.defaultProps = {
  as: 'div',
  sections: [],
  global: {},
}

Sections.propTypes = {
  as: PropTypes.string,
  sections: PropTypes.array,
  global: PropTypes.object,
}

export default Sections
