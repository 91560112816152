import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { uid } from 'react-uid'
import { useTranslation } from 'react-i18next'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import ArticleCard from '../base/ArticleCard'
import { ArticleTypes } from '../../constants/enums'
import { buildLink } from '@amazeelabs/react-framework-bridge/gatsby'

const CustomPostSelection = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  space,
  items,
  ...props
}) => {
  const { t } = useTranslation()

  const badgeTexts = {
    [ArticleTypes.insight]: t('Global.insight'),
    [ArticleTypes.news]: t('Global.news'),
    [ArticleTypes.successStory]: t('Global.successStory'),
    [ArticleTypes.releaseNote]: t('Global.releaseNote'),
  }

  const finalItems = items.slice(0, 3).map((item) => {
    const variant = ArticleTypes[item.category || 'news'] || ArticleTypes.news
    return {
      ...item,
      headline: item.title,
      image: item.cover,
      Link: buildLink({ href: item.route }),
      variant,
      badgeText: badgeTexts[variant],
    }
  })

  return (
    <Tag className={cn(className)} {...addValidHtmlProps(props)}>
      <div className={cn('box bg-white', py(space))}>
        {headline && (
          <HeadlineTag
            className="sm:col-span-5 xl:col-span-4 mb-10 break-words"
            order={headlineOrder}
          >
            <span className="block text-32 leading-1.2 font-bold uppercase md:text-44">
              {headline}
            </span>
          </HeadlineTag>
        )}
        {finalItems?.length > 0 && (
          <ul className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
            {finalItems.map((item) => (
              <li key={item.id || uid(item)} className="flex flex-col">
                <ArticleCard
                  headlineOrder={headlineOrder + 1}
                  as="article"
                  {...item}
                  className="flex-1"
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </Tag>
  )
}

CustomPostSelection.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  items: [],
}

CustomPostSelection.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  space: PropTypes.string,
  items: PropTypes.array,
}

export default CustomPostSelection

export const query = graphql`
  fragment SectionsCustomPostSelection on STRAPI__COMPONENT_SECTIONS_CUSTOM_POST_SELECTION {
    disableTopMargin
    space
    id
    anchor
    strapi_component
    headline
    headlineOrder
    items {
      id
      route
      slug
      title
      teaserHeadline
      category
      cover {
        mime
        url
        alt: alternativeText
        caption
        width
        height
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
