import React from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import ArticleCard from '../base/ArticleCard'
import Button from '../base/Button'

const ArticleCollection = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  items,
  Link,
  linkText,
  id,
  space,
  ...props
}) => (
  <Tag className={cn(className)} id={id} {...addValidHtmlProps(props)}>
    <div className={cn('box bg-white', py(space))}>
      {headline && (
        <HeadlineTag
          className="sm:col-span-5 xl:col-span-4 mb-10 break-words"
          order={headlineOrder}
        >
          <span className="block text-32 leading-1.2 font-bold uppercase md:text-44">
            {headline}
          </span>
        </HeadlineTag>
      )}
      {items && items.length && (
        <ul className="grid gap-5 auto-rows-auto grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
          {items.map((item, index) => (
            <li
              key={uid(item)}
              className={cn(
                index === 0 && 'xl:col-span-2 xl:row-span-2',
                'flex flex-col',
              )}
            >
              <ArticleCard
                headlineOrder={headlineOrder + 1}
                as="article"
                {...item}
                highlighted={index === 0}
                className="flex-1"
              />
            </li>
          ))}
        </ul>
      )}
      {Link && linkText && (
        <div className="pt-10">
          <Button as={Link}>{linkText}</Button>
        </div>
      )}
    </div>
  </Tag>
)

ArticleCollection.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  items: [],
}

ArticleCollection.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  items: PropTypes.array,
  linkText: PropTypes.string,
  Link: PropTypes.any,
  id: PropTypes.string,
}

export default ArticleCollection
