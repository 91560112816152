import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import RichText from '../base/RichText'
import AspectRatio from '../base/AspectRatio'

const HighlighLogo = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  overline,
  image,
  space,
  ...props
}) => {
  const hasHeading = !_.isEmpty(overline) || !_.isEmpty(headline)
  const enableHighlighting = true

  return (
    <Tag className={cn(className)} {...addValidHtmlProps(props)}>
      <div className={cn('box', py(space))}>
        {hasHeading && (
          <HeadlineTag order={headlineOrder}>
            {overline && (
              <span className="block text-16 leading-1.3 font-bold uppercase mb-1">
                {overline}
              </span>
            )}
            {headline && (
              <div className="sm:flex sm:justify-between">
                <RichText
                  as="span"
                  className="flex-1 block text-32 leading-1.2 font-bold uppercase max-w-4xl"
                  enableHighlighting={enableHighlighting}
                >
                  {headline}
                </RichText>
                <AspectRatio
                  size={image}
                  className="relative max-h-28 smmax:mt-8 sm:ml-20"
                  placeholderClassName="max-h-28"
                >
                  {image.Component && (
                    <image.Component className="!absolute inset-0 w-full h-full object-contain sm:object-right" />
                  )}
                </AspectRatio>
              </div>
            )}
          </HeadlineTag>
        )}
      </div>
    </Tag>
  )
}

HighlighLogo.defaultProps = {
  as: 'div',
  headlineOrder: 2,
}

HighlighLogo.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  overline: PropTypes.string,
  headline: PropTypes.string,
  image: PropTypes.object,
}

export default HighlighLogo

export const query = graphql`
  fragment SectionsHighlightLogo on STRAPI__COMPONENT_SECTIONS_HIGHLIGHT_LOGO {
    disableTopMargin
    space
    id
    strapi_component
    anchor
    headline
    headlineOrder
    overline
    image {
      mime
      url
      alt: alternativeText
      width
      height
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
