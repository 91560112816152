import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { cn } from '../../helper'
import HeadlineTag from '../base/HeadlineTag'

const HeadingColumn = ({
  className,
  headlineOrder,
  badgeText,
  overline,
  headline,
  subline,
}) => (
  <div className={className}>
    {(overline || badgeText || headline) && (
      <HeadlineTag order={headlineOrder || 2}>
        {overline && (
          <p className="block">
            <span className="text-16 font-bold uppercase leading-1.3">
              {overline}
            </span>
          </p>
        )}
        {badgeText && (
          <span className={cn('block', overline && 'mt-6')}>
            <span className="px-2 py-1 bg-greyish-brown text-white text-14 font-bold uppercase">
              {badgeText}
            </span>
          </span>
        )}
        {headline && (
          <span
            className={cn(
              (badgeText || overline) && 'mt-5',
              'block max-w-3xl font-bold uppercase break-words text-28 leading-[2.625rem] sm:text-32 sm:leading-[3rem] sm:w-9/12 md:text-48 md:leading-[4.375rem]',
            )}
            order={headlineOrder}
          >
            <span className="block">
              <span className="px-2 bg-rio-500 text-white decoration-clone whitespace-pre-line">
                {headline}
              </span>
            </span>
          </span>
        )}
      </HeadlineTag>
    )}
    {subline && (
      <p className="mt-1 max-w-3xl">
        <span className="px-2 py-1 bg-greyish-brown text-white text-22 font-bold uppercase md:text-28 decoration-clone ">
          {subline}
        </span>
      </p>
    )}
  </div>
)

HeadingColumn.defaultProps = {
  headlineOrder: 2,
}

HeadingColumn.propTypes = {
  headlineOrder: PropTypes.number,
  className: PropTypes.string,
  overline: PropTypes.string,
  badgeText: PropTypes.string,
  headline: PropTypes.string,
  subline: PropTypes.string,
}

export default HeadingColumn

export const query = graphql`
  fragment BlocksHeadingBlock on STRAPI__COMPONENT_BLOCKS_HEADING_BLOCK {
    strapi_component
    id
    badgeText
    headline
    subline
    headlineOrder
  }
`
