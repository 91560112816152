import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from '../../helper'
import HeadlineTag from './HeadlineTag'
import RichText from './RichText'
import Button from './Button'

const TopicCard = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  title,
  copy,
  linkText,
  Link,
  image,
}) => (
  <Tag
    className={classNames(
      'flex flex-col bg-white border border-gray-100',
      className,
    )}
  >
    {title && (
      <HeadlineTag
        className="px-3 py-2 bg-rio-grande-green text-white break-words md:px-6"
        order={headlineOrder}
      >
        <span className="block text-20 leading-1.3 font-bold uppercase md:text-24">
          {title}
        </span>
      </HeadlineTag>
    )}
    <div className="relative aspect-16/9">
      {image && image.Component && (
        <image.Component className="!absolute inset-0 w-full h-full" />
      )}
    </div>
    <div className="flex-1 flex flex-col px-3 py-5 max-w-reading md:px-6">
      {headline && (
        <HeadlineTag
          className="block text-20 leading-1.3 font-bold uppercase break-words md:text-24"
          order={headlineOrder + 1}
        >
          {headline}
        </HeadlineTag>
      )}
      {copy && (
        <RichText className={`${headline && 'mt-2'} prose`}>{copy}</RichText>
      )}
      {Link && linkText && (
        <div className={`${headline || copy ? 'pt-5' : ''} mt-auto`}>
          <Button as={Link} variant="primary" showLinkIcon>{linkText}</Button>
        </div>
      )}
    </div>
  </Tag>
)

TopicCard.defaultProps = {
  as: 'article',
  headlineOrder: 2,
}

TopicCard.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  title: PropTypes.string,
  headline: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.any,
  linkText: PropTypes.string,
  Link: PropTypes.any,
}

export default TopicCard
