import React from 'react'
import PropTypes from 'prop-types'

const HeadlineTag = ({ order, children, ...props }) => {
  const Tag = `h${Math.max(1, Math.min(order, 6))}`

  return <Tag {...props}>{children}</Tag>
}

HeadlineTag.defaultProps = {
  order: 2,
}

HeadlineTag.propTypes = {
  order: PropTypes.number,
  children: PropTypes.node,
}

export default HeadlineTag
