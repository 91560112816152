const validAttrs = []
const validAttrsAliases = { anchor: 'id' }

export const addValidHtmlProps = (props) => {
  const finalProps = {}

  Object.keys(props).forEach((key) => {
    if (key.startsWith('data-') || validAttrs.includes(key)) {
      finalProps[key] = props[key]
    }

    Object.keys(validAttrsAliases).forEach((alias) => {
      if (key === alias) {
        finalProps[validAttrsAliases[alias]] = props[key]
      }
    })
  })

  return finalProps
}
