import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'
import Flickity from 'react-flickity-component'
import { uid } from 'react-uid'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { sortByRankingAndTitle } from '../../utils/sorting'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadlineTag from '../base/HeadlineTag'
import RichText from '../base/RichText'
import Image from '../base/Image'

const LogoCloud = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  overline,
  items,
  space,
  id,
  ...props
}) => {
  const hasHeading = !_.isEmpty(overline) || !_.isEmpty(headline)
  const enableHighlighting = true

  const sortedItems = sortByRankingAndTitle(items)

  const flickityOptions = {
    autoPlay: 2000,
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
    draggable: false,
    freeScroll: false,
    friction: 0.4,
    selectedAttraction: 0.035,
    cellAlign: 'left',
  }

  return (
    <Tag
      className={cn('overflow-hidden', className)}
      {...addValidHtmlProps(props)}
    >
      <div className={cn('box bg-white', py(space))}>
        <div className="grid gap-x-15 gap-y-4 items-center sm:grid-cols-12 overflow-hidden">
          {hasHeading && (
            <HeadlineTag
              className="relative z-10 py-8 bg-white pr-12 sm:border-r sm:border-r-gray-500 sm:col-span-5 xl:col-span-4"
              order={headlineOrder}
            >
              {overline && (
                <span className="block text-16 leading-1.3 font-bold uppercase">
                  {overline}
                </span>
              )}
              {headline && (
                <RichText
                  as="span"
                  className="block text-32 leading-1.2 font-bold uppercase"
                  enableHighlighting={enableHighlighting}
                >
                  {headline}
                </RichText>
              )}
            </HeadlineTag>
          )}
          {sortedItems.length > 0 && (
            <div className="sm:col-start-7 sm:col-span-6 xl:col-start-6 xl:col-span-8">
              <Flickity
                key={id}
                className="flickity-overflow-visible"
                elementType="div"
                options={flickityOptions}
                disableImagesLoaded={false}
                reloadOnUpdate={false}
                static={false}
              >
                {sortedItems.map((item) => (
                  <div
                    key={item.id || uid(item)}
                    className="flex mr-15 md:mr-30"
                  >
                    {item.logo && (
                      <div className="relative h-28">
                        {item.logo.width && item.logo.height && (
                          <svg
                            className="block h-full w-auto max-w-[11rem]"
                            viewBox={`0 0 ${item.logo.width} ${item.logo.height}`}
                          />
                        )}
                        <Image
                          className={cn(
                            item.logo.width
                              ? '!absolute inset-0 w-full h-full object-contain'
                              : 'h-full w-auto max-w-[11rem] object-contain',
                          )}
                          media={item.logo}
                          objectFit="contain"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </Flickity>
            </div>
          )}
        </div>
      </div>
    </Tag>
  )
}

LogoCloud.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  items: [],
}

LogoCloud.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  overline: PropTypes.string,
  headline: PropTypes.string,
  items: PropTypes.array,
}

export default LogoCloud

export const query = graphql`
  fragment SectionsPartnerCloud on STRAPI__COMPONENT_SECTIONS_PARTNER_CLOUD {
    disableTopMargin
    space
    id
    anchor
    headline
    headlineOrder
    overline
    strapi_component
    tag
    items: partners {
      route
      title
      ranking
      logo {
        ...SharedImage
      }
    }
  }
`
