import React, { Fragment, useRef, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import CheckIcon from '@mui/icons-material/Check'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import _ from 'lodash'

import { cn } from '../../helper'

const Select = ({
  className,
  name,
  options,
  label,
  value,
  onChange,
  required,
  labelHidden,
}) => {
  const listboxButton = useRef(null)
  const [validityChecked, setValidityChecked] = useState(false)
  const [invalid, setInvalid] = useState(false)

  const optionsByValue = options.reduce(
    (obj, option) => ({ ...obj, [option.value]: option.text }),
    {},
  )

  const finalLabel = `${label}${required ? '*' : ''}`

  const nativeSelect = useRef(null)

  useEffect(() => {
    if (nativeSelect?.current) {
      nativeSelect.current.value = value
    }
  })

  return (
    <>
      <Listbox
        value={value}
        onChange={(event) => {
          setInvalid(false)
          onChange(event)
        }}
        className={className}
      >
        {({ open }) => (
          <div className="relative">
            <select
              ref={nativeSelect}
              name={name}
              className="absolute inset-0 !border-none !outline-none focus:!outline-none shadow-off"
              onKeyDownCapture={(event) => {
                event.preventDefault()
                listboxButton?.current?.click()
              }}
              onInvalid={(event) => {
                setInvalid(!Boolean(event.target.value))
                setValidityChecked(true)
              }}
              required={required}
            >
              <option value=""></option>
              {options.map((option) => (
                <option key={option.value} value={option.value} />
              ))}
            </select>
            {label && (
              <Listbox.Label
                className={cn(
                  'block text-12 font-bold uppercase transition transform',
                  value === '' || value === null
                    ? 'opacity-0 translate-y-full'
                    : 'opacity-100 translate-y-0',
                  labelHidden && 'invisible',
                )}
              >
                {finalLabel}
              </Listbox.Label>
            )}

            <div className="mt-1 relative">
              <Listbox.Button
                className={cn(
                  'bg-white relative w-full text-16 px-[0.5em] py-[0.4375em] text-left cursor-default border focus:border-black focus:outline-0 focus:ring-0',
                  validityChecked && invalid ? 'border-red' : 'border-gray-400',
                )}
                ref={listboxButton}
              >
                <span
                  className={cn(
                    'block truncate',
                    value === undefined && 'italic text-gray-400',
                  )}
                >
                  {optionsByValue[value] && value !== ''
                    ? optionsByValue[value]
                    : finalLabel}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ExpandMoreIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 -mt-px w-full bg-white border border-gray-400 shadow-md max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        cn(
                          active ? 'text-body' : 'text-gray-400',
                          'cursor-default select-none relative py-2 pl-3 pr-9',
                        )
                      }
                      value={option.value}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={cn(
                              'block truncate',
                              selected && 'text-body',
                            )}
                          >
                            {option.text}
                          </span>

                          {selected ? (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-body">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
    </>
  )
}

export default Select
