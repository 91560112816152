import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { uid } from 'react-uid'

import { cn } from '../../helper'
import { py } from '../../constants/space'
import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import Button from '../base/Button'
import HeadlineTag from '../base/HeadlineTag'
import Image from '../base/Image'
import HeadingColumn from '../blocks/HeadingColumn'
import HighlightColumn from '../blocks/HighlightColumn'
import TextColumn from '../blocks/TextColumn'
import ImageColumn from '../blocks/ImageColumn'

const gridClasses = [
	'', // 0
	'', // 1
	'md:grid-cols-2', // 2
	'md:grid-cols-2 xl:grid-cols-3', // 3
	'md:grid-cols-2 xl:grid-cols-4', // 4
	'md:grid-cols-2 xl:grid-cols-5', // 5
	'md:grid-cols-2 xl:grid-cols-6', // 6
	'md:grid-cols-4 xl:grid-cols-7', // 7
	'md:grid-cols-4 xl:grid-cols-8', // 8
	'md:grid-cols-5 xl:grid-cols-9', // 9
	'md:grid-cols-5 xl:grid-cols-10', // 10
	'md:grid-cols-6 xl:grid-cols-11', // 11
	'md:grid-cols-6 xl:grid-cols-12', // 12
]

const Columns = ({
	as: Tag,
	className,
	anchor,
	headingBlock,
	Link,
	linkText,
	items,
	space,
	...props
}) => {
	//const finalItems = items ? items.slice(0, 12) : []
	const finalItems = items ? items : [];
	const numItems = finalItems.length;
	let columnClasses = gridClasses[numItems];

	if (numItems > 3) {
		columnClasses = 'sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4';
	}

	const headlineOrder = headingBlock?.headlineOrder || 2;

	return (
		<Tag id={anchor} className={cn(className)} {...addValidHtmlProps(props)}>
			<div className={cn('box bg-white space-y-20', py(space))}>
				{headingBlock && (
					<HeadingColumn headlineOrder={headlineOrder} {...headingBlock} />
				)}
				{finalItems.length && (
					<div className={cn('grid gap-x-10 gap-y-15 column-group', columnClasses)}>
						{finalItems.map((item) => (
							<article
								key={item.id || uid(item)}
								className="flex flex-col space-y-5 column-item"
							>
								{item.image && <ImageColumn image={item.image} />}
								{item.iconImage && (
									<div
										className={cn(
											'relative aspect-1/1 w-18 h-18',
											item.image && '!mt-8',
										)}
									>
										<Image
											className="!absolute inset-0 w-full h-full object-fit"
											media={item.iconImage}
											objectFit="contain"
										/>
									</div>
								)}
								{item.overline && (
									<div className="block head-s mb-1">
										{item.overline}
									</div>
								)}
								{item.headline && (
									<HighlightColumn
										headline={item.headline}
										headlineOrder={headlineOrder + (headingBlock ? 1 : 0)}
										enableHighlighting={item.enableHighlighting}
										className={cn(item.overline && '!mt-2')}
									/>
								)}
								{item.richtext && <TextColumn richtext={item.richtext} />}
								{item.Link && item.linkText && (
									<div className="!mt-10">
										<Button as={item.Link} variant="primary" showLinkIcon>{item.linkText}</Button>
									</div>
								)}
							</article>
						))}
					</div>
				)}
				{Link && linkText && (
					<div className="text-center">
						<Button as={Link} variant="primary" showLinkIcon>{linkText}</Button>
					</div>
				)}
			</div>
		</Tag>
	)
}

Columns.defaultProps = {
	as: 'div',
}

Columns.propTypes = {
	as: PropTypes.string,
	className: PropTypes.string,
	tag: PropTypes.string,
	anchor: PropTypes.string,
	headingBlock: PropTypes.object,
	Link: PropTypes.any,
	linkText: PropTypes.string,
	items: PropTypes.array,
}

export default Columns

export const query = graphql`
  fragment SectionsColumns on STRAPI__COMPONENT_SECTIONS_COLUMNS {
    id
    strapi_component
    anchor
    tag
    space
    disableTopMargin
    headingBlock {
      ...BlocksHeadingBlock
    }
    link {
      ...SharedLink
    }
    columnsItem {
      items {
        richtext {
          data {
            richtext
          }
        }
        headline
        enableHighlighting
        link {
          ...SharedLink
        }
        image {
          ...SharedImage
        }
        iconImage {
          ...SharedImage
        }
        overline
      }
    }
  }
`
