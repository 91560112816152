import React, { useState } from 'react'
import _ from 'lodash'

import { cn } from '../../helper'

const TextField = ({
  className,
  htmlId,
  name,
  value,
  onChange,
  label,
  required,
  autoComplete,
  readOnly,
  type = 'text',
}) => {
  const placeholder = label ? label + (required ? '*' : '') : undefined
  const [validityChecked, setValidityChecked] = useState(false)

  return (
    <div className={cn(readOnly && 'opacity-50', className)}>
      {label && (
        <label
          htmlFor={htmlId}
          className={cn(
            'block text-12 font-bold uppercase transition transform',
            value === '' || value === null
              ? 'opacity-0 translate-y-full'
              : 'opacity-100 translate-y-0',
          )}
        >
          {label}
          {required && '*'}
        </label>
      )}
      <div className="relative mt-1">
        <input
          type={type}
          name={name}
          id={htmlId}
          className={cn(
            'block w-full text-16 px-[0.5em] py-[0.4375em] shadow-off bg-white placeholder-gray-400 placeholder-italic border border-gray-400 valid:focus:border-black focus:outline-0 focus:ring-0',
            validityChecked && 'invalid:border-red',
          )}
          placeholder={placeholder}
          autoComplete={autoComplete}
          value={value}
          required={required}
          readOnly={readOnly}
          onInvalid={() => setValidityChecked(true)}
          {...(onChange &&
            !readOnly && {
              onChange: (event) => onChange(event.target.value),
            })}
        />
      </div>
    </div>
  )
}

export default TextField
