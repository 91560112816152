import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from '../../helper'
import HeadlineTag from './HeadlineTag'
import RichText from './RichText'
import Button from './Button'

const ArticleCardLarge = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  Link,
  linkText,
  image,
  copy,
}) => (
  <Tag
    className={classNames(
      'relative flex flex-col lg:grid lg:grid-cols-2 lg:gap-x-5',
      className,
    )}
  >
    <div className="relative bg-gray-100">
      {image && (
        <>
          <div className="relative aspect-8/5" />
          <image.Component className="!absolute inset-0 w-full h-full" />
        </>
      )}
    </div>
    <div className="flex-1 flex flex-col p-10 bg-gray-100">
      {headline && (
        <HeadlineTag
          className="text-28 leading-1.2 font-bold uppercase break-words xl:w-full xl:max-w-md xl:mx-auto"
          order={headlineOrder + 1}
        >
          {Link ? (
            <Link className="relative z-30">{headline}</Link>
          ) : (
            <span>{headline}</span>
          )}
        </HeadlineTag>
      )}
      {copy && (
        <RichText
          className={classNames(
            'prose xl:w-full xl:max-w-md xl:mx-auto',
            headline && 'mt-6',
          )}
        >
          {copy}
        </RichText>
      )}
      {Link && linkText && (
        <div
          className={classNames(
            'xl:w-full xl:max-w-md xl:mx-auto',
            (copy || headline) && 'mt-auto pt-8',
          )}
        >
          <Button as={Link} variant="primary" showLinkIcon>{linkText}</Button>
        </div>
      )}
    </div>
    {Link && (
      <Link
        className="absolute top-0 left-0 w-full h-full z-20"
        tabindex="-1"
        aria-hidden
      >
        <span className="sr-only">{headline}</span>
      </Link>
    )}
  </Tag>
)

ArticleCardLarge.defaultProps = {
  as: 'article',
  headlineOrder: 2,
}

ArticleCardLarge.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  Link: PropTypes.any,
  image: PropTypes.object,
  headline: PropTypes.string,
  copy: PropTypes.string,
  linkText: PropTypes.string,
}

export default ArticleCardLarge
