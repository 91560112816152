import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { cn } from '../../helper'
import RichText from '../base/RichText'

const TextColumn = ({ className, richtext }) => (
  <RichText className={cn('prose break-words max-w-none', className)}>
    {richtext}
  </RichText>
)

TextColumn.defaultProps = {}

TextColumn.propTypes = {
  className: PropTypes.string,
  richtext: PropTypes.string,
}

export default TextColumn

export const query = graphql`
  fragment BlocksTextBlock on STRAPI__COMPONENT_BLOCKS_TEXT_BLOCK {
    strapi_component
    id
    richtext {
      data {
        richtext
      }
    }
  }
`
