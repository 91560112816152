import React from 'react'
import PropTypes from 'prop-types'

import HeadlineTag from './HeadlineTag'
import { cn } from '../../helper'

export default function StatsBox({
  className,
  headline,
  headlineOrder,
  items,
}) {
  return (
    <div className={cn('bg-gray-100 px-5 py-10 lg:p-10', className)}>
      {headline && (
        <HeadlineTag
          order={headlineOrder}
          className="mb-5 text-16 font-bold uppercase md:text-18 lg:mb-10"
        >
          {headline}
        </HeadlineTag>
      )}
      {items?.length > 0 && (
        <dl className="space-y-5 lg:space-y-10">
          {items.map((item) => (
            <div key={item.name} className="flex flex-col-reverse gap-y-0.5">
              <dt className="flex-auto text-16 leading-1.2 md:text-18">
                {item.name}
              </dt>
              <dd className="text-24 leading-1.2 font-bold md:text-32 lg:text-48">
                {item.stat}
              </dd>
            </div>
          ))}
        </dl>
      )}
    </div>
  )
}

StatsBox.defaultProps = {
  as: 'div',
  headlineOrder: 3,
  items: [],
}

StatsBox.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  items: PropTypes.array,
}
