import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { buildLink } from '@amazeelabs/react-framework-bridge/gatsby'
import { useTranslation } from 'react-i18next'

import { cn } from '@/helper'
import { insertImages } from '@/utils/enhanceStrapiData'
import ArticleList from '@/components/sections/ArticleList'

const LatestPosts = ({ className, posts, ...props }) => {
  const { t } = useTranslation()

  const items = posts.map((item) => ({
    ...item,
    headline: item.teaserHeadline || item.title,
    copy: item.excerpt,
    ...insertImages(item, 'cover'),
    linkText: t('Global.more'),
    Link: buildLink({ href: item.route }),
  }))

  return (
    <ArticleList
      items={items}
      className={cn(className)}
      hasPagination={true}
      pageSize={5}
      {...props}
    />
  )
}

LatestPosts.defaultProps = {
  as: 'div',
  headlineOrder: 2,
  posts: [],
}

LatestPosts.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  headline: PropTypes.string,
  loadMoreButtonText: PropTypes.string,
  loadingText: PropTypes.string,
  posts: PropTypes.array,
}

export default LatestPosts

export const query = graphql`
  fragment SectionsLatestPosts on STRAPI__COMPONENT_SECTIONS_LATEST_POSTS {
    disableTopMargin
    space
    id
    anchor
    headline
    headlineOrder
    loadMoreButtonText
    loadingText
    strapi_component
  }
`
