import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

import { addValidHtmlProps } from '../../utils/addValidHtmlProps'
import { cn } from '../../helper'
import { py } from '../../constants/space'
import HeadingColumn from '../blocks/HeadingColumn'
import RichText from '../base/RichText'
import FormNewsletter from '../base/FormNewsletter'

const Newsletter = ({
  as: Tag,
  className,
  headingBlock,
  copy,
  space,
  Link,
  linkText,
  terms,
  formAction,
  fieldNameGivenName,
  fieldNameFamilyName,
  fieldNameEmail,
  submitLabel,
  information,
  ...props
}) => {
  const { t } = useTranslation()
  const headlineOrder = headingBlock?.headlineOrder || 2

  return (
    <Tag className={cn(className)} {...addValidHtmlProps(props)}>
      <div className={cn('box bg-white space-y-10', py(space))}>
        {headingBlock && (
          <HeadingColumn headlineOrder={headlineOrder} {...headingBlock} />
        )}
        {copy && (
          <RichText className="prose break-words max-w-none">{copy}</RichText>
        )}
        <div>
          <FormNewsletter
            name="registration"
            className="mt-6 max-w-720"
            terms={terms?.data?.terms}
            formAction={formAction}
            fieldNameGivenName={fieldNameGivenName}
            fieldNameFamilyName={fieldNameFamilyName}
            fieldNameEmail={fieldNameEmail}
            submitLabel={submitLabel}
            information={information?.data?.information}
          />
          {Link && linkText && (
            <div className="mt-10">
              <Link className="underline">{linkText}</Link>
            </div>
          )}
        </div>
      </div>
    </Tag>
  )
}

Newsletter.defaultProps = {
  as: 'div',
}

Newsletter.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  Link: PropTypes.any,
  linkText: PropTypes.string,
}

export default Newsletter

export const query = graphql`
  fragment SectionsNewsletter on STRAPI__COMPONENT_SECTIONS_NEWSLETTER {
    id
    strapi_component
    space
    disableTopMargin
    anchor
    tag
    headingBlock {
      ...BlocksHeadingBlock
    }
    copy {
      data {
        copy
      }
    }
    formAction
    fieldNameGivenName
    fieldNameFamilyName
    fieldNameEmail
    submitLabel
    information {
      data {
        information
      }
    }
    terms {
      data {
        terms
      }
    }
    link {
      ...SharedLink
    }
  }
`
