import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from '../../helper'
import HeadlineTag from './HeadlineTag'
import PlatformShelf from './PlatformShelf'

import { ArticleTypes } from '../../constants/enums'

const ArticleCardSmall = ({
  as: Tag,
  className,
  headlineOrder,
  headline,
  subline,
  date,
  datetime,
  Link,
  platforms,
  variant = ArticleTypes.releaseNote,
}) => {
  const Background =
    variant === ArticleTypes.insight ? (
      <div className="absolute inset-0 w-full h-full bg-cover bg-green-pattern" />
    ) : (
      <div className="absolute inset-0 w-full h-full bg-cover bg-gray-pattern" />
    )

  const formattedDate = datetime || date
  const showMetaInfo = Boolean(formattedDate || (platforms && platforms.length))

  return (
    <Tag
      className={classNames(
        'relative grid bg-off-white',
        variant === ArticleTypes.insight && 'text-white',
        className,
      )}
    >
      <div className="grid-stacked flex flex-col min-w-0">
        {Background}
        <div
          className={classNames(
            'flex-1 flex flex-col relative z-10 px-3 min-w-0 md:px-5',
            showMetaInfo ? 'pb-4' : 'pb-3 md:pb-5',
          )}
        >
          {headline && (
            <HeadlineTag
              className="pt-5 text-14 leading-1.2 font-bold uppercase break-words"
              order={headlineOrder + 1}
            >
              <span className="block">{headline}</span>
              {subline && (
                <span className="block font-normal normal-case break-words">
                  {subline}
                </span>
              )}
            </HeadlineTag>
          )}
          {showMetaInfo && (
            <div className="mt-auto flex justify-between items-end space-x-5">
              {formattedDate && (
                <p className="flex-none text-10">{formattedDate}</p>
              )}
              {platforms && (
                <PlatformShelf
                  platforms={platforms}
                  inverted={variant === ArticleTypes.insight}
                  className="overflow-hidden"
                  size="xs"
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="aspect-3/2 grid-stacked" aria-hidden />
      {Link && (
        <Link className="absolute top-0 left-0 w-full h-full z-20">
          <span className="sr-only">{headline}</span>
        </Link>
      )}
    </Tag>
  )
}

ArticleCardSmall.defaultProps = {
  as: 'article',
  headlineOrder: 2,
  platforms: [],
}

ArticleCardSmall.propTypes = {
  as: PropTypes.oneOf(['div', 'section', 'article', 'aside']),
  className: PropTypes.string,
  headlineOrder: PropTypes.number,
  variant: PropTypes.string,
  Link: PropTypes.any,
  headline: PropTypes.string,
  subline: PropTypes.string,
  platforms: PropTypes.array,
  date: PropTypes.string,
  datetime: PropTypes.string,
}

export default ArticleCardSmall
